import "./styles.css"

const PageNotFound = () => {
    return (
        <div className="pageNotFoundContainer">
            <div id="pageNotFound">
                <div id="img"></div>
                <div className="fof">
                    <h1>404 not found!</h1>
                    <p>
                        We can&lsquo;t seem to find the page you are looking
                        for.
                    </p>
                </div>
            </div>
        </div>
    )
}
export default PageNotFound
