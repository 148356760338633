export const employerPlansData = Object.freeze({
    "coupe-hcsc": {
        sponsorid: "7140",
        endpointsList: {
            publicproviders: "/coupe/v3/publicproviders",
            publicmedicalcodes: "/coupe/v3/publicmedicalcodes"
        },
        employers: [
            {
                employername: "7-Eleven, Inc - Non Financing",
                employerid: "400070",
                flags: { customerserviceurls: true },
                csLink: "",
                csText: "",
                plans: [
                    {
                        planname: "Consumer Copay Plan - Blue Access (NY)",
                        planid: "100520"
                    },
                    {
                        planname: "Consumer Copay Plan - Bluecard",
                        planid: "100530"
                    },
                    {
                        planname: "Consumer Copay Plan - FL Select",
                        planid: "100540"
                    },
                    {
                        planname: "Consumer Copay Plan - WI Select",
                        planid: "100550"
                    },
                    {
                        planname: "Retiree HDHP Family - Blue Access (NY)",
                        planid: "100560"
                    },
                    {
                        planname: "Retiree HDHP Family - Bluecard",
                        planid: "100570"
                    },
                    {
                        planname: "Retiree HDHP Family - FL Select",
                        planid: "100580"
                    },
                    {
                        planname: "Retiree HDHP Family - WI Select",
                        planid: "100590"
                    },
                    {
                        planname: "Retiree HDHP Individual - Blue Access (NY)",
                        planid: "100600"
                    },
                    {
                        planname: "	Retiree HDHP Individual - Bluecard",
                        planid: "100610"
                    },
                    {
                        planname: "Retiree HDHP Individual - FL Select",
                        planid: "100620"
                    },
                    {
                        planname: "Retiree HDHP Individual - WI Select",
                        planid: "100630"
                    },
                    {
                        planname: "Security HSA Family  - WI Select",
                        planid: "100640"
                    },
                    {
                        planname: "Security HSA Family - Blue Access (NY)",
                        planid: "100650"
                    },
                    {
                        planname: "Security HSA Family - Bluecard",
                        planid: "100660"
                    },
                    {
                        planname: "Security HSA Family- FL Select",
                        planid: "100670"
                    },
                    {
                        planname: "Security HSA Individual - Blue Access (NY)",
                        planid: "100680"
                    },
                    {
                        planname: "Security HSA Individual - Bluecard",
                        planid: "100690"
                    },
                    {
                        planname: "Security HSA Individual - FL Select",
                        planid: "100700"
                    },
                    {
                        planname: "Security HSA Individual - WI Select",
                        planid: "100710"
                    },
                    {
                        planname: "Standard HSA Family Plan - - Bluecard",
                        planid: "100720"
                    },
                    {
                        planname: "Standard HSA Family Plan - Blue Access (NY)",
                        planid: "100730"
                    },
                    {
                        planname: "Standard HSA Family Plan - FL Select",
                        planid: "100740"
                    },
                    {
                        planname: "Standard HSA Family Plan - WI Select",
                        planid: "100750"
                    },
                    {
                        planname:
                            "Standard HSA Individual Plan - Blue Access (NY)",
                        planid: "100760"
                    },
                    {
                        planname: "Standard HSA Individual Plan - Bluecard",
                        planid: "100770"
                    },
                    {
                        planname: "Standard HSA Individual Plan - WI Select",
                        planid: "100780"
                    },
                    {
                        planname: "Standard HSA Individual Plan- FL Select",
                        planid: "100790"
                    }
                ]
            },
            {
                employername: "Brookshire Grocery Company",
                employerid: "400080",
                plans: [
                    {
                        planname: "Coupe A - Non Financing",
                        planid: "100800"
                    },
                    {
                        planname: "Coupe A - Standard",
                        planid: "100810"
                    },
                    {
                        planname: "Coupe B - BlueChoice Adv - Non Financing",
                        planid: "100820"
                    },
                    {
                        planname: "Coupe B - BlueChoice Adv - Standard",
                        planid: "100830"
                    }
                ]
            },
            {
                employername: "Hyatt Hotel Corp",
                employerid: "400000",
                plans: [
                    {
                        planname: "Copay Plan - Bluecard - Non Financing",
                        planid: "100000"
                    },
                    {
                        planname: "Copay Plan - Bluecard - Standard",
                        planid: "100010"
                    },
                    {
                        planname: "Copay Plan - FL Select - Non Financing",
                        planid: "100040"
                    },
                    {
                        planname: "Copay Plan - FL Select - Standard",
                        planid: "100050"
                    },
                    {
                        planname: "Copay Plan - GA Select - Non Financing",
                        planid: "100060"
                    },
                    {
                        planname: "Copay Plan - GA Select - Standard",
                        planid: "100070"
                    },
                    {
                        planname: "Copay Plan - DC Select - Non Financing",
                        planid: "100020"
                    },
                    {
                        planname: "Copay Plan - DC Select - Standard",
                        planid: "100030"
                    },
                    {
                        planname: "Copay Plan - NJ Select - Non Financing",
                        planid: "100120"
                    },
                    {
                        planname: "Copay Plan - NJ Select - Standard",
                        planid: "100130"
                    },
                    {
                        planname: "Copay Plan - OK Select - Non Financing",
                        planid: "100140"
                    },
                    {
                        planname: "Copay Plan - OK Select - Standard",
                        planid: "100150"
                    },
                    {
                        planname: "Copay Plan - WI Select - Non Financing",
                        planid: "100200"
                    },
                    {
                        planname: "Copay Plan - WI Select - Standard",
                        planid: "100210"
                    },
                    {
                        planname: "Copay Plan - ST Select - Non Financing",
                        planid: "100160"
                    },
                    {
                        planname: "Copay Plan - ST Select - Standard",
                        planid: "100170"
                    },
                    {
                        planname: "Copay Plan - KC Select - Non Financing",
                        planid: "100080"
                    },
                    {
                        planname: "Copay Plan - KC Select - Standard",
                        planid: "100090"
                    },
                    {
                        planname: "Copay Plan - NH Select - Non Financing",
                        planid: "100100"
                    },
                    {
                        planname: "Copay Plan - NH Select - Standard",
                        planid: "100110"
                    },
                    {
                        planname: "Copay Plan - VA Select - Non Financing",
                        planid: "100180"
                    },
                    {
                        planname: "Copay Plan - VA Select - Standard",
                        planid: "100190"
                    }
                ]
            },
            {
                employername: "R.R. Donnelley & Sons Company",
                employerid: "400060",
                plans: [
                    {
                        planname: "Copay Plan - Bluecard - Non Financing",
                        planid: "100380"
                    },
                    {
                        planname: "Copay Plan - Bluecard - Standard",
                        planid: "100390"
                    },
                    {
                        planname: "Copay Plan - BlueChoice Adv - Non Financing",
                        planid: "100400"
                    },
                    {
                        planname: "Copay Plan - BlueChoice Adv - Standard",
                        planid: "100410"
                    },
                    {
                        planname: "Copay Plan - FL Select - Non Financing",
                        planid: "100420"
                    },
                    {
                        planname: "Copay Plan - FL Select - Standard",
                        planid: "100430"
                    },
                    {
                        planname: "Copay Plan - GA Select - Non Financing",
                        planid: "100440"
                    },
                    {
                        planname: "Copay Plan - GA Select - Standard",
                        planid: "100450"
                    },
                    {
                        planname: "Copay Plan - NJ Select - Non Financing",
                        planid: "100460"
                    },
                    {
                        planname: "Copay Plan - NJ Select - Standard",
                        planid: "100470"
                    },
                    {
                        planname: "Copay Plan - TN Select - Non Financing",
                        planid: "100480"
                    },
                    {
                        planname: "Copay Plan - TN Select - Standard",
                        planid: "100490"
                    },
                    {
                        planname: "Copay Plan - WI Select - Non Financing",
                        planid: "100500"
                    },
                    {
                        planname: "Copay Plan - WI Select - Standard",
                        planid: "100510"
                    }
                ]
            },
            {
                employername: "The City Of Enid",
                employerid: "400090",
                plans: [
                    {
                        planname: "Coupe Copay Plan B Finance",
                        planid: "100840"
                    }
                ]
            }
        ]
    },
    "coupe-anthem": {
        sponsorid: "7140",
        flags: { customerserviceurls: true },
        endpointsList: {
            publicproviders: "/coupe/v3/publicproviders",
            publicmedicalcodes: "/coupe/v3/publicmedicalcodes"
        },
        employers: [
            {
                employername: "ArcField Corporation (Tiger Cub)",
                employerid: "500010",
                plans: [
                    {
                        planname: "Copay Plan - BlueCard - Non Financing",
                        planid: "200010"
                    },
                    {
                        planname: "Copay Plan - BlueCard - Standard",
                        planid: "200020"
                    },
                    {
                        planname: "Copay Plan - BlueChoice Adv - Non Financing",
                        planid: "200030"
                    },
                    {
                        planname: "Copay Plan - BlueChoice Adv - Standard",
                        planid: "200040"
                    },
                    {
                        planname: "Copay Plan - FL Select - Non Financing",
                        planid: "200050"
                    },
                    {
                        planname: "Copay Plan - FL Select - Standard",
                        planid: "200060"
                    },
                    {
                        planname: "Copay Plan - NJ Select - Non Financing",
                        planid: "200070"
                    },
                    {
                        planname: "Copay Plan - NJ Select - Standard",
                        planid: "200080"
                    }
                ]
            },
            {
                employername: "Avient Corporation",
                employerid: "500090",
                plans: [
                    {
                        planname: "Copay - Bluecard - Non Financing",
                        planid: "200720"
                    },
                    {
                        planname: "Copay - Bluecard - Standard",
                        planid: "200730"
                    },
                    {
                        planname: "Copay - GA Select - Non Financing",
                        planid: "200740"
                    },
                    {
                        planname: "Copay - GA Select - Standard",
                        planid: "200750"
                    },
                    {
                        planname:
                            "Copay - MO (STL) & IL Select - Non Financing",
                        planid: "200760"
                    },
                    {
                        planname: "Copay - MO (STL) & IL Select - Standard",
                        planid: "200770"
                    },
                    {
                        planname: "Copay - WI Select - Non Financing",
                        planid: "200780"
                    },
                    {
                        planname: "Copay - WI Select - Standard",
                        planid: "200790"
                    }
                ]
            },
            {
                employername: "Cardinal Health",
                employerid: "500070",
                plans: [
                    {
                        planname: "Copay Plan - Blue Access Choice",
                        planid: "200270"
                    },
                    {
                        planname: "Copay Plan - Blue PPO (BCBS Kansas City)",
                        planid: "200280"
                    },
                    {
                        planname: "Copay Plan - Bluecard",
                        planid: "200290"
                    },
                    {
                        planname: "Copay Plan - BlueChoice Adv",
                        planid: "200300"
                    },
                    {
                        planname: "Copay Plan - FL Select",
                        planid: "200310"
                    },
                    {
                        planname: "Copay Plan - GA Select",
                        planid: "200320"
                    },
                    {
                        planname: "Copay Plan - NH Select",
                        planid: "200330"
                    },
                    {
                        planname: "Copay Plan - NJ Select",
                        planid: "200340"
                    },
                    {
                        planname: "Copay Plan - WI Select",
                        planid: "200350"
                    }
                ]
            },
            {
                employername: "Douglas Dynamics, LLC",
                employerid: "500020",
                plans: [
                    {
                        planname: "Coupe PPO Plan - Non Financing",
                        planid: "200090"
                    },
                    {
                        planname: "Coupe PPO Plan - Standard",
                        planid: "200100"
                    }
                ]
            },
            {
                employername: "HealthPro",
                employerid: "500100",
                plans: [
                    {
                        planname:
                            "Coupe HDHP - Blue PPO (BCBS Kansas City) - HDHP",
                        planid: "200800"
                    },
                    {
                        planname:
                            "Coupe HDHP - Blue PPO (BCBS Kansas City) - Non Financing HDHP",
                        planid: "200810"
                    },
                    {
                        planname: "Coupe HDHP - BlueCard - HDHP",
                        planid: "200820"
                    },
                    {
                        planname: "Coupe HDHP - BlueCard - Non Financing HDHP",
                        planid: "200830"
                    },
                    {
                        planname: "Coupe HDHP - BlueChoice Adv - HDHP",
                        planid: "200840"
                    },
                    {
                        planname:
                            "Coupe HDHP - BlueChoice Adv - Non Financing HDHP",
                        planid: "200850"
                    },
                    {
                        planname: "Coupe HDHP - FL Select - HDHP",
                        planid: "200860"
                    },
                    {
                        planname: "Coupe HDHP - FL Select - Non Financing HDHP",
                        planid: "200870"
                    },
                    {
                        planname: "Coupe HDHP - GA Select - HDHP",
                        planid: "200880"
                    },
                    {
                        planname: "Coupe HDHP - GA Select - Non Financing HDHP",
                        planid: "200890"
                    },
                    {
                        planname: "Coupe HDHP - NH Select - HDHP",
                        planid: "200900"
                    },
                    {
                        planname: "Coupe HDHP - NH Select - Non Financing HDHP",
                        planid: "200910"
                    },
                    {
                        planname: "Coupe HDHP - WI Select - HDHP",
                        planid: "200920"
                    },
                    {
                        planname: "Coupe HDHP - WI Select - Non Financing HDHP",
                        planid: "200930"
                    },
                    {
                        planname:
                            "Coupe PPO - Blue PPO (BCBS Kansas City) - Non Financing",
                        planid: "200940"
                    },
                    {
                        planname:
                            "Coupe PPO - Blue PPO (BCBS Kansas City) - Standard",
                        planid: "200950"
                    },
                    {
                        planname: "Coupe PPO - BlueCard PPO - Non Financing",
                        planid: "200960"
                    },
                    {
                        planname: "Coupe PPO - BlueCard PPO - Standard",
                        planid: "200970"
                    },
                    {
                        planname: "Coupe PPO - BlueChoice Adv - Non Financing",
                        planid: "200980"
                    },
                    {
                        planname: "Coupe PPO - BlueChoice Adv - Standard",
                        planid: "200990"
                    },
                    {
                        planname: "Coupe PPO - FL Select - Non Financing",
                        planid: "201000"
                    },
                    {
                        planname: "Coupe PPO - FL Select - Standard",
                        planid: "201010"
                    },
                    {
                        planname: "Coupe PPO - GA Select - Non Financing",
                        planid: "201020"
                    },
                    {
                        planname: "Coupe PPO - GA Select - Standard",
                        planid: "201030"
                    },
                    {
                        planname: "Coupe PPO - MI Select - Non Financing",
                        planid: "201040"
                    },
                    {
                        planname: "Coupe PPO - MI Select - Standard",
                        planid: "201050"
                    },
                    {
                        planname: "Coupe PPO - NH Select - Non Financing",
                        planid: "201060"
                    },
                    {
                        planname: "Coupe PPO - NH Select - Standard",
                        planid: "201070"
                    },
                    {
                        planname: "Coupe PPO - WI Select - Non Financing",
                        planid: "201080"
                    },
                    {
                        planname: "Coupe PPO - WI Select - Standard",
                        planid: "201090"
                    }
                ]
            },
            {
                employername: "Kenan Advantage Group Inc",
                employerid: "500080",
                plans: [
                    {
                        planname:
                            "Coupe Health Choice Plan - AZ Select - Non Financing",
                        planid: "200360"
                    },
                    {
                        planname:
                            "Coupe Health Choice Plan - AZ Select - Standard",
                        planid: "200370"
                    },
                    {
                        planname:
                            "Coupe Health Choice Plan - Bluecard - Non Financing",
                        planid: "200380"
                    },
                    {
                        planname:
                            "Coupe Health Choice Plan - Bluecard - Standard",
                        planid: "200390"
                    },
                    {
                        planname:
                            "Coupe Health Choice Plan - FL Select - Non Financing",
                        planid: "200400"
                    },
                    {
                        planname:
                            "Coupe Health Choice Plan - FL Select - Standard",
                        planid: "200410"
                    },
                    {
                        planname:
                            "Coupe Health Choice Plan - GA Select - Non Financing",
                        planid: "200420"
                    },
                    {
                        planname:
                            "Coupe Health Choice Plan - GA Select - Standard",
                        planid: "200430"
                    },
                    {
                        planname:
                            "Coupe Health Choice Plan - VA Select - Non Financing",
                        planid: "200440"
                    },
                    {
                        planname:
                            "Coupe Health Choice Plan - VA Select - Standard",
                        planid: "200450"
                    },
                    {
                        planname:
                            "Coupe Health Choice Plan - WI Select - Non Financing",
                        planid: "200460"
                    },
                    {
                        planname:
                            "Coupe Health Choice Plan - WI Select - Standard",
                        planid: "200470"
                    },
                    {
                        planname:
                            "Coupe Health Consumer HDHP - AZ Select - HDHP",
                        planid: "200480"
                    },
                    {
                        planname:
                            "Coupe Health Consumer HDHP - AZ Select - Non Financing HDHP",
                        planid: "200490"
                    },
                    {
                        planname:
                            "Coupe Health Consumer HDHP - Bluecard - HDHP",
                        planid: "200500"
                    },
                    {
                        planname:
                            "Coupe Health Consumer HDHP - Bluecard - Non Financing HDHP",
                        planid: "200510"
                    },
                    {
                        planname:
                            "Coupe Health Consumer HDHP - FL Select - HDHP",
                        planid: "200520"
                    },
                    {
                        planname:
                            "Coupe Health Consumer HDHP - FL Select - Non Financing HDHP",
                        planid: "200530"
                    },
                    {
                        planname:
                            "Coupe Health Consumer HDHP - GA Select - HDHP",
                        planid: "200540"
                    },
                    {
                        planname:
                            "Coupe Health Consumer HDHP - GA Select - Non Financing HDHP",
                        planid: "200550"
                    },
                    {
                        planname:
                            "Coupe Health Consumer HDHP - VA Select - HDHP",
                        planid: "200560"
                    },
                    {
                        planname:
                            "Coupe Health Consumer HDHP - VA Select - Non Financing HDHP",
                        planid: "200570"
                    },
                    {
                        planname:
                            "Coupe Health Consumer HDHP - WI Select - HDHP",
                        planid: "200580"
                    },
                    {
                        planname:
                            "Coupe Health Consumer HDHP - WI Select - Non Financing HDHP",
                        planid: "200590"
                    },
                    {
                        planname:
                            "Coupe Health Consumer HDHP+ - AZ Select - HDHP",
                        planid: "200600"
                    },
                    {
                        planname:
                            "Coupe Health Consumer HDHP+ - AZ Select - Non Financing HDHP",
                        planid: "200610"
                    },
                    {
                        planname:
                            "Coupe Health Consumer HDHP+ - Bluecard - HDHP",
                        planid: "200620"
                    },
                    {
                        planname:
                            "Coupe Health Consumer HDHP+ - Bluecard - Non Financing HDHP",
                        planid: "200630"
                    },
                    {
                        planname:
                            "Coupe Health Consumer HDHP+ - FL Select - HDHP",
                        planid: "200640"
                    },
                    {
                        planname:
                            "Coupe Health Consumer HDHP+ - FL Select - Non Financing HDHP",
                        planid: "200650"
                    },
                    {
                        planname:
                            "Coupe Health Consumer HDHP+ - GA Select - HDHP",
                        planid: "200660"
                    },
                    {
                        planname:
                            "Coupe Health Consumer HDHP+ - GA Select - Non Financing HDHP",
                        planid: "200670"
                    },
                    {
                        planname:
                            "Coupe Health Consumer HDHP+ - VA Select - HDHP",
                        planid: "200680"
                    },
                    {
                        planname:
                            "Coupe Health Consumer HDHP+ - VA Select - Non Financing HDHP",
                        planid: "200690"
                    },
                    {
                        planname:
                            "Coupe Health Consumer HDHP+ - WI Select - HDHP",
                        planid: "200700"
                    },
                    {
                        planname:
                            "Coupe Health Consumer HDHP+ - WI Select - Non Financing HDHP",
                        planid: "200710"
                    }
                ]
            }
        ]
    },
    "coupe-bcbsal": {
        sponsorid: "7140",
        endpointsList: {
            publicproviders: "/coupe/v3/publicproviders",
            publicmedicalcodes: "/coupe/v3/publicmedicalcodes"
        },
        employers: [
            {
                employername: "Koch Foods - Copay",
                employerid: "10523",
                plans: [{ planname: "Coupe Copay Plan", planid: "38619" }]
            }
        ]
    },
    // "coupe-na": {
    //     sponsorid: "7140",
    //     endpointsList: {
    //         publicproviders: "/coupe/v3/publicproviders",
    //         publicmedicalcodes: "/coupe/v3/publicmedicalcodes"
    //     },
    //     employers: [
    //         {
    //             employername: "NA Test Employer",
    //             employerid: "10516",
    //             plans: [{ planname: "NA Test Plan", planid: "38607" }]
    //         }
    //     ]
    // },
    //coupe new plans >=2025
    "coupe-bcbsmn": {
        sponsorid: "7140",
        // flags: {},
        endpointsList: {
            publicproviders: "/coupe/v3/publicproviders",
            publicmedicalcodes: "/coupe/v3/publicmedicalcodes"
        },
        employers: [
            {
                employername: "Allianz Life Insurance Company of North America",
                employerid: "10516",
                plans: [{ planname: "Financing Plan", planid: "38607" }]
            },
            {
                employername: "APi Group - Copay",
                employerid: "10490",
                plans: [{ planname: "Coupe Financing Plan", planid: "38658" }]
            },
            {
                employername: "APi Group - Non Financing Copay",
                employerid: "10545",
                plans: [
                    { planname: "Coupe Non-Financing Plan", planid: "38659" }
                ]
            },
            {
                employername: "BCBS MN - Financing",
                employerid: "8480",
                plans: [{ planname: "Coupe Financing", planid: "38647" }]
            },
            {
                employername: "BCBS MN - Non Financing",
                employerid: "10536",
                plans: [{ planname: "Coupe Non Financing", planid: "38648" }]
            },
            {
                employername: "Best Buy, LLC - CP1",
                employerid: "10529",
                plans: [{ planname: "Coupe Plan 1", planid: "38633" }]
            },
            {
                employername: "Best Buy, LLC - CP2",
                employerid: "10530",
                plans: [{ planname: "Coupe Plan 2", planid: "38634" }]
            },
            {
                employername: "Bethel University - HDHP",
                employerid: "10541",
                plans: [{ planname: "HDHP", planid: "38653" }]
            },
            {
                employername: "Bethel University - Non Financing HDHP",
                employerid: "10542",
                plans: [{ planname: "HDHP Non-Finance", planid: "38654" }]
            },
            {
                employername: "Bethel University - Copay",
                employerid: "10543",
                plans: [{ planname: "PPO -Finance", planid: "38655" }]
            },
            {
                employername: "Bethel University - Non Financing Copay",
                employerid: "10544",
                plans: [{ planname: "PPO  Non-Finance", planid: "38656" }]
            },
            {
                employername: "Christensen Farms & Feedlot, Inc.",
                employerid: "10504",
                plans: [{ planname: "Coupe Copay 2025", planid: "38613" }]
            },
            {
                employername: "Cuningham Group Architecture - HDHP",
                employerid: "10537",
                plans: [{ planname: "Non Embedded HDHP", planid: "38649" }]
            },
            {
                employername:
                    "Cuningham Group Architecture - Non Financing HDHP",
                employerid: "10538",
                plans: [{ planname: "Non Embedded HDHP", planid: "38650" }]
            },
            {
                employername: "Cuningham Group Architecture - Copay",
                employerid: "10539",
                plans: [{ planname: "Coupe PPO Copay Plan", planid: "38651" }]
            },
            {
                employername:
                    "Cuningham Group Architecture - Non Financing Copay",
                employerid: "10540",
                plans: [{ planname: "Coupe PPO Copay Plan", planid: "38652" }]
            },
            {
                employername: "Horton Holding, Inc.",
                employerid: "8633",
                plans: [{ planname: "Coupe Health Plan 2025", planid: "38636" }]
            },
            {
                employername: "Instant Web, LLC dba IWCO",
                employerid: "10502",
                plans: [{ planname: "Coupe Copay Plan", planid: "38661" }]
            },
            {
                employername: "Interstate Companies, Inc.",
                employerid: "10506",
                plans: [{ planname: "Coupe HDHP", planid: "38626" }]
            },
            {
                employername: "Maurices, Inc.",
                employerid: "10508",
                plans: [{ planname: "Coupe Copay Plan", planid: "38657" }]
            },
            {
                employername: "Medtronic - Copay",
                employerid: "10517",
                plans: [{ planname: "BCBS Coupe PPO", planid: "38611" }]
            },
            {
                employername: "Medtronic - Non Financing Copay",
                employerid: "10518",
                plans: [{ planname: "BCBS Coupe PPO", planid: "38612" }]
            },
            {
                employername: "Pentair - NonFinancing Copay",
                employerid: "10522",
                plans: [
                    { planname: "BCBS Coupe Corp Plan PPO", planid: "38618" }
                ]
            },
            {
                employername: "St. Olaf College - Coupe Financing",
                employerid: "10496",
                plans: [
                    { planname: "Coupe Copay Plan Financing", planid: "38637" }
                ]
            },
            {
                employername: "St. Olaf College - HDHP Financing",
                employerid: "10497",
                plans: [
                    { planname: "Coupe HDHP Plan Financing", planid: "38638" }
                ]
            },
            {
                employername: "St. Olaf College - Coupe Non Financing",
                employerid: "10532",
                plans: [
                    {
                        planname: "Coupe Copay Plan Non-Financing",
                        planid: "38639"
                    }
                ]
            },
            {
                employername: "St. Olaf College - HDHP Non-Financing",
                employerid: "10533",
                plans: [
                    {
                        planname: "Coupe HDHP Plan Non-Financing",
                        planid: "38640"
                    }
                ]
            },
            {
                employername:
                    "Twin City Fan Companies Ltd - Non-Financing Copay",
                employerid: "10519",
                plans: [
                    { planname: "Coupe Non-Financing - PPO", planid: "38614" }
                ]
            },
            {
                employername: "Twin City Fan Companies Ltd - Copay",
                employerid: "10520",
                plans: [{ planname: "Coupe Financing - PPO", planid: "38615" }]
            }
        ]
    },
    //coupe old plans <=2024
    coupe: {
        sponsorid: "7140",
        endpointsList: {
            publicproviders: "/coupe/v2/publicproviders",
            publicmedicalcodes: "/coupe/v2/publicmedicalcodes"
        },
        employers: [
            {
                employername: "APi Group",
                employerid: "10490",
                plans: [
                    { planname: "Coupe", planid: "38509" },
                    { planname: "Coupe Benefits Summary", planid: "38585" }
                ]
            },
            {
                employername: "Best Buy, LLC - CP1",
                employerid: "10498",
                plans: [{ planname: "Coupe Plan 1", planid: "38562" }]
            },
            {
                employername: "Best Buy, LLC - CP2",
                employerid: "10499",
                plans: [{ planname: "Coupe Plan 2", planid: "38563" }]
            },
            {
                employername: "Blue Cross and Blue Shield of Minnesota",
                employerid: "8480",
                plans: [
                    { planname: "Coupe 2023", planid: "38513" },
                    { planname: "Coupe 2024", planid: "38578" }
                ]
            },
            {
                employername: "Christensen Farms & Feedlot, Inc.",
                employerid: "10504",
                plans: [{ planname: "Coupe Copay 2024", planid: "38571" }]
            },
            {
                employername: "Horton Holding, Inc.",
                employerid: "8633",
                plans: [
                    { planname: "Coupe 2023", planid: "37546" },
                    { planname: "Coupe Health Plan 2024", planid: "38567" }
                ]
            },
            {
                employername: "Instant Web, LLC dba IWCO",
                employerid: "10502",
                plans: [{ planname: "Coupe Copay Plan", planid: "38568" }]
            },
            {
                employername: "Interstate Companies, Inc.",
                employerid: "10506",
                plans: [{ planname: "Coupe HDHP Plan", planid: "38573" }]
            },
            {
                employername: "Maurices, Inc.",
                employerid: "10508",
                plans: [{ planname: "Coupe Copay Plan", planid: "38586" }]
            },
            {
                employername: "St. Olaf College - CORE",
                employerid: "10496",
                plans: [{ planname: "Core Medical Plan", planid: "38557" }]
            },
            {
                employername: "St. Olaf College - HDHP",
                employerid: "10497",
                plans: [{ planname: "HDHP Medical Plan", planid: "38558" }]
            }
        ]
    },
    //simplepay new plans >=2025
    "simplepay-2025": {
        sponsorid: "6106",
        endpointsList: {
            publicproviders: "/coupe/v3/publicproviders",
            publicmedicalcodes: "/coupe/v3/publicmedicalcodes"
        },
        employers: [
            {
                employername: "Ability Beyond",
                employerid: "7058",
                plans: [
                    {
                        planname: "SimplePay Health plan",
                        planid: "38680"
                    }
                ]
            },
            {
                employername: "Builders FirstSource, Inc.",
                employerid: "10503",
                plans: [
                    { planname: "Copay Plan", planid: "38623" },
                    { planname: "HDHP", planid: "38624" }
                ]
            },
            {
                employername: "CATIC Financial, Inc",
                employerid: "10507",
                plans: [
                    {
                        planname: "SimplePay Copay",
                        planid: "38665"
                    }
                ]
            },
            {
                employername: "Circle the City - Non Financing",
                employerid: "10549",
                plans: [
                    {
                        planname: "Copay 2 Plan",
                        planid: "38669"
                    }
                ]
            },
            {
                employername: "Circle the City - Financing",
                employerid: "10550",
                plans: [
                    {
                        planname: "Copay 1 Plan",
                        planid: "38670"
                    },
                    {
                        planname: "HDHP",
                        planid: "38671"
                    }
                ]
            },
            {
                employername: "Cvent Inc.",
                employerid: "10546",
                plans: [{ planname: "SimplePay Copay Plan", planid: "38662" }]
            },
            {
                employername: "Durez Corporation dba Durez",
                employerid: "10527",
                plans: [
                    { planname: "Copay Plan", planid: "38629" },
                    { planname: "HDHP Plan", planid: "38630" }
                ]
            },
            {
                employername: "Harlan Foods, Inc.",
                employerid: "6882",
                plans: [
                    {
                        planname: "Core Medical Plan 2024-2025",
                        planid: "38608"
                    },
                    {
                        planname: "Enhanced Medical Plan 2024-2025",
                        planid: "38609"
                    },
                    {
                        planname: "Value Medical Plan 2024-2025",
                        planid: "38610"
                    }
                ]
            },
            {
                employername: "HD Supply",
                employerid: "10427",
                plans: [{ planname: "Medical Plan 2025", planid: "38617" }]
            },
            {
                employername: "Hilti Inc",
                employerid: "10500",
                plans: [{ planname: "SimplePay Plan", planid: "38644" }]
            },
            {
                employername: "Immanuel",
                employerid: "7005",
                plans: [
                    {
                        planname: "SimplePay Copay Plan",
                        planid: "38679"
                    }
                ]
            },
            {
                employername: "Invited Clubs",
                employerid: "10505",
                plans: [{ planname: "Simplepay Plan", planid: "38660" }]
            },
            {
                employername: "Marotta Controls, Inc.",
                employerid: "10531",
                plans: [{ planname: "SimplePay POS Plan", planid: "38635" }]
            },
            {
                employername: "Metro Fire Equipment, Inc.",
                employerid: "10525",
                plans: [
                    {
                        planname: "SimplePay Copay 1 Plan",
                        planid: "38621"
                    },
                    {
                        planname: "SimplePay HDHP Plan",
                        planid: "38622"
                    }
                ]
            },
            {
                employername: "Metro Fire Equipment, Inc. - NonFinancing Copay",
                employerid: "10524",
                plans: [
                    {
                        planname: "SimplePay Copay 2 Plan",
                        planid: "38620"
                    }
                ]
            },
            {
                employername: "North American Companies",
                employerid: "10452",
                plans: [
                    { planname: "SimplePay HDHP Plan", planid: "38645" },
                    { planname: "SimplePay Plan", planid: "38646" }
                ]
            },
            {
                employername: "Omni Hotels Management Corporation",
                employerid: "10521",
                plans: [{ planname: "SimplePay Plan", planid: "38616" }]
            },
            {
                employername: "Park Ave Motors",
                employerid: "10509",
                plans: [{ planname: "SimplePay Copay", planid: "38666" }]
            },
            {
                employername:
                    "SRTV Holdings LLC dba Patriot Rail - NonFinancing Copay",
                employerid: "10534",
                plans: [
                    {
                        planname: "Traditional Copay",
                        planid: "38641"
                    }
                ]
            },
            {
                employername: "SRTV Holdings LLC dba Patriot Rail",
                employerid: "10535",
                plans: [
                    {
                        planname: "SimplePay Copay",
                        planid: "38642"
                    },
                    {
                        planname: "SimplePay HDHP",
                        planid: "38643"
                    }
                ]
            },
            {
                employername: "Tech Mahindra Americas Inc. - Plan 1",
                employerid: "10547",
                plans: [{ planname: "SimplePay Copay", planid: "38663" }]
            },
            {
                employername: "Tech Mahindra Americas Inc. - Plan 2",
                employerid: "10548",
                plans: [{ planname: "SimplePay Copay", planid: "38664" }]
            },
            {
                employername: "Tim Dahle Auto Group",
                employerid: "10428",
                plans: [{ planname: "SimplePay Health Plan", planid: "38678" }]
            },
            {
                employername: "Vaupell Holdings, Inc.",
                employerid: "10528",
                plans: [
                    { planname: "No Deductible Plan", planid: "38631" },
                    { planname: "HDHP Plan", planid: "38632" }
                ]
            },
            {
                employername: "Wasatch Property",
                employerid: "10464",
                plans: [
                    { planname: "SimplePay Copay Plan", planid: "38667" },
                    { planname: "SimplePay HDHP Plan", planid: "38668" }
                ]
            },
            {
                employername: "Wasserstrom Holdings, Inc.",
                employerid: "10526",
                plans: [
                    { planname: "No Deductible Plan", planid: "38628" },
                    { planname: "HDHP Plan", planid: "38627" }
                ]
            },
            {
                employername: "Workday, Inc.",
                employerid: "10501",
                plans: [
                    {
                        planname: "Health and Welfare Program",
                        planid: "38625"
                    }
                ]
            }
        ]
    },
    //simplepay old plans <=2024
    simplepay: {
        sponsorid: "6106",
        endpointsList: {
            publicproviders: "/coupe/v2/publicproviders",
            publicmedicalcodes: "/coupe/v2/publicmedicalcodes"
        },
        employers: [
            {
                employername: "Ability Beyond",
                employerid: "7058",
                plans: [
                    {
                        planname: "MED BNFTS OUT OF NETWORK (SIMPLEPAY)",
                        planid: "38510"
                    },
                    {
                        planname: "SimplePay Health plan",
                        planid: "38588"
                    }
                ]
            },
            {
                employername: "Alpha Furniture",
                employerid: "10491",
                plans: [{ planname: "SimplePay Health Plan", planid: "37719" }]
            },
            {
                employername: "Allegheny Coatings",
                employerid: "10492",
                plans: [
                    { planname: "SimplePay Gold 2023", planid: "38549" },
                    { planname: "SimplePay Silver 2023", planid: "38550" },
                    { planname: "SimplePay Gold 2024", planid: "38596" },
                    { planname: "SimplePay Silver 2024", planid: "38597" }
                ]
            },
            {
                employername: "Behavioral Perspective Inc",
                employerid: "10514",
                plans: [
                    { planname: "Copay Plan A", planid: "38604" },
                    { planname: "Copay Plan B", planid: "38605" }
                ]
            },
            {
                employername: "Builders FirstSource, Inc.",
                employerid: "10503",
                plans: [
                    { planname: "Copay Plan 2024", planid: "38569" },
                    { planname: "HDHP 2024", planid: "38570" }
                ]
            },
            {
                employername: "Butler County",
                employerid: "6991",
                plans: [
                    {
                        planname: "MED BNFTS OUT OF NETWORK (SIMPLEPAY)",
                        planid: "36628"
                    }
                ]
            },
            {
                employername: "CATIC Financial, Inc",
                employerid: "10507",
                plans: [
                    {
                        planname: "SimplePay Copay",
                        planid: "38576"
                    }
                ]
            },
            {
                employername: "Chasse Building Team, Inc.",
                employerid: "10515",
                plans: [{ planname: "Copay", planid: "38606" }]
            },
            {
                employername: "Checkers Drive-In Restaurants, Inc.",
                employerid: "9335",
                plans: [
                    { planname: "SimplePay Bronze 2023", planid: "37783" },
                    { planname: "SimplePay Gold 2023", planid: "37784" },
                    { planname: "SimplePay Silver 2023", planid: "37785" },
                    { planname: "SimplePay Bronze 2024", planid: "38579" },
                    { planname: "SimplePay Gold 2024", planid: "38580" },
                    { planname: "SimplePay Silver 2024", planid: "38581" }
                ]
            },
            {
                employername: "Copa Health, Inc.",
                employerid: "10510",
                plans: [
                    {
                        planname: "Copay Plan",
                        planid: "38591"
                    },
                    {
                        planname: "HDHP Plan",
                        planid: "38592"
                    }
                ]
            },
            {
                employername: "Copa Health, Inc. - Non financing Plan",
                employerid: "10511",
                plans: [
                    {
                        planname: "Traditional Copay Plan",
                        planid: "38593"
                    }
                ]
            },
            {
                employername: "Genuine Financial Holding LLC dba HireRight",
                employerid: "8558",
                plans: [
                    { planname: "SimplePay Core Plan 2023", planid: "38514" },
                    { planname: "Core Plan 2024", planid: "38590" }
                ]
            },
            {
                employername: "Harlan Foods, Inc.",
                employerid: "6882",
                plans: [
                    {
                        planname: "Core Medical Plan 2023-2024",
                        planid: "38559"
                    },
                    {
                        planname: "Enhanced Medical Plan 2023-2024",
                        planid: "38560"
                    },
                    {
                        planname: "Value Medical Plan 2023-2024",
                        planid: "38561"
                    }
                ]
            },
            {
                employername: "HD Supply",
                employerid: "10427",
                plans: [
                    { planname: "SimplePay Health Plan 2023", planid: "36880" },
                    { planname: "Medical Plan 2024", planid: "38577" }
                ]
            },
            {
                employername: "Hilti Inc",
                employerid: "10500",
                plans: [{ planname: "SimplePay Plan", planid: "38564" }]
            },
            {
                employername: "Immanuel",
                employerid: "7005",
                plans: [
                    {
                        planname: "MED BNFTS OUT OF NETWORK (SIMPLEPAY)",
                        planid: "38515"
                    },
                    {
                        planname: "SimplePay Copay Plan",
                        planid: "38565"
                    }
                ]
            },
            {
                employername: "Invited Clubs",
                employerid: "10505",
                plans: [{ planname: "SimplePay Plan", planid: "38572" }]
            },
            {
                employername: "Lutheran Services Florida",
                employerid: "8632",
                plans: [
                    { planname: "SimplePay Bronze Plan", planid: "38516" },
                    { planname: "SimplePay Platinum Plan", planid: "38517" }
                ]
            },
            {
                employername:
                    "Memphis Obstetrics and Gynecological Association P.C.",
                employerid: "7085",
                plans: [
                    {
                        planname: "MED BNFTS OUT OF NETWORK (SIMPLEPAY)",
                        planid: "38518"
                    }
                ]
            },
            {
                employername: "Mosaic",
                employerid: "6953",
                plans: [
                    {
                        planname: "MED BNFTS OUT OF NETWORK (SIMPLEPAY)",
                        planid: "38519"
                    },
                    {
                        planname: "Copay Plan",
                        planid: "38584"
                    }
                ]
            },
            {
                employername: "Mountain Beverage Co., LLC",
                employerid: "10513",
                plans: [
                    {
                        planname: "Option A",
                        planid: "38595"
                    }
                ]
            },
            {
                employername: "Mountain Beverage Co., LLC - Non financing Plan",
                employerid: "10512",
                plans: [
                    {
                        planname: "Option B",
                        planid: "38594"
                    }
                ]
            },
            {
                employername: "North American Companies",
                employerid: "10452",
                plans: [
                    { planname: "SimplePay Copay Plan 2023", planid: "37526" },
                    { planname: "SimplePay HDHP Plan 2023", planid: "37527" },
                    { planname: "SimplePay Plan 2024", planid: "38575" },
                    { planname: "SimplePay HDHP Plan 2024", planid: "38574" }
                ]
            },
            {
                employername: "Park Ave Motors",
                employerid: "10509",
                plans: [{ planname: "SimplePay Copay", planid: "38587" }]
            },
            {
                employername: "SimplePay Health",
                employerid: "5047",
                plans: [{ planname: "SPT-01", planid: "33541" }]
            },
            {
                employername: "Tervis Tumbler",
                employerid: "10465",
                plans: [
                    {
                        planname: "SimplePay Health Copay Plan",
                        planid: "37865"
                    },
                    { planname: "SimplePay Health HDHP Plan", planid: "37866" }
                ]
            },
            {
                employername: "Tim Dahle Auto Group",
                employerid: "10428",
                plans: [
                    { planname: "SimplePay Health Plan 2023", planid: "36936" },
                    { planname: "SimplePay Health Plan 2024", planid: "38589" }
                ]
            },
            {
                employername: "Wasatch Property",
                employerid: "10464",
                plans: [
                    { planname: "SimplePay Health Plan 2023", planid: "38548" },
                    { planname: "SimplePay Copay Plan 2024", planid: "38582" },
                    { planname: "SimplePay HDHP Plan 2024", planid: "38583" }
                ]
            },
            {
                employername: "Workday, Inc.",
                employerid: "10501",
                plans: [
                    {
                        planname: "Health and Welfare Program - 2024",
                        planid: "38566"
                    }
                ]
            }
        ]
    }
})
