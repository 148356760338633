import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useLocation, useNavigate } from "react-router-dom"

import InfoIcon from "@mui/icons-material/Info"
import CategoryIcon from "@mui/icons-material/Category"
import SearchIcon from "@mui/icons-material/Search"
import ReceiptIcon from "@mui/icons-material/Receipt"
import SearchTip from "../common/searchTip/SearchTip"
import { Button } from "@mui/material"

import Landing from "../../view/landing/Landing"

import "./Nav.css"

const Nav = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const location = useLocation()
    const [open, setOpen] = useState(false)
    const [menuType, setMenuType] = useState(false)
    const {
        providersResponse,
        showNavSearchModal,
        loginType,
        currentProviderDetails
    } = useSelector((state) => state)
    const { insurancePlanType = "" } = providersResponse || {}
    useEffect(() => {
        if (showNavSearchModal) {
            document.body.style.overflow = "hidden"
        } else if (document.body.style.overflow === "hidden") {
            document.body.style.overflow = "unset"
        }
    }, [showNavSearchModal])

    const handleClose = () => {
        dispatch({ type: "IS_SEARCHTIP_MODAL_SHOW", payload: false })
        setOpen(false)
    }
    const handleMenu = (type) => {
        setOpen(true)
        setMenuType(type)
    }
    const handleNewSearch = () => {
        //  dispatch({ type: "RESET_PROVIDERLIST" })
        // dispatch({ type: "GET_ZIPCODES_FAIL" })
        // dispatch({ type: "GET_PROVIDER_DETAILS_FAIL" })
        dispatch({
            type: "NAV_SEARCH_MODAL_STATUS",
            payload: true
        })
        dispatch({
            type: "SET_CATEGORY_VIEW_SHOW",
            payload: false
        })
        dispatch({
            type: "SHOW_CATEGORY_PAGE",
            payload: true
        })
    }
    const getReports = () => {
        const res = {
            ...currentProviderDetails,
            getAllResults: true,
            offset: 0,
            limit: 20,
            navigate
        }
        let actionType = "GET_PUBLIC_PROVIDER_DETAILS"
        if (currentProviderDetails?.title === "procedureCode") {
            res["subType"] = "procedureCode"
            res["procedureCodes"] = currentProviderDetails.procedureCode
            actionType = "GET_PUBLIC_CONDITIONS_PROCEDURES_DETAILS_DATA"
        } else if (currentProviderDetails.title === "specialties") {
            res["subType"] = "specialties"
            actionType = "GET_PUBLIC_CONDITIONS_PROCEDURES_DETAILS_DATA"
        }
        dispatch({
            type: actionType,
            payload: res
        })
    }

    const printPage = () => {
        window.print()
    }

    const drugSearchFlag = () => {
        return location.pathname === "/DrugResults"
    }

    return (
        <>
            {!drugSearchFlag() && (
                <>
                    <ul className="nav-bar">
                        {location.pathname === "/generateProviderReport" ? (
                            <li>
                                <Button
                                    variant="text"
                                    color="primary"
                                    aria-label="Print Report"
                                    onClick={printPage}
                                    startIcon={<ReceiptIcon />}
                                >
                                    <span className="xs-hide">
                                        Print Report
                                    </span>
                                </Button>
                            </li>
                        ) : (
                            <>
                                <li>
                                    <Button
                                        onClick={() => handleMenu("search")}
                                        variant="text"
                                        color="primary"
                                        aria-label="Search Tip"
                                        startIcon={<InfoIcon />}
                                    >
                                        <span className="xs-hide">
                                            Search Tip
                                        </span>
                                    </Button>
                                </li>
                                <li>
                                    <Button
                                        onClick={() => handleMenu("legend")}
                                        variant="text"
                                        color="primary"
                                        aria-label="Icon Legend"
                                        startIcon={<CategoryIcon />}
                                    >
                                        <span className="xs-hide">
                                            Icon Legend
                                        </span>
                                    </Button>
                                </li>
                                <li>
                                    <Button
                                        variant="text"
                                        color="primary"
                                        aria-label="New Search"
                                        onClick={handleNewSearch}
                                        startIcon={<SearchIcon />}
                                    >
                                        <span className="xs-hide">
                                            New Search
                                        </span>
                                    </Button>
                                </li>
                                {loginType === "external user login" && (
                                    <li>
                                        <Button
                                            variant="text"
                                            color="primary"
                                            aria-label="Generate Report"
                                            onClick={getReports}
                                            startIcon={<ReceiptIcon />}
                                        >
                                            <span className="xs-hide">
                                                Generate Report
                                            </span>
                                        </Button>
                                    </li>
                                )}
                            </>
                        )}
                    </ul>
                </>
            )}
            <SearchTip
                open={open}
                handleClose={handleClose}
                menuType={menuType}
                insurancePlanType={insurancePlanType}
            />
            {showNavSearchModal && <Landing type="navSearch" />}
        </>
    )
}

export default Nav
