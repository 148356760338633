import React from "react"
import { useSelector, useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import { PropTypes } from "prop-types"

import { Button } from "@mui/material"

import "./styles.css"

const ErrorPage = (props) => {
    const store = useSelector((state) => state)
    const { globalErrorMessage } = store
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const handleNavigation = () => {
        if (props.type === "pageLevel") {
            dispatch({
                type: "SET_SELECTED_CATEGORY_PAGE_LEVEL_ERROR",
                payload: true
            })
            props.handleClose()
        } else {
            const empStatus = sessionStorage.getItem("empType")
            const url = sessionStorage.getItem("url")
            if (empStatus) {
                sessionStorage.removeItem("empType")
                sessionStorage.removeItem("url")
                navigate(url)
            } else {
                navigate("/")
            }
        }
    }
    return (
        <div className="error-landing">
            <div className="error-container">
                <div className="error-text">{globalErrorMessage}</div>
                <Button
                    onClick={handleNavigation}
                    variant="text"
                    color="primary"
                    aria-label="try again Tip"
                >
                    Try Again
                </Button>
            </div>
        </div>
    )
}
ErrorPage.propTypes = {
    type: PropTypes.any,
    handleClose: PropTypes.any
}
export default ErrorPage
