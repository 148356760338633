import axiosInstance from "../middleware/axiosInstance"
import { load } from "protobufjs"
// import protobuf from "./protos/basicProvider.proto"
import protobufBcbs from "./protos/basicPublicProviderBCBS.proto"
import protobufAetna from "./protos/basicPublicProviderAetna.proto"
import { Buffer } from "buffer"

const getProvidersResponse = async (encodeResponse, sponsorId) => {
    try {
        const protoBuff = sponsorId === "7140" ? protobufBcbs : protobufAetna
        return await load(protoBuff).then(function (root) {
            const BasicProviderResponse = root.lookupType(
                "providerSearch.BasicPublicProviderResponse"
            )
            const decodedResponse = BasicProviderResponse.decode(
                Buffer.from(encodeResponse.data, "base64")
            )
            // console.log(decodedResponse, "decodedResponse")
            return {
                data: decodedResponse,
                apiStatus: "success",
                cached: encodeResponse.cached
            }
        })
    } catch (e) {
        console.log(e)
    }
}

export const getProviderListService = async ({
    token,
    payload,
    detailType,
    sponsorId,
    employeeId,
    providerId,
    nearByZipcodes,
    radius
}) => {
    const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`
    }
    const { city, state, zipCode, providerType, offset = 0 } = payload
    const zCode = nearByZipcodes || zipCode
    let url = ""
    if (detailType === "basic") {
        url = `${process.env.REACT_APP_API_URL_v2}/coupe/v2/providers?sponsorId=${sponsorId}&employeeId=${employeeId}&detail=${detailType}&limit=50000&offset=${offset}&zipcode=${zCode}&city=${city}&state=${state}&radius=${radius}`

        // url = `${process.env.REACT_APP_API_URL}/v2/coupe/providers?sponsorId=${sponsorId}&employeeId=${employeeId}&detail=${detailType}&limit=50000&offset=${offset}&zipcode=${zCode}&city=${city}&state=${state}&firstProviderCall=true`
        let basicSearchResponse = await axiosInstance({
            method: "get",
            url,
            headers: headers
        })
        return await getProvidersResponse(basicSearchResponse, sponsorId)
    } else {
        url = `${process.env.REACT_APP_API_URL_v2}/coupe/v2/providers?sponsorId=${sponsorId}&employeeId=${employeeId}&detail=${detailType}&zipcode=${zCode}&city=${city}&state=${state}&limit=10&offset=${offset}&providerType=${providerType}&providersList=${providerId}`
    }

    return axiosInstance({
        method: "get",
        url,
        headers: headers
    })
}

export const fetchProvidersInParallel = async ({
    token,
    payload,
    detailType,
    sponsorId,
    employeeId,
    nearByZipcodes,
    radius
}) => {
    const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`
    }
    const zCode = nearByZipcodes || payload?.zipCode
    payload.zCode = zCode

    let providerConcurrentresults = await Promise.all(
        getProviderRequests(
            payload,
            headers,
            sponsorId,
            employeeId,
            detailType,
            radius
        )
    )
    let providers = []
    let specialties = []
    let searchparams = []
    let insurancePlanType = []
    let apiStatus = ""
    for (let i = 0; i < providerConcurrentresults.length; i++) {
        let res = providerConcurrentresults[i]
        if (
            !res?.data?.statusCode === undefined &&
            res?.data?.statusCode !== 200
        ) {
            apiStatus = "fail"
        }
        if (res?.data?.statusCode === undefined) {
            providers = providers.concat(res?.data?.providers)
            specialties = specialties.concat(res?.data?.specialties)
            searchparams.push(res?.data?.searchparams)
            insurancePlanType.push(res?.data?.insurancePlanType)
        }
    }
    return {
        apiStatus: apiStatus !== "fail" ? "success" : "fail",
        data: {
            providers: providers,
            specialties: specialties,
            searchparams: searchparams,
            insurance_plantype: [...new Set(insurancePlanType)],
            count: payload?.count
        }
    }
}
const getProviderRequests = (
    payload,
    headers,
    sponsorId,
    employeeId,
    detailType
) => {
    const providerRequests = []
    let { count, city, state, zCode, radius } = payload
    const totalVal = count
    let offset = 50000
    while (count > 0 && offset <= totalVal) {
        let url = `${process.env.REACT_APP_API_URL_v2}/coupe/v2/providers?sponsorId=${sponsorId}&employeeId=${employeeId}&detail=${detailType}&limit=50000&offset=${offset}&zipcode=${zCode}&city=${city}&state=${state}&radius=${radius}`
        providerRequests.push(
            axiosInstance({
                method: "get",
                url: url,
                headers: headers
            }).then((response) => {
                return getProvidersResponse(response, sponsorId)
            })
        )
        offset += 50000
        count -= 50000
    }
    return providerRequests
}
