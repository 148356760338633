import axiosInstance from "../middleware/axiosInstance"

const getCityZipcodeService = async (payload) => {
    const { lat, lng } = payload || {}
    const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${process.env.REACT_APP_GOOGLE_API_KEY}`
    return axiosInstance({
        method: "get",
        url,
        data: null
    })
}

export default getCityZipcodeService
