import axiosInstance from "../middleware/axiosInstance"

const getTokenService = async () => {
    // Function pulls url query params from url and creates object containing params

    const client_id = process.env.REACT_APP_CLIENT_ID
    const client_secret = process.env.REACT_APP_CLIENT_SECRET

    const code = await getUrlParameter("code")

    const auth = {
        username: client_id,
        password: client_secret
    }
    const headers = {
        "Content-Type": "application/x-www-form-urlencoded"
    }
    return axiosInstance({
        method: "post",
        url: `${process.env.REACT_APP_API_BASE_URL}/oauth2/token?grant_type=authorization_code&code=${code}&client_id=${client_id}&redirect_uri=${process.env.REACT_APP_SPA_DOMAIN_URL}`,
        headers: headers,
        data: null,
        auth: auth
    })
}

const getUrlParameter = (searchTerm) => {
    const params = new Proxy(new URLSearchParams(window.location.search), {
        get: (searchParams, prop) => searchParams.get(prop)
    })

    return params[searchTerm]
}

export default getTokenService
