import React, { useEffect, useState } from "react"
import {
    MarkerClusterer,
    SuperClusterAlgorithm
} from "@googlemaps/markerclusterer"
import GroupModal from "./GroupModal"
import pin_recommended from "../../assets/images/pin_recommended.svg"
import pin_multiple from "../../assets/images/pin_multiple.svg"
import pin_facility_large from "../../assets/images/pin_facility_large.svg"
import pin_tier2_large from "../../assets/images/pin_tier2_large.svg"
import pin_tier3_large from "../../assets/images/pin_tier3_large.svg"
import pin_facility_dark from "../../assets/images/pin_facility_dark_large.svg"
import pin_tier2_dark from "../../assets/images/pin_tier2_dark_large.svg"
import pin_tier3_dark from "../../assets/images/pin_tier3_dark_large.svg"
import pin_multiple_highlighted_large from "../../assets/images/pin_recommended_highlighted_large.svg"
import { isFacilityWithCopay } from "../../utils/common"

import "./styles.css"

const google = window.google

/* eslint react/prop-types: 0 */
const Map = ({
    handleMarkerClick,
    markerData,
    currentProviderData,
    providerDetailsIndexOne
}) => {
    let count = 0
    const [groupedLocationList, setGroupedLocationList] = useState(null)

    let mapEntries = []
    let markersProp = []
    let mapEntriesProp = []
    let markerClusterersProp = []
    useEffect(() => {
        checkDuplicateLocations()
        setGroupedLocationList([])
    }, [markerData])

    const checkDuplicateLocations = () => {
        count = 0
        const list = markerData.map((value) => {
            return (
                value?.address?.location?.lat?.toString() +
                value?.address?.location?.lon?.toString()
            )
        })
        list.map((value) => {
            if (!list.indexOf(value)) {
                count = count + 1
            }
        })
        initMap(markerData)
    }

    const getMarkerType = (position) => {
        let tier = position?.override_tier
            ? position.override_tier
            : position.default_tier
        if (isFacilityWithCopay(position)) {
            return providerDetailsIndexOne?.provider_id ===
                position?.provider_id
                ? pin_facility_dark
                : pin_facility_large
        } else if (tier && position?.provider_id) {
            return getMapPin(tier, position.provider_id)
        }
    }

    const getMapPin = (tier, provider_id) => {
        if (tier === 3) {
            return providerDetailsIndexOne?.provider_id === provider_id
                ? pin_tier3_dark
                : pin_tier3_large
        } else if (tier === 2) {
            return providerDetailsIndexOne?.provider_id === provider_id
                ? pin_tier2_dark
                : pin_tier2_large
        } else if (tier === 1) {
            return providerDetailsIndexOne?.provider_id === provider_id
                ? pin_multiple_highlighted_large
                : pin_recommended
        }
    }

    const getMapRate = (val) => {
        if (isFacilityWithCopay(val)) {
            return " "
        }
        const overRate = val?.override_rate
        const defaultRate = val?.default_rate
        return overRate ? "$" + overRate : defaultRate ? "$" + defaultRate : " "
    }

    const initMap = (addressResp) => {
        for (let n = 0; n < markersProp.length; n++) {
            markersProp[n].setMap(null)
        }
        markersProp = []
        mapEntriesProp = []
        markerClusterersProp = []
        const e = addressResp || []
        let i = e?.map((item) => {
            return item.address?.location?.lat
        })
        let r = e?.map((item) => {
            return item.address?.location?.lon
        })
        let val = 0
        i?.forEach((ele) => {
            return (val += ele)
        })
        let l = 0
        r?.forEach((ele) => {
            return (l += ele)
        })

        const aProp = {
            center: new google.maps.LatLng(val / i.length, l / r.length),
            zoom: 5,
            maxZoom: 17,
            mapTypeId: google.maps.MapTypeId.ROADMAP,
            styles: [
                {
                    featureType: "administrative",
                    elementType: "geometry",
                    stylers: [{ visibility: "off" }]
                },
                { featureType: "poi", stylers: [{ visibility: "off" }] },
                {
                    featureType: "road",
                    elementType: "labels.icon",
                    stylers: [{ visibility: "off" }]
                },
                { featureType: "transit", stylers: [{ visibility: "off" }] }
            ]
        }
        const map = new window.google.maps.Map(
            document.getElementById("map"),
            aProp
        )
        // Create an array of alphabetical characters used to label the markers.
        const labels = addressResp
        // Add some markers to the map.
        const markers = labels?.map((position) => {
            // const label = labels[i % labels.length]
            const marker = new google.maps.Marker({
                title: position?.provider_name,
                icon: {
                    url: getMarkerType(position),
                    size: new google.maps.Size(75, 75),
                    scaledSize: new google.maps.Size(75, 75),
                    labelOrigin: new google.maps.Point(20, -8)
                },
                id: position?.provider_id,
                text: position?.provider_name,
                map: map,
                position: {
                    lat: position.address?.location?.lat,
                    lng: position.address?.location?.lon
                },
                label: {
                    className: "marker-cluster-label",
                    text: getMapRate(position),
                    fontSize: "18px",
                    fontWeight: "bold",
                    color: "#007FC1",
                    fontFamily: "Arial, sans-serif"
                }
            })
            position.marker = marker

            // markers can only be keyboard focusable when they have click listeners
            // open info window when marker is clicked
            marker.addListener("click", () => {
                handleMarkerClick(position)
            })
            mapEntriesProp.push(position)
            markersProp.push(marker)
            mapEntries.push(marker)
            return marker
        })

        // Add a marker clusterer to manage the markers.
        let nProp, iProp
        for (
            nProp = new google.maps.LatLngBounds(), iProp = 0;
            iProp < markersProp.length;
            iProp++
        ) {
            nProp.extend(markersProp[iProp].getPosition())
        }
        map.fitBounds(nProp, 100)

        const clus = new MarkerClusterer({
            markers,
            map,
            algorithm: new SuperClusterAlgorithm({ maxZoom: 18 }),
            renderer: {
                render: ({ count, position }) =>
                    new google.maps.Marker({
                        icon: {
                            url: pin_multiple
                        },
                        position,
                        label: {
                            className: "setPosition",
                            text: String(count)
                        }
                    })
            },
            onClusterClick: (e, cliust, n) => {
                let i = n.getBounds()
                let r = i.getCenter()
                let o = map
                o.panTo(r)
                new google.maps.event.addListenerOnce(o, "idle", () => {
                    setGroupedLocationList([])
                    if (o.getZoom() < 15) {
                        let e = o.getZoom()
                        o.setZoom(e + 1)
                        google.maps.event.addListenerOnce(o, "idle", () => {
                            // o.fitBounds(i)
                        })
                    } else {
                        let r = cliust.markers.map((e) => {
                            return labels.find(function (t) {
                                return t.marker.id === e.id
                            })
                        })
                        setGroupedLocationList(r)
                    }
                })
            }
        })
        markerClusterersProp.push(clus)

        google.maps.event.addListener(map, "tilesloaded", () => {
            setTimeout(() => {
                markerClusterersProp.forEach(function (t) {
                    t.clusters.forEach(function (t) {
                        if (t.markers.length > 1) {
                            let n = []
                            t.markers.forEach(function (t) {
                                mapEntriesProp.find((e) => {
                                    return e.marker === t
                                })
                                n.push(100)
                            })
                            let i = null
                            let r = "-10px"
                            if (n.length > 1) {
                                n = n.sort(function (t, e) {
                                    return t - e
                                })
                                i =
                                    "$" +
                                    Number(n[0].toFixed()).toLocaleString() +
                                    "-$" +
                                    Number(
                                        n[n.length - 1].toFixed()
                                    ).toLocaleString()
                                r = "-28px"
                            } else {
                                if (1 === n.length) {
                                    i =
                                        "$" +
                                        Number(n[0].toFixed()).toLocaleString()
                                }
                                if (
                                    i &&
                                    !t.clusterIcon_.div_.innerHTML.includes(
                                        "span"
                                    )
                                ) {
                                    t.clusterIcon_.div_.innerHTML =
                                        '<span class="marker-cluster-label" style="position: absolute; top: -27px; left: ' +
                                        r +
                                        '; color: #007FC1">' +
                                        i +
                                        "</span>" +
                                        t.clusterIcon_.div_.innerHTML
                                }
                            }
                        }
                    })
                })
            }, 325)
        })

        let isMatched = false
        const { lat, lon } = currentProviderData?.address?.location || {}
        addressResp.forEach((ele) => {
            if (
                ele.provider_id === currentProviderData?.provider_id &&
                ele.address.location.lat === lat &&
                ele.address.location.lon === lon
            ) {
                isMatched = true
            }
        })
        if (lat && lon && isMatched) {
            let selectedRecord = new google.maps.LatLng(lat, lon)
            setTimeout(() => {
                map.setCenter(selectedRecord)
                map.panTo(selectedRecord)
            }, 100)
            // map.setZoom(10)
            // if (mapEntries && mapEntries.length) {
            //     mapEntries.forEach((item, i) => {
            //         if (item.id === currentProviderData.provider_id) {
            //             mapEntries[i].setIcon(pin_multiple_highlighted_large)
            //         }
            //     })
            // }
        }
        setTimeout(() => {
            if (count === markerData.length) {
                map.setZoom(15)
            }
        }, 50)
    }

    const callBackClose = () => {
        setGroupedLocationList()
    }

    return (
        <>
            <div id="map"></div>
            <GroupModal
                groupedLocation={groupedLocationList}
                callBackClose={callBackClose}
                providerDetailsIndexOne={providerDetailsIndexOne}
            />
        </>
    )
}

export default Map
