import React from "react"
import Backdrop from "@mui/material/Backdrop"
import CircularProgress from "@mui/material/CircularProgress"
import Box from "@mui/material/Box"

import "./CircularLoader.css"

const CircularLoader = () => {
    const [open] = React.useState(true)
    return (
        <Box className="circular-loader">
            <Backdrop
                open={open}
                sx={{
                    color: "#fff",
                    zIndex: 9999
                }}
            >
                <CircularProgress />
            </Backdrop>
        </Box>
    )
}
export default CircularLoader
