import { put, select, takeLatest } from "redux-saga/effects"

import { getProviderListService } from "../service/getProviderList.service"
import * as Types from "../actionTypes/index"

const state = (store) => store
export function* getProviderDetails(action) {
    const { payload } = action
    try {
        const storeData = yield select(state)
        if (payload?.type === "navSearch" || payload?.type === "loadMore") {
            yield put({ type: Types.SHOW_LOADER })
        }
        const { tokenInfo, userInfo, nearByZipcodes } = storeData || {}
        const { access_token } = tokenInfo || {}
        const sponsorId = userInfo?.["custom:sponsorid"]
        const employeeId = userInfo?.["custom:employeeid"]
        const id = payload?.pageChange
            ? payload?.unique_id
            : payload?.providerId
        const results = yield getProviderListService({
            token: access_token,
            payload,
            subType: payload.subType,
            detailType: "detailed",
            sponsorId: sponsorId,
            employeeId: employeeId,
            providerId: id,
            nearByZipcodes
        })
        const { data, apiStatus } = results
        yield put({
            type: Types.SET_SELECTED_CATEGORY_PAGE_LEVEL_ERROR
        })
        yield put({
            type: Types.SHOW_PAGE_LEVEL_ERROR
        })
        yield put({
            type: Types.SHOW_CATEGORY_PAGE
        })
        yield put({
            type: Types.NAV_SEARCH_MODAL_STATUS,
            payload: false
        })
        if (apiStatus === "success") {
            yield put({
                type: Types.GET_PROVIDER_DETAILS_SUCCESS,
                providerDetailsData: data?.providers || [],
                count: data.count || 0
            })
            yield put({
                type: Types.GET_PREFERRED_DATA,
                preferredDetailsData: data?.preferred
            })
            if (payload?.type !== "navSearch") {
                if (
                    data?.providers?.length > 0 ||
                    data?.preferred?.length > 0
                ) {
                    yield put({
                        type: "SET_CATEGORY_VIEW_SHOW",
                        payload: false
                    })
                    payload.navigate("/ProviderResults")
                } else {
                    yield put({
                        type: Types.GLOBAL_ERROR_MESSAGE,
                        payload:
                            "There are no providers in the area you searched. Please try searching a new area for additional provider options."
                    })
                    yield put({
                        type: Types.SHOW_PAGE_LEVEL_ERROR,
                        payload: true
                    })
                    // payload.navigate("/error")
                }
            } else if (payload?.type === "navSearch") {
                yield put({
                    type: Types.IS_SEARCHTIP_MODAL_SHOW,
                    payload: true
                })
                if (
                    data?.preferred?.length !== 1 &&
                    (data?.providers?.length === 0 || !data?.providers?.length)
                ) {
                    yield put({
                        type: Types.GLOBAL_ERROR_MESSAGE,
                        payload:
                            "There are no providers in the area you searched. Please try searching a new area for additional provider options."
                    })
                    yield put({
                        type: Types.SET_SELECTED_CATEGORY_PAGE_LEVEL_ERROR,
                        payload: true
                    })
                    payload.navigate("/error")
                }
            }
        } else if (results.response.status === 404) {
            yield put({
                type: Types.GLOBAL_ERROR_MESSAGE,
                payload:
                    "There are no providers in the area you searched. Please try searching a new area for additional provider options."
            })
            yield put({
                type: Types.SET_SELECTED_CATEGORY_PAGE_LEVEL_ERROR,
                payload: true
            })
            payload.navigate("/error")
        } else {
            yield put({ type: Types.GET_PROVIDER_DETAILS_FAIL })
            yield put({
                type: Types.GLOBAL_ERROR_MESSAGE,
                payload:
                    "We're sorry to say we're experiencing a service outage. Our team is working quickly to fix the issue."
            })
            yield put({
                type: Types.SET_SELECTED_CATEGORY_PAGE_LEVEL_ERROR,
                payload: true
            })
            payload.navigate("/error")
        }
        const tokenData = sessionStorage.getItem("tokenData")
        if (tokenData) {
            yield put({ type: Types.LOGIN_TYPE, payload: "vp login" })
            //  const tokenInfo = JSON.parse(tokenData)
            // yield put({ type: Types.GET_REFRESH_TOKEN, tokenInfo })
        } else {
            const client_id = process.env.REACT_APP_CLIENT_ID
            const url = `${process.env.REACT_APP_API_BASE_URL}/oauth2/authorize?identity_provider=Virgin-Pulse&redirect_uri=${process.env.REACT_APP_SPA_DOMAIN_URL}&response_type=CODE&client_id=${client_id}`
            window.location.href = url
        }
        yield put({ type: Types.HIDE_LOADER })
    } catch (error) {
        yield put({ type: Types.HIDE_LOADER })
        yield put({ type: Types.GET_PROVIDER_DETAILS_FAIL })
    }
}

export function* getProviderDetailsSaga() {
    yield takeLatest(Types.GET_PROVIDER_DETAILS, getProviderDetails)
}
