import React from "react"
import { useSelector } from "react-redux"
import { ReactComponent as TierIcon } from "../../../assets/images/tier_starIcon.svg"
import { getTierBgColor } from "./../../../utils/common"
import "./Style.css"
import { Box, Card, Divider, Stack } from "@mui/material"
import { useTheme } from "@mui/material/styles"

const NetworkPhamacies = () => {
    const { selectedDrugDetails } = useSelector((state) => state)
    const muiTheme = useTheme()
    let drugPharmacies = selectedDrugDetails?.pharmacy

    // sorting
    const sortedPharmacies =
        drugPharmacies &&
        Object.entries(drugPharmacies).length > 0 &&
        Object.entries(drugPharmacies)
            .map(([key, value]) => ({ ...value, name: key }))
            .sort((a, b) => a.tier - b.tier)

    return (
        <div className="pharmacies-container">
            {sortedPharmacies &&
                sortedPharmacies.length &&
                sortedPharmacies.map(
                    ({ tier, tierName, copayAmt, period }, index) => (
                        <Card
                            className="pharmacy-column"
                            key={index}
                            sx={{
                                flex: {
                                    xs: "1 1 100%",
                                    sm: "1 1 calc(50% - 20px)",
                                    md: "1 1 calc(25% - 20px)"
                                }
                            }}
                        >
                            <Box
                                sx={{
                                    background: muiTheme.palette.primary.main,
                                    color: muiTheme.palette.primary.text,
                                    fontSize: 18,
                                    height: 66,
                                    lineHeight: "22px",
                                    fontWeight: 500,
                                    alignContent: "center",
                                    textAlign: "center"
                                }}
                            >
                                {tierName}
                            </Box>
                            <Box
                                sx={{
                                    fontSize: 40,
                                    fontWeight: 500,
                                    textAlign: "center",
                                    padding: muiTheme.spacing(8)
                                }}
                            >
                                {`$${copayAmt}`}
                            </Box>
                            <Divider />
                            <Stack
                                direction="row"
                                justifyContent="center"
                                alignItems="center"
                                spacing={2}
                                sx={{ padding: 6 }}
                            >
                                <div>{`Up to ${period} days supply`}</div>
                                {tier && (
                                    <>
                                        <Box
                                            sx={{
                                                width: 30,
                                                height: 30,
                                                position: "relative"
                                            }}
                                        >
                                            <TierIcon
                                                fill={getTierBgColor(tier)}
                                            />
                                            <div className="final-tier">
                                                {tier}
                                            </div>
                                        </Box>
                                        <span> Tier</span>
                                    </>
                                )}
                            </Stack>
                        </Card>
                    )
                )}
        </div>
    )
}

export default NetworkPhamacies
