import React, { useState } from "react"
import { Box, Divider, Link, Pagination, Stack, useTheme } from "@mui/material"

const ADRows = ({ records = [], getDrugDetails = () => {} }) => {
    const [page, setPage] = useState(1)
    const recordsPerPage = 10
    const pageCount = Math.ceil(records.length / recordsPerPage)
    const muiTheme = useTheme()
    const handleChange = (event, value) => {
        setPage(value)
    }

    const paginatedRecords = records.slice(
        (page - 1) * recordsPerPage,
        page * recordsPerPage
    )
    return (
        <>
            {paginatedRecords &&
                paginatedRecords.map((row, index) => {
                    return (
                        <>
                            <Link
                                className="raw-link"
                                key={index}
                                href="javascript:;"
                                onClick={(e) => getDrugDetails(e, row)}
                            >
                                {row.name}
                            </Link>
                            <Divider />
                        </>
                    )
                })}
            {paginatedRecords.length < 1 && (
                <Box
                    sx={{
                        textAlign: "center",
                        padding: "20px",
                        color: muiTheme.palette.grey.text
                    }}
                >
                    No alternative found.
                </Box>
            )}
            {records.length > recordsPerPage && (
                <Stack spacing={2} sx={{ alignItems: "flex-end" }}>
                    <Pagination
                        count={pageCount}
                        page={page}
                        shape="rounded"
                        onChange={handleChange}
                        variant="outlined"
                        color="primary"
                    />
                </Stack>
            )}
        </>
    )
}

ADRows.propTypes = {
    records: [],
    getDrugDetails: () => {}
}
export default ADRows
