import React from "react"
import { Card, Tab, Tabs, Typography, useTheme } from "@mui/material"
import TabPanel from "@material-ui/lab/TabPanel"
import TabContext from "@material-ui/lab/TabContext"
import ADRows from "./Rows"

const AlternativeTabs = ({
    name = "",
    data = {},
    getDrugDetails = () => {}
}) => {
    const [value, setValue] = React.useState(0)
    const muiTheme = useTheme()
    const handleChange = (event, newValue) => {
        setValue(newValue)
    }

    return (
        <>
            <Typography
                variant="h3"
                component="h2"
                sx={{ marginBottom: muiTheme.spacing(8) }}
            >
                {name}
            </Typography>
            <Card>
                <TabContext value={value}>
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        indicatorColor="primary"
                        textColor="inherit"
                        variant="fullWidth"
                        sx={{
                            "& .MuiTab-root": {
                                backgroundColor: muiTheme.palette.grey.bg,
                                "&.Mui-selected": {
                                    color: muiTheme.palette.primary.text,
                                    backgroundColor:
                                        muiTheme.palette.primary.main
                                }
                            }
                        }}
                    >
                        <Tab label="Generic" />
                        <Tab label="Preferred" />
                        <Tab label="Non-Preferred" />
                        <Tab label="Specialty" />
                    </Tabs>

                    <TabPanel value={value} index={0}>
                        {value === 0 && (
                            <ADRows
                                records={data?.generic}
                                getDrugDetails={getDrugDetails}
                            />
                        )}
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        {value === 1 && (
                            <ADRows
                                records={data?.preferred}
                                getDrugDetails={getDrugDetails}
                            />
                        )}
                    </TabPanel>
                    <TabPanel value={value} index={2}>
                        {value === 2 && (
                            <ADRows
                                records={data?.nonPreferred}
                                getDrugDetails={getDrugDetails}
                            />
                        )}
                    </TabPanel>
                    <TabPanel value={value} index={3}>
                        {value === 3 && (
                            <ADRows
                                records={data?.specialty}
                                getDrugDetails={getDrugDetails}
                            />
                        )}
                    </TabPanel>
                </TabContext>
            </Card>
        </>
    )
}
AlternativeTabs.propTypes = {
    name: "",
    data: {},
    getDrugDetails: () => {}
}
export default AlternativeTabs
