import {
    BENEFIT_MANAGER,
    FORMULARY_TYPE,
    PREV_OPTIONS
} from "../../../utils/constants"

import Medone_CP_Access from "../../../assets/pdfs/Medone-Coupe/Step_Therapy_Access.pdf"
import Medone_CP_Performance from "../../../assets/pdfs/Medone-Coupe/Step_Therapy_Performance.pdf"
import Medone_SP_Access from "../../../assets/pdfs/Medone-SimplePay/Step_Therapy_Access.pdf"
import Medone_SP_Performance from "../../../assets/pdfs/Medone-SimplePay/Step_Therapy_Performance.pdf"
import CVS_SP_Standard from "../../../assets/pdfs/CVS-SimplePay/Step_Theraphy_Standard.pdf"

const formatString = (input) => {
    return input && input.toLowerCase().replace(/\s+/g, "")
}

const addLinkinText = (link, label, text) => {
    const anchorLink = `<a href="${link}" target="_blank" rel="noopener noreferrer">${label}</a>`
    return text.replace(label, anchorLink)
}

const getStepTherapyPDF = (benefitManager, formularyType, text) => {
    const { MEDONE_ACCESS, MEDONE_PERFORMANCE } = FORMULARY_TYPE
    const { MEDONE_COUPE, MEDONE_SIMPLE_PAY, CVS_SIMPLE_PAY } = BENEFIT_MANAGER
    const formatedFormularyType = formatString(formularyType)

    if (benefitManager === MEDONE_COUPE) {
        if (formatedFormularyType === formatString(MEDONE_ACCESS)) {
            return addLinkinText(
                Medone_CP_Access,
                "Step Therapy Program PDF",
                text
            )
        } else if (formatedFormularyType === formatString(MEDONE_PERFORMANCE)) {
            return addLinkinText(
                Medone_CP_Performance,
                "Step Therapy Program PDF",
                text
            )
        }
    } else if (benefitManager === MEDONE_SIMPLE_PAY) {
        if (formatedFormularyType === formatString(MEDONE_ACCESS)) {
            return addLinkinText(
                Medone_SP_Access,
                "Step Therapy Program PDF",
                text
            )
        } else if (formatedFormularyType === formatString(MEDONE_PERFORMANCE)) {
            return addLinkinText(
                Medone_SP_Performance,
                "Step Therapy Program PDF",
                text
            )
        }
    } else if (benefitManager === CVS_SIMPLE_PAY) {
        return addLinkinText(CVS_SP_Standard, "PDF", text)
    }
}

export const getPriorAuthDisclaimer = (benefitManager, defaultNotices) => {
    const { MEDONE_COUPE, MEDONE_SIMPLE_PAY, CVS_SIMPLE_PAY } = BENEFIT_MANAGER
    let textVal = ""

    if (benefitManager === CVS_SIMPLE_PAY) {
        textVal = defaultNotices["rx_product_prior_authorization_cvs_simplepay"]
    } else if (benefitManager === MEDONE_COUPE) {
        textVal = defaultNotices["rx_product_prior_authorization_medone_coupe"]
    } else if (benefitManager === MEDONE_SIMPLE_PAY) {
        textVal =
            defaultNotices["rx_product_prior_authorization_medone_simplepay"]
    }

    return textVal
}

export const getStepTherapyDisclaimer = (
    benefitManager,
    formularyType,
    defaultNotices
) => {
    const { MEDONE_COUPE, MEDONE_SIMPLE_PAY, CVS_SIMPLE_PAY } = BENEFIT_MANAGER
    let textVal = ""

    if (benefitManager === CVS_SIMPLE_PAY) {
        textVal = defaultNotices["rx_step_therapy_disclaimer_cvs_simplepay"]
    } else if (benefitManager === MEDONE_COUPE) {
        textVal = defaultNotices["rx_step_therapy_disclaimer_medone_coupe"]
    } else if (benefitManager === MEDONE_SIMPLE_PAY) {
        textVal = defaultNotices["rx_step_therapy_disclaimer_medone_simplepay"]
    }

    if (textVal) {
        return getStepTherapyPDF(benefitManager, formularyType, textVal)
    }

    return null
}

export const getPreventativeDisclaimer = (
    preventativeOption,
    displayNotices
) => {
    if (preventativeOption === PREV_OPTIONS.PREV_A) {
        return displayNotices["rx_preventative_option_A"]
    } else if (
        preventativeOption === PREV_OPTIONS.PREV_B ||
        preventativeOption === PREV_OPTIONS.PREV_C
    ) {
        return displayNotices["rx_preventative_option_B_C"]
    }

    return null
}

export const getCustomSearchDisclaimer = (displayNotices) => {
    return displayNotices["rx_custom_search_result_disclaimer"]
}

export const getPharmacyCopayDisclaimer = (benefitManager, displayNotices) => {
    const { MEDONE_COUPE, MEDONE_SIMPLE_PAY, CVS_SIMPLE_PAY } = BENEFIT_MANAGER

    if (benefitManager === CVS_SIMPLE_PAY) {
        return displayNotices["rx_pharmacy_copay_disclaimer_simplepay"]
    } else if (benefitManager === MEDONE_COUPE) {
        return displayNotices["rx_pharmacy_copay_disclaimer_coupe"]
    } else if (benefitManager === MEDONE_SIMPLE_PAY) {
        return displayNotices["rx_pharmacy_copay_disclaimer_simplepay"]
    }

    return null
}

export const getMaintenanceDisclaimer = (benefitManager, displayNotices) => {
    const { CVS_SIMPLE_PAY } = BENEFIT_MANAGER

    if (benefitManager === CVS_SIMPLE_PAY) {
        return displayNotices["rx_search_maintenance_indication_cvs"]
    }

    return null
}

export const getMaintenanceOptOutDisclaimer = (
    benefitManager,
    displayNotices
) => {
    const { CVS_SIMPLE_PAY } = BENEFIT_MANAGER

    if (benefitManager === CVS_SIMPLE_PAY) {
        return displayNotices["rx_search_maintenance_indication_opt_out_cvs"]
    }

    return null
}

export const getAgeLimitDisclaimer = (benefitManager, displayNotices) => {
    const { MEDONE_COUPE, MEDONE_SIMPLE_PAY } = BENEFIT_MANAGER

    if (benefitManager === MEDONE_COUPE) {
        return displayNotices["rx_age_limit_disclaimer_medone_coupe"]
    } else if (benefitManager === MEDONE_SIMPLE_PAY) {
        return displayNotices["rx_age_limit_disclaimer_medone_simplepay"]
    }

    return null
}

export const getQuantityLimitDisclaimer = (benefitManager, displayNotices) => {
    const { MEDONE_COUPE, MEDONE_SIMPLE_PAY } = BENEFIT_MANAGER

    if (benefitManager === MEDONE_COUPE) {
        return displayNotices["rx_quantity_limit_disclaimer_medone_coupe"]
    } else if (benefitManager === MEDONE_SIMPLE_PAY) {
        return displayNotices["rx_quantity_limit_disclaimer_medone_simplepay"]
    }

    return null
}

export const getSpecTierExcDisclaimer = (benefitManager, displayNotices) => {
    const { MEDONE_COUPE, MEDONE_SIMPLE_PAY, CVS_SIMPLE_PAY } = BENEFIT_MANAGER

    if (benefitManager === CVS_SIMPLE_PAY) {
        return displayNotices["rx_specialty_tier_exclusion_cvs_simplepay"]
    } else if (benefitManager === MEDONE_COUPE) {
        return displayNotices["rx_specialty_tier_exclusion_medone_coupe"]
    } else if (benefitManager === MEDONE_SIMPLE_PAY) {
        return displayNotices["rx_specialty_tier_exclusion_medone_simplepay"]
    }

    return null
}
