import React, { useContext, useEffect, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { useSelector } from "react-redux"

import IconButton from "@mui/material/IconButton"
import MoreVertIcon from "@mui/icons-material/MoreVert"
import Menu from "@mui/material/Menu"
import MenuItem from "@mui/material/MenuItem"
import HomeIcon from "@mui/icons-material/Home"
import ContactMailIcon from "@mui/icons-material/ContactMail"
import SecurityIcon from "@mui/icons-material/Security"
import AssignmentIcon from "@mui/icons-material/Assignment"
import BuildIcon from "@mui/icons-material/Build"
import { ListItemText, Toolbar } from "@mui/material"

import Nav from "../nav/Nav"
import darkHeaderImg from "../../assets/images/Coupe-Green-bubble-logo.svg"
import headerImg from "../../assets/images/SPH-NewHeader-logo.svg"
import { ThemeContext } from "../../Theme"
import { checkIsCSurlDisplay, currentNavigation } from "../../utils/common"

import "./Header.css"

const Header = () => {
    const { loginType, isLoginurl } = useSelector((state) => state)
    const location = useLocation()
    const navigate = useNavigate()
    const { theme } = useContext(ThemeContext)
    const [anchorEl, setAnchorEl] = useState(null)
    const [showNav, setShowNav] = useState(false)
    const open = Boolean(anchorEl)
    const [hideSupportUrl, setHideSupportUrl] = useState({})
    const params = new URLSearchParams(location.search)

    useEffect(() => {
        setShowNav(location.pathname)
    }, [location])

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget)
    }
    const handleClose = () => {
        setAnchorEl(null)
    }

    useEffect(() => {
        const { productLinkStatus, empLinkStatus, csLink, csText } =
            checkIsCSurlDisplay(params)
        setHideSupportUrl({
            productLinkStatus,
            empLinkStatus,
            csLink,
            csText
        })
    }, [])

    const getCsLinks = (theme) => {
        return theme === "coupe-health"
            ? "https://employers.coupehealth.com/CoupeHealthContact.html"
            : "https://employers.simplepayhealth.com/SimplePayHealthContact.html"
    }
    const options = [
        {
            name: "Home",
            icon: <HomeIcon sx={{ color: "rgba(0,0,0,.54)" }} />,
            divider: true,
            navUrl: "home",
            openStatus: "same-window",
            url: process.env.REACT_APP_SPA_HOME_URL
        },
        {
            name: "Contact Your Health Valet",
            icon: <ContactMailIcon sx={{ color: "rgba(0,0,0,.54)" }} />,
            divider: true,
            hide:
                hideSupportUrl.productLinkStatus ||
                hideSupportUrl.empLinkStatus,
            link: hideSupportUrl.csLink
                ? hideSupportUrl.csLink
                : getCsLinks(theme)
        },
        {
            name: "Privacy Policy",
            icon: <SecurityIcon sx={{ color: "rgba(0,0,0,.54)" }} />,
            link:
                theme === "coupe-health"
                    ? "https://www.coupehealth.com/wp-content/uploads/2021/06/privacy-statement.pdf"
                    : "https://simplepayhealth.stella-webhosting-p.io-prod.stellahealth.net/wp-content/uploads/2021/02/sph-privacy.pdf"
        },
        {
            name: "Terms of Services",
            icon: <AssignmentIcon sx={{ color: "rgba(0,0,0,.54)" }} />,
            link:
                theme === "coupe-health"
                    ? "https://www.coupehealth.com/wp-content/uploads/2021/06/terms-of-use.pdf"
                    : "https://simplepayhealth.stella-webhosting-p.io-prod.stellahealth.net/wp-content/uploads/2021/02/sph-tou.pdf"
        },
        {
            name: "Support",
            icon: <BuildIcon sx={{ color: "rgba(0,0,0,.54)" }} />,
            hide:
                hideSupportUrl.productLinkStatus ||
                hideSupportUrl.empLinkStatus ||
                hideSupportUrl.csLink,
            link:
                theme === "coupe-health"
                    ? "https://employers.coupehealth.com/support-coupe.html"
                    : "https://employers.simplepayhealth.com/support.html"
        }
    ]

    // const filteredOptions = options.filter((option) => {
    //     if (
    //         option.name === "Support" ||
    //         option.name === "Contact Your Health Valet"
    //     ) {
    //         return false
    //     }
    //     return true
    // })

    const navigateToHome = () => {
        if (location.pathname !== "/" && location.pathname !== "/employers/") {
            navigate("/")
        }
    }

    return (
        <>
            {" "}
            {theme ? (
                <>
                    <div className="page-header">
                        <Toolbar>
                            <header className="align-items-center col d-flex justify-content-between">
                                <div
                                    className="navigation-logo"
                                    onClick={navigateToHome}
                                >
                                    {theme === "coupe-health" && (
                                        <img
                                            src={darkHeaderImg}
                                            alt="page header"
                                        />
                                    )}
                                    {theme === "simplePay-health" && (
                                        <img
                                            src={headerImg}
                                            alt="page header"
                                        />
                                    )}
                                </div>
                                <IconButton
                                    aria-label="more"
                                    id="long-button"
                                    aria-controls={
                                        open ? "long-menu" : undefined
                                    }
                                    aria-expanded={open ? "true" : undefined}
                                    aria-haspopup="true"
                                    onClick={handleClick}
                                >
                                    <MoreVertIcon
                                        sx={{
                                            color: "var(--text-common)"
                                        }}
                                    />
                                </IconButton>

                                <Menu
                                    id="long-menu"
                                    className="menu-bar"
                                    MenuListProps={{
                                        "aria-labelledby": "long-button"
                                    }}
                                    anchorEl={anchorEl}
                                    open={open}
                                    onClose={handleClose}
                                >
                                    {options?.map((option, i) => {
                                        if (!option.hide) {
                                            return (
                                                <MenuItem
                                                    className={
                                                        "menu-item-li" +
                                                        (option.divider
                                                            ? " MuiDivider-root"
                                                            : "")
                                                    }
                                                    key={i}
                                                    selected={
                                                        option === "Pyxis"
                                                    }
                                                    onClick={() => {
                                                        if (
                                                            option?.navUrl ===
                                                            "home"
                                                        ) {
                                                            if (
                                                                location.pathname !==
                                                                    "/" &&
                                                                loginType ===
                                                                    "vp login"
                                                            ) {
                                                                window.location =
                                                                    option.url
                                                            } else if (
                                                                location.pathname ===
                                                                    "/" &&
                                                                loginType ===
                                                                    "vp login"
                                                            ) {
                                                                return null
                                                            } else if (
                                                                location.pathname !==
                                                                    "/" &&
                                                                location.pathname !==
                                                                    "/employers/" &&
                                                                loginType !==
                                                                    "external user login"
                                                            ) {
                                                                navigate("/")
                                                            } else if (
                                                                isLoginurl
                                                            ) {
                                                                if (
                                                                    location.pathname !==
                                                                        "/" &&
                                                                    location.pathname !==
                                                                        "/employers/"
                                                                ) {
                                                                    navigate(
                                                                        "/"
                                                                    )
                                                                } else {
                                                                    return null
                                                                }
                                                            } else if (
                                                                loginType ===
                                                                "external user login"
                                                            ) {
                                                                //loginType === "external user login" ? "/internal" : null
                                                                navigate(
                                                                    "/internal"
                                                                )
                                                            } else {
                                                                navigate("/")
                                                            }
                                                        } else if (
                                                            option?.navUrl
                                                        ) {
                                                            navigate(
                                                                option.navUrl
                                                            )
                                                        } else if (
                                                            option?.link
                                                        ) {
                                                            window.open(
                                                                option.link
                                                            )
                                                        } else if (
                                                            option?.openStatus ===
                                                            "same-window"
                                                        ) {
                                                            window.location =
                                                                option.url
                                                        }
                                                        setAnchorEl(null)
                                                    }}
                                                >
                                                    {option.icon}
                                                    <ListItemText>
                                                        <a
                                                            className="menu-anchor"
                                                            rel="noreferrer"
                                                            onClick={(e) => {
                                                                e.preventDefault()
                                                            }}
                                                        >
                                                            {option.name}
                                                        </a>
                                                    </ListItemText>
                                                </MenuItem>
                                            )
                                        } else {
                                            return ""
                                        }
                                    })}
                                </Menu>
                            </header>
                        </Toolbar>
                    </div>
                    {currentNavigation(showNav) && <Nav />}
                </>
            ) : (
                ""
            )}
        </>
    )
}

export default Header
