import React from "react"
import { CardContent, Card, Typography, useTheme } from "@mui/material"
import { getPharmacyCopayDisclaimer } from "./Utils"

const BasicInfo = ({ drugDetails = {}, displayNotices = [] }) => {
    const muiTheme = useTheme()
    return (
        <>
            <Card>
                <CardContent>
                    <Typography variant="h3" component="h1">
                        {`${drugDetails?.name}`}{" "}
                        {drugDetails?.count !== null
                            ? `${drugDetails?.count} Count`
                            : ``}
                    </Typography>
                    <Typography component={`p`} sx={{ opacity: 0.8 }}>
                        {`${drugDetails?.tierName}`}
                    </Typography>
                    <Typography
                        component={`h4`}
                        sx={{
                            marginBottom: muiTheme.spacing(2),
                            marginTop: muiTheme.spacing(10)
                        }}
                    >
                        <strong>Drug</strong>
                    </Typography>
                    <p>
                        {drugDetails?.description}{" "}
                        {drugDetails?.count !== null
                            ? `${drugDetails?.count} Count`
                            : ``}{" "}
                    </p>
                </CardContent>
            </Card>
            <Card className="mt-4">
                <CardContent>
                    <p>
                        <strong>Your Pharmacy Copay</strong>
                    </p>
                    <div
                        dangerouslySetInnerHTML={{
                            __html: getPharmacyCopayDisclaimer(
                                drugDetails.benefitManager,
                                displayNotices
                            )
                        }}
                    />
                </CardContent>
            </Card>
        </>
    )
}
BasicInfo.propTypes = {
    drugDetails: {},
    displayNotices: []
}
export default BasicInfo
