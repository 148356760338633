import { put, takeLatest } from "redux-saga/effects"

import getCityZipcodeService from "../service/getCityZipcode.service"
import * as Types from "../actionTypes/index"

export function* getCityZipcode(action) {
    const { payload } = action
    try {
        const response = yield getCityZipcodeService(payload)
        const { data, apiStatus } = response
        if (apiStatus === "success") {
            if (data?.results?.length) {
                const address_components = data.results[0].address_components
                const zipCode_components = address_components?.find((comp) =>
                    comp.types.includes("postal_code")
                )
                yield put({
                    type: Types.GET_CITY_ZIPCODE_SUCCESS,
                    zipcode: zipCode_components.short_name
                })
            } else {
                yield put({ type: Types.GET_CITY_ZIPCODE_FAIL })
            }
        } else {
            yield put({ type: Types.GET_CITY_ZIPCODE_FAIL })
        }
    } catch (error) {
        yield put({ type: Types.GET_CITY_ZIPCODE_FAIL })
        yield put({
            type: Types.GLOBAL_ERROR_MESSAGE,
            payload:
                "We're sorry to say we're experiencing a service outage. Our team is working quickly to fix the issue."
        })
    }
}

export function* getCityZipcodeSaga() {
    yield takeLatest(Types.GET_CITY_ZIPCODE, getCityZipcode)
}
