import React, { useEffect, createContext, useState, useMemo } from "react"
import {
    ThemeProvider as MuiThemeProvider,
    createTheme
} from "@mui/material/styles"
import { coupeTheme, simplePayTheme, defaultTheme } from "./themeConfig"

import { PropTypes } from "prop-types"
import { useSelector } from "react-redux"

const ThemeContext = createContext()

const ThemeProvider = ({ children }) => {
    const { userInfo = {} } = useSelector((state) => state)
    const userName = userInfo["custom:sponsorid"]
    const [theme, setTheme] = useState("")

    const muiTheme = useMemo(() => {
        if (theme === "coupe-health") {
            return createTheme(coupeTheme)
        } else if (theme === "simplePay-health") {
            return createTheme(simplePayTheme)
        } else {
            return createTheme(defaultTheme)
        }
    }, [theme])

    function toggleTheme() {
        if (userName === "7140") {
            setTheme("coupe-health")
        } else if (userName === "6106") {
            setTheme("simplePay-health")
        }
    }
    useEffect(() => {
        toggleTheme()
    }, [userName])

    return (
        <ThemeContext.Provider
            value={{
                theme,
                setTheme,
                toggleTheme
            }}
        >
            <MuiThemeProvider theme={muiTheme}>{children}</MuiThemeProvider>
        </ThemeContext.Provider>
    )
}

ThemeProvider.propTypes = {
    children: PropTypes.any
}
export { ThemeContext, ThemeProvider }
