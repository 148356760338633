import { put, select, takeLatest } from "redux-saga/effects"

import getPublicBundledSPDService from "../service/getPublicBundledSPD.service"
import * as Types from "../actionTypes/index"

const state = (store) => store
export function* getPublicBundledSPD(action) {
    const { payload } = action
    yield put({ type: Types.INCREASE_LOADER_COUNT })
    try {
        const storeData = yield select(state)
        if (
            payload?.type === "navSearch" ||
            payload?.type === "loadMore" ||
            payload?.getAllResults
        ) {
            yield put({ type: Types.INCREASE_LOADER_COUNT })
        }
        const { userInfo, nearByZipcodes } = storeData || {}
        const sponsorId = userInfo?.["custom:sponsorid"]
        const results = yield getPublicBundledSPDService({
            payload,
            detailType: "basic",
            sponsorId: sponsorId,
            employerId: userInfo?.employerId || "",
            planId: userInfo?.planId || "",
            nearByZipcodes
        })
        const { data, apiStatus } = results
        let proceduresList = []
        if (apiStatus === "success") {
            const procedures = data?.procedures ? data.procedures : []
            proceduresList = [...procedures]
            yield put({
                type: Types.GET_CONDITIONS_PROCEDURES_DATA_SUCCESS,
                data: [...proceduresList]
            })
            yield put({
                type: Types.GET_SPECIALTYLIST_SUCCESS,
                specialties: data?.specialties
            })
            yield put({
                type: "SET_CATEGORY_VIEW_SHOW",
                payload: true
            })
            if (!proceduresList?.length) {
                yield put({
                    type: Types.GLOBAL_ERROR_MESSAGE,
                    payload:
                        "There are no providers in the area you searched. Please try searching a new area for additional provider options."
                })
                payload.navigate("/error")
            }
        } else {
            yield put({ type: Types.GET_CONDITIONS_PROCEDURES_DATA_FAIL })
            yield put({
                type: Types.GLOBAL_ERROR_MESSAGE,
                payload:
                    "We're sorry to say we're experiencing a service outage. Our team is working quickly to fix the issue."
            })
            payload.navigate("/error")
        }
        yield put({ type: Types.DECREASE_LOADER_COUNT })
    } catch (error) {
        yield put({ type: Types.GET_CONDITIONS_PROCEDURES_DATA_FAIL })
        yield put({
            type: Types.GLOBAL_ERROR_MESSAGE,
            payload:
                "We're sorry to say we're experiencing a service outage. Our team is working quickly to fix the issue."
        })
        payload.navigate("/error")
        yield put({ type: Types.DECREASE_LOADER_COUNT })
    }
}

export function* getPublicBundledSPDSaga() {
    yield takeLatest(
        Types.GET_PUBLIC_CONDITIONS_PROCEDURES_DATA,
        getPublicBundledSPD
    )
}
