import React, { useEffect, useState, useContext } from "react"
import { useDispatch, useSelector } from "react-redux"
import { PropTypes } from "prop-types"
import Tabs from "@mui/material/Tabs"
import Tab from "@mui/material/Tab"
import Box from "@mui/material/Box"
import Alert from "@mui/material/Alert"

import Card from "@mui/material/Card"
import CardContent from "@mui/material/CardContent"
import Dialog from "@mui/material/Dialog"
import CloseIcon from "@mui/icons-material/Close"
import { IconButton } from "@mui/material"

import LocationSearch from "../../components/providers-services/locationSearch"
import CircularLoader from "../../components/common/circularLoader/CircularLoader"
import { getDirectLandingUrlParams } from "../../utils/common"
import { ThemeContext } from "../../Theme"
import * as Types from "../../redux/actionTypes/index"

import "./Landing.css"
import GetCategoryList from "../../components/providers-services/GetCategoryList"
import { InfoOutlined } from "@mui/icons-material"
import DrugSearchTabContent from "../../components/drug-search/TabContent"
import ErrorPage from "../error/errorPage"

const Landing = ({ type }) => {
    const prod = window.location.origin.includes(
        "https://providersearch.coupehealth.com"
    )
    const stage = window.location.origin.includes(
        "https://providersearch-stage.coupehealth.com"
    )
    const isProd = prod || stage
    const dispatch = useDispatch()
    const { theme } = useContext(ThemeContext)
    const {
        showNavSearchModal,
        showCategorySearch,
        cityZipCode,
        currentProviderDetails,
        showCategoryPage,
        loginType,
        userInfo,
        networkProfilesData,
        showPageLevelError,
        showCategoryPageLevelError,
        selectedNetworkId
    } = useSelector((state) => state)
    const sponsorid = userInfo?.["custom:sponsorid"]
    const userInfoPartnersId = userInfo?.partnersId || ""
    const [showCategorySearchBar, setShowCategorySearchBar] = useState(
        type || showCategoryPageLevelError ? true : false
    )
    const [showCircularLoader, setShowCircularLoader] = useState(
        type === "navSearch" || showCategoryPageLevelError ? true : false
    )
    const params = new URLSearchParams(location.search)
    const [selectedCompleteAddress, setSelectedCompleteAddress] = useState("")
    const [openSnackBar, setOpenSnackBar] = useState(false)
    const [activeTab, setActiveTab] = useState(0)
    const [partnersId, setPartnersId] = useState(userInfoPartnersId)
    const tabLabels = ["ProviderSearch", "DrugSearch"]
    const handleTabClick = (event, newValue) => {
        setActiveTab(newValue)
    }
    useEffect(() => {
        const hash = window.location.hash.replace("#", "")
        const tabIndex = tabLabels.indexOf(hash)
        if (tabIndex !== -1) {
            setActiveTab(tabIndex)
        }
    }, [])

    useEffect(() => {
        window.location.hash = tabLabels[activeTab]
    }, [activeTab])
    //get category related states

    const openCategorySearchBar = (status) => {
        setShowCategorySearchBar(status)
        if (!status) {
            if (
                !(
                    (type === "navSearch" && showCategoryPage) ||
                    showCategoryPageLevelError
                )
            ) {
                dispatch({ type: "RESET_PROVIDERLIST" })
                dispatch({ type: "GET_ZIPCODES_FAIL" })
                dispatch({ type: "GET_PROVIDER_DETAILS_FAIL" })
            }
            setSelectedCompleteAddress("")
        }
    }

    useEffect(() => {
        if (theme === "coupe-health") {
            document.title = "Coupe Health"
        } else {
            document.title = "SimplePay Health"
        }
    }, [theme])
    useEffect(() => {
        if (
            type === "navSearch" ||
            loginType === "external user login" ||
            showCategoryPageLevelError
        ) {
            setOpenSnackBar(false)
        } else {
            setOpenSnackBar(true)
        }
        setTimeout(() => {
            setOpenSnackBar(false)
        }, 6000)
    }, [loginType, type, showCategoryPageLevelError])
    useEffect(() => {
        if (cityZipCode) {
            const obj = {
                ...selectedCompleteAddress,
                zipCode: cityZipCode
            }
            setSelectedCompleteAddress(obj)
        }
    }, [cityZipCode])
    useEffect(() => {
        dispatch({
            type: "SET_CATEGORY_VIEW_SHOW",
            payload: false
        })
        const { sponsorId, employerId, planId, product } =
            getDirectLandingUrlParams(params)
        if (product) {
            setPartnersId(product)
        }
        if (sponsorId && employerId && planId) {
            const userInfo = {
                "custom:sponsorid": sponsorId,
                employerId: employerId,
                planId: planId,
                partnersId: product
            }
            sessionStorage.setItem("userInfo", JSON.stringify(userInfo))
            dispatch({ type: "GET_USER_INFO_SUCCESS", userInfo })
            dispatch({ type: "LOGIN_TYPE", payload: "external user login" })
            dispatch({ type: "IS_LOGIN_URI", payload: true })
        }
    }, [])

    useEffect(() => {
        if (type !== "navSearch" && !showCategoryPageLevelError) {
            dispatch({ type: "RESET_PROVIDERLIST" })
            dispatch({ type: "GET_ZIPCODES_FAIL" })
            dispatch({ type: "GET_PROVIDER_DETAILS_FAIL" })
        }
        const timeoutVal = type !== "navSearch" ? 1000 : 0
        if (type === "navSearch" || showCategoryPageLevelError) {
            setShowCircularLoader(true)
        }
        setTimeout(() => {
            setShowCircularLoader(true)
        }, [timeoutVal])
        // dispatch({ type: Types.GET_USER_INFO })
    }, [])

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [showCircularLoader, showCategorySearchBar])

    const updateSetSelectedCompleteAddress = (data) => {
        setSelectedCompleteAddress(data)
    }

    const showCategoryList = () => {
        return (
            (showCategoryPage && type === "navSearch") ||
            showCategoryPageLevelError ||
            (showCategorySearchBar && showCategorySearch)
        )
    }

    const getNavHeader = () => {
        if (
            (type === "navSearch" && showCategoryPage) ||
            showCategoryPageLevelError
        ) {
            return `Your Search is Near ${currentProviderDetails?.zipCode} (${currentProviderDetails?.city}, ${currentProviderDetails?.state})`
        } else {
            return `Your Search is Near ${selectedCompleteAddress?.zipCode} (${selectedCompleteAddress?.city}, ${selectedCompleteAddress?.state})`
        }
    }

    const getSearchBar = () => {
        return (
            <>
                <div
                    className={`${type === "navSearch" && "nav-search-header"}`}
                >
                    <h2 className="criteria-workflow-title">
                        {showCategoryList() ? (
                            getNavHeader()
                        ) : (
                            <div className="location-search-subheader">
                                Going for care is easy-going.
                            </div>
                        )}
                    </h2>
                    {type === "navSearch" && (
                        <IconButton
                            className="new-search-close-icon"
                            onClick={() => {
                                dispatch({
                                    type: Types.NAV_SEARCH_MODAL_STATUS,
                                    payload: false
                                })
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                    )}
                </div>
                {showCategoryList() ? (
                    <GetCategoryList
                        type={type}
                        openCategorySearchBar={openCategorySearchBar}
                        selectedCompleteAddress={selectedCompleteAddress}
                        showNavSearchModal={showNavSearchModal}
                        currentProviderDetails={currentProviderDetails}
                        showCategoryPage={showCategoryPage}
                    />
                ) : (
                    <LocationSearch
                        selectedNetworkId={selectedNetworkId}
                        sponsorid={sponsorid}
                        partnersId={partnersId}
                        networkProfilesData={networkProfilesData}
                        loginType={loginType}
                        type={type}
                        openCategorySearchBar={openCategorySearchBar}
                        selectedCompleteAddress={selectedCompleteAddress}
                        showNavSearchModal={showNavSearchModal}
                        updateSetSelectedCompleteAddress={
                            updateSetSelectedCompleteAddress
                        }
                    />
                )}
            </>
        )
    }
    const handleErrorClose = () => {
        dispatch({
            type: Types.SHOW_PAGE_LEVEL_ERROR
        })
    }

    return (
        <>
            {showPageLevelError ? (
                <ErrorPage type="pageLevel" handleClose={handleErrorClose} />
            ) : (
                <div
                    className={`${
                        type === "navSearch" ? "nav-search" : ""
                    } landing`}
                >
                    {!showCircularLoader && (
                        <div className="loanding-loader">
                            <CircularLoader />
                        </div>
                    )}
                    {showCircularLoader && (
                        <div className="main-landing" id="mainLanding">
                            {/* Alert for drug search */}
                            <Box
                                sx={{
                                    margin: "10px auto 0",
                                    width: {
                                        xs: "86%",
                                        md: 600
                                    }
                                }}
                            >
                                <Alert
                                    severity="success"
                                    variant="filled"
                                    icon={false}
                                    sx={{ width: "100%" }}
                                    style={{
                                        background: "#f6de30",
                                        color: "#003",
                                        display:
                                            activeTab === 1 ? "flex" : "none"
                                    }}
                                >
                                    <p>
                                        Drug Search feature is only active for
                                        2024 plan benefits. It will become
                                        available for the 2025 benefit year
                                        starting January 1, 2025, if applicable
                                        to your health plan.
                                    </p>
                                    <p>
                                        Until then, you may not receive search
                                        results unless your health plan has
                                        specifically informed you that this
                                        feature is currently active for your
                                        2025 benefits.
                                    </p>
                                </Alert>
                            </Box>
                            {openSnackBar && (
                                <Box sx={{ width: "100%", margin: "auto" }}>
                                    <Alert
                                        icon={
                                            <InfoOutlined fontSize="inherit" />
                                        }
                                        severity="success"
                                        variant="filled"
                                        sx={{ width: "100%" }}
                                        style={{
                                            background: "#f6de30",
                                            color: "#003"
                                        }}
                                    >
                                        We&#39;ve updated the look of your
                                        provider search to improve your
                                        experience but have not changed how you
                                        search
                                    </Alert>
                                </Box>
                            )}
                            <Dialog
                                open={true}
                                hideBackdrop={
                                    type === "navSearch" ? false : true
                                }
                                classes={{
                                    root: `searchModalContainer ${
                                        type === "navSearch"
                                            ? "navbarSearchModalContainer"
                                            : ""
                                    }`
                                }}
                                container={() => {
                                    if (type === "navSearch") {
                                        return document.getElementsByClassName(
                                            "app-content"
                                        )[0]
                                    } else {
                                        return document.getElementById(
                                            "mainLanding"
                                        )
                                    }
                                }}
                            >
                                <section
                                    className={`tab-parent-section ${
                                        isProd ? "full-tab-width" : ""
                                    }`}
                                >
                                    <Tabs
                                        value={activeTab}
                                        onChange={handleTabClick}
                                        aria-label="search address tab"
                                    >
                                        <Tab
                                            label="Provider Search"
                                            id="tab-0"
                                        />
                                        {!isProd ? (
                                            <Tab
                                                disabled={showCategoryList()}
                                                label="Drug Search"
                                                id="tab-1"
                                            />
                                        ) : (
                                            ""
                                        )}
                                    </Tabs>
                                </section>

                                <Card
                                    sx={{
                                        borderTopRightRadius: 0,
                                        borderTopLeftRadius: 0,
                                        minWidth: {
                                            xs: "100%",
                                            sm: "100%",
                                            md: "100%",
                                            lg: "600px"
                                        }
                                    }}
                                    className="content-wrapper"
                                >
                                    {activeTab === 0 && (
                                        <CardContent>
                                            {getSearchBar()}
                                        </CardContent>
                                    )}
                                    {activeTab === 1 && (
                                        <CardContent>
                                            <DrugSearchTabContent />
                                        </CardContent>
                                    )}
                                </Card>
                            </Dialog>
                        </div>
                    )}
                </div>
            )}
        </>
    )
}

Landing.propTypes = {
    type: PropTypes.any
}

export default Landing
