import { put, select, takeLatest } from "redux-saga/effects"

import getRefreshTokenService from "../service/getRefreshToken.service"
import * as Types from "../actionTypes/index"

const state = (store) => store
export function* getRefreshToken() {
    try {
        const storeData = yield select(state)
        const { tokenInfo } = storeData || {}
        const { refresh_token } = tokenInfo || {}
        const results = yield getRefreshTokenService({ refresh_token })
        const { data, apiStatus } = results
        if (apiStatus === "success") {
            yield put({
                type: Types.GET_REFRESH_TOKEN_SUCCESS,
                tokenInfo: data
            })
            yield put({ type: Types.GET_USER_INFO })
        } else {
            sessionStorage.removeItem("tokenData")
            yield put({ type: Types.GET_REFRESH_TOKEN_FAIL })
        }
    } catch (error) {
        sessionStorage.removeItem("tokenData")
        yield put({ type: Types.GET_REFRESH_TOKEN_FAIL })
    }
}

export function* getRefreshTokenSaga() {
    yield takeLatest(Types.GET_REFRESH_TOKEN, getRefreshToken)
}
