import { put, select, takeLatest } from "redux-saga/effects"

import getNetworkProfilesService from "../service/getNetworkProfiles.service"
import * as Types from "../actionTypes/index"

const state = (store) => store
export function* getNetworkProfiles(action) {
    const { partnersId } = action
    try {
        const storeData = yield select(state)
        const { userInfo } = storeData || {}
        const employerId = userInfo?.employerId || ""
        console.log(userInfo)
        let id = partnersId
        if (!id) {
            id = userInfo.partnersId
        }
        const response = yield getNetworkProfilesService(id, employerId)
        const { data, apiStatus } = response
        if (apiStatus === "success") {
            if (data) {
                yield put({
                    type: Types.GET_NETWORK_PROFILES_SUCCESS,
                    data: data
                })
            } else {
                yield put({ type: Types.GET_NETWORK_PROFILES_FAIL })
            }
        } else {
            yield put({ type: Types.GET_NETWORK_PROFILES_FAIL })
        }
    } catch (error) {
        yield put({ type: Types.GET_NETWORK_PROFILES_FAIL })
        yield put({
            type: Types.GLOBAL_ERROR_MESSAGE,
            payload:
                "We're sorry to say we're experiencing a service outage. Our team is working quickly to fix the issue."
        })
    }
}

export function* getNetworkProfilesSaga() {
    yield takeLatest(Types.GET_NETWORK_PROFILES, getNetworkProfiles)
}
