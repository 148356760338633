import React, { useContext } from "react"
import { ThemeContext } from "./../../Theme"
import "./Style.css"
import SearchBar from "./SearchBar"
import { Box } from "@mui/material"

const DrugSearchTabContent = () => {
    const { theme } = useContext(ThemeContext)
    return (
        <>
            <Box
                sx={{ fontWeight: "bold" }}
                className="criteria-workflow-title"
            >
                {theme === "coupe-health"
                    ? "The road to upfront prescription costs"
                    : "Discover upfront prescription costs"}
            </Box>
            <div className="text-left provider-lable-flds">
                <p>
                    Search for your prescribed medication by name in the{" "}
                    {theme === "coupe-health" ? `Coupe` : `SimplePay`} member
                    portal. If you cannot find your medication in the portal,
                    please call the number on the back of your ID card for
                    assistance.
                </p>
                <p>
                    Please note, you will not pay anything to an in-network
                    pharmacist for covered medications – your pharmacy charges
                    will be included on your monthly statement.
                </p>
                <label className="font-weight-bold" htmlFor="drug-search-flds">
                    Search for the price below:
                </label>
                <SearchBar parent="tab" />
            </div>
        </>
    )
}
export default DrugSearchTabContent
