import axiosInstance from "../middleware/axiosInstance"
import { load } from "protobufjs"
import protobuf from "./protos/basicPublicProcedureSpecialty.proto"
import protobufdx from "./protos/basicPublicMedicalCodes.proto"
import { Buffer } from "buffer"

const getBundledSPDResponse = async (encodeResponse, dxcodes) => {
    if (dxcodes) {
        return await load(protobufdx).then(function (root) {
            const BasicDXResponse = root.lookupType(
                "BasicPublicMedicalCodesResponse"
            )
            const decodedResponse = BasicDXResponse.decode(
                Buffer.from(encodeResponse.data, "base64")
            )

            return {
                data: decodedResponse,
                apiStatus: "success",
                cached: encodeResponse.cached
            }
        })
    } else {
        return await load(protobuf).then(function (root) {
            const BasicSPDResponse = root.lookupType(
                "BasicPublicProcedureSpecialtyResponse"
            )
            const decodedResponse = BasicSPDResponse.decode(
                Buffer.from(encodeResponse.data, "base64")
            )
            return {
                data: decodedResponse,
                apiStatus: "success",
                cached: encodeResponse.cached
            }
        })
    }
}

const getBundledSPDService = async ({
    token,
    payload,
    detailType,
    sponsorId,
    employeeId,
    nearByZipcodes,
    dxcodes
}) => {
    const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`
    }
    let url = ""
    const {
        city,
        state,
        zipCode,
        offset = 0,
        procedureCodes,
        diagnosisCode,
        specialtyCode
    } = payload
    const zCode = nearByZipcodes || zipCode
    if (detailType === "basic") {
        if (dxcodes) {
            url = `${process.env.REACT_APP_API_URL_v2}/coupe/v3/medicalcodes?detail=basic&sponsorId=${sponsorId}&employeeId=${employeeId}&state=${state}&city=${city}&zipcode=${zCode}&limit=50000&offset=${offset}&dxcodes=true`
        } else {
            url = `${process.env.REACT_APP_API_URL_v2}/coupe/v3/medicalcodes?detail=basic&sponsorId=${sponsorId}&employeeId=${employeeId}&state=${state}&city=${city}&zipcode=${zCode}&limit=50000&offset=${offset}`
        }

        let basicSearchRes = await axiosInstance({
            method: "get",
            url,
            headers: headers
        })
        return await getBundledSPDResponse(basicSearchRes, dxcodes)
    } else {
        let codesQueryParam = ""
        if (payload.codeType === "diagnosisCode") {
            codesQueryParam = `diagnosisCode=${diagnosisCode}`
        } else if (payload.codeType === "specialtiesCode") {
            codesQueryParam = `specialtyCode=${specialtyCode}`
        } else {
            codesQueryParam = `procedureCodes=${procedureCodes}`
        }
        url = `${process.env.REACT_APP_API_URL_v2}/coupe/v3/medicalcodes?detail=detailed&state=${state}&sponsorId=${sponsorId}&zipcode=${zCode}&employeeId=${employeeId}&${codesQueryParam}&limit=10&offset=${offset}&city=${city}`
    }

    return axiosInstance({
        method: "get",
        url,
        headers: headers
    })
}

export default getBundledSPDService
