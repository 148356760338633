import axios from "axios"

const axiosInstance = axios.create({})
axiosInstance.interceptors.response.use(
    (response) => {
        if (response) {
            return Promise.resolve({
                data: response.data,
                status: response.status,
                cached: response.headers.cached,
                apiStatus: "success"
            })
        } else {
            return Promise.resolve(response)
        }
    },
    (error) => {
        let responseBody = { ...error, apiStatus: "fail" }
        return Promise.resolve(responseBody)
    }
)

export default axiosInstance
