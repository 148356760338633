export const SET_MAP_DATA = "SET_MAP_DATA"
export const GET_TOKEN = "GET_TOKEN"
export const GET_TOKEN_SUCCESS = "GET_TOKEN_SUCCESS"
export const GET_TOKEN_FAIL = "GET_TOKEN_FAIL"
export const GET_USER_INFO = "GET_USER_INFO"
export const GET_USER_INFO_SUCCESS = "GET_USER_INFO_SUCCESS"
export const GET_USER_INFO_FAIL = "GET_USER_INFO_FAIL"
export const GET_PROVIDERLIST = "GET_PROVIDERLIST"
export const GET_PUBLIC_PROVIDERLIST = "GET_PUBLIC_PROVIDERLIST"
export const GET_PROVIDERLIST_SUCCESS = "GET_PROVIDERLIST_SUCCESS"
export const GET_PROVIDERLIST_FAIL = "GET_PROVIDERLIST_FAIL"
export const RESET_PROVIDERLIST = "RESET_PROVIDERLIST"
export const GET_PROVIDER_DETAILS = "GET_PROVIDER_DETAILS"
export const GET_PUBLIC_PROVIDER_DETAILS = "GET_PUBLIC_PROVIDER_DETAILS"
export const GET_PROVIDER_DETAILS_SUCCESS = "GET_PROVIDER_DETAILS_SUCCESS"
export const GET_PREFERRED_DATA = "GET_PREFERRED_DATA"
export const GET_PROVIDER_DETAILS_FAIL = "GET_PROVIDER_DETAILS_FAIL"
export const GET_REFRESH_TOKEN = "GET_REFRESH_TOKEN"
export const GET_REFRESH_TOKEN_SUCCESS = "GET_REFRESH_TOKEN_SUCCESS"
export const GET_REFRESH_TOKEN_FAIL = "GET_REFRESH_TOKEN_FAIL"
export const NAV_SEARCH_MODAL_STATUS = "NAV_SEARCH_MODAL_STATUS"
export const IS_SEARCHTIP_MODAL_SHOW = "IS_SEARCHTIP_MODAL_SHOW"
export const GLOBAL_ERROR_MESSAGE = "GLOBAL_ERROR_MESSAGE"
export const GET_ZIPCODES = "GET_ZIPCODES"
export const GET_ZIPCODES_SUCCESS = "GET_ZIPCODES_SUCCESS"
export const GET_ZIPCODES_FAIL = "GET_ZIPCODES_FAIL"
export const GET_PROVIDER_ALL_DETAILS_SUCCESS =
    "GET_PROVIDER_ALL_DETAILS_SUCCESS"
export const SHOW_LOADER = "SHOW_LOADER"
export const HIDE_LOADER = "HIDE_LOADER"
export const INCREASE_LOADER_COUNT = "INCREASE_LOADER_COUNT"
export const DECREASE_LOADER_COUNT = "DECREASE_LOADER_COUNT"
export const LOGIN_TYPE = "LOGIN_TYPE"
export const IS_LOGIN_URI = "IS_LOGIN_URI"
export const SET_CATEGORY_VIEW_SHOW = "SET_CATEGORY_VIEW_SHOW"
export const SET_SELECTED_DROPDOWN_DETAILS = "SET_SELECTED_DROPDOWN_DETAILS"

export const GET_CITY_ZIPCODE = "GET_CITY_ZIPCODE"
export const GET_CITY_ZIPCODE_SUCCESS = "GET_CITY_ZIPCODE_SUCCESS"
export const GET_CITY_ZIPCODE_FAIL = "GET_CITY_ZIPCODE_FAIL"

export const GET_PUBLIC_DIAGNOSIS_DATA = "GET_PUBLIC_DIAGNOSIS_DATA"
export const GET_DIAGNOSIS_DATA = "GET_DIAGNOSIS_DATA"
export const GET_PUBLIC_CONDITIONS_PROCEDURES_DATA =
    "GET_PUBLIC_CONDITIONS_PROCEDURES_DATA"
export const GET_CONDITIONS_PROCEDURES_DATA = "GET_CONDITIONS_PROCEDURES_DATA"
export const GET_CONDITIONS_PROCEDURES_DATA_SUCCESS =
    "GET_CONDITIONS_PROCEDURES_DATA_SUCCESS"
export const GET_DIAGNOSIS_DATA_SUCCESS = "GET_DIAGNOSIS_DATA_SUCCESS"
export const GET_CONDITIONS_PROCEDURES_DATA_FAIL =
    "GET_CONDITIONS_PROCEDURES_DATA_FAIL"
export const GET_CONDITIONS_PROCEDURES_DETAILS_DATA =
    "GET_CONDITIONS_PROCEDURES_DETAILS_DATA"
export const GET_PUBLIC_CONDITIONS_PROCEDURES_DETAILS_DATA =
    "GET_PUBLIC_CONDITIONS_PROCEDURES_DETAILS_DATA"
export const GET_SPECIALTYLIST_FAIL = "GET_SPECIALTYLIST_FAIL"
export const GET_SPECIALTYLIST_SUCCESS = "GET_SPECIALTYLIST_SUCCESS"
export const SHOW_CATEGORY_PAGE = "SHOW_CATEGORY_PAGE"
export const GET_PUBLIC_DRUG_LIST_DATA = "GET_PUBLIC_DRUG_LIST_DATA"
export const GET_DRUG_LIST_DATA = "GET_DRUG_LIST_DATA"
export const GET_DRUG_DATA_SUCCESS = "GET_DRUG_DATA_SUCCESS"
export const GET_DRUG_DATA_FAIL = "GET_DRUG_DATA_FAIL"
export const SET_SELECTED_DRUG_DATA = "SET_SELECTED_DRUG_DATA"
export const GET_PUBLIC_DRUG_DETAILS_DATA = "GET_PUBLIC_DRUG_DETAILS_DATA"
export const GET_DRUG_DETAILS_DATA = "GET_DRUG_DETAILS_DATA"
export const GET_DRUG_DETAILS_DATA_SUCCESS = "GET_DRUG_DETAILS_DATA_SUCCESS"
export const GET_DRUG_DETAILS_DATA_FAIL = "GET_DRUG_DETAILS_DATA_FAIL"

export const SET_MILE_RADIUS = "SET_MILE_RADIUS"
export const GET_NETWORK_PROFILES = "GET_NETWORK_PROFILES"
export const GET_NETWORK_PROFILES_SUCCESS = "GET_NETWORK_PROFILES_SUCCESS"
export const GET_NETWORK_PROFILES_FAIL = "GET_NETWORK_PROFILES_FAIL"

export const SET_SELECTED_NETWORK_ID = "SET_SELECTED_NETWORK_ID"
export const SHOW_PAGE_LEVEL_ERROR = "SHOW_PAGE_LEVEL_ERROR"
export const SET_SELECTED_CATEGORY_PAGE_LEVEL_ERROR =
    "SET_SELECTED_CATEGORY_PAGE_LEVEL_ERROR"
