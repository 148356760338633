import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import * as Types from "../../../redux/actionTypes/index"

import { Grid } from "@mui/material"
import "./Style.css"
import AlternativeTabs from "./Tabs"

const AlternativesAndForms = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { selectedDrugDetails: drugDetails, loginType } = useSelector(
        (state) => state
    )

    const getDrugDetails = (e, data) => {
        e.preventDefault()
        const obj = {
            selectedDrug: data,
            navigate
        }
        const actionType =
            loginType === "external user login"
                ? Types.GET_PUBLIC_DRUG_DETAILS_DATA
                : Types.GET_DRUG_DETAILS_DATA
        dispatch({
            type: actionType,
            payload: obj
        })
    }

    return (
        <Grid
            container
            spacing={4}
            className={`alternatives-and-forms-container`}
        >
            <Grid item xs={12} lg={6}>
                {/* Alternative Therapeutics */}
                <AlternativeTabs
                    name="Therapeutic Alternatives"
                    data={drugDetails?.therapeuticAlts}
                    getDrugDetails={getDrugDetails}
                />
            </Grid>
            <Grid item xs={12} lg={6}>
                {/* Alternative Drug Forms */}
                <AlternativeTabs
                    name="Alternative Drug Forms & Strengths"
                    data={drugDetails?.altDrugForms}
                    getDrugDetails={getDrugDetails}
                />
            </Grid>
        </Grid>
    )
}

export default AlternativesAndForms
