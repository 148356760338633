import axiosInstance from "../middleware/axiosInstance"

const getNetworkProfilesService = async (partnersId, employerId) => {
    let url = `${process.env.REACT_APP_API_URL_v3}/coupe/v3/networks?product=${partnersId}&employerId=${employerId}`

    return axiosInstance({
        method: "get",
        url
    })
}

export default getNetworkProfilesService
