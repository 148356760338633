import React from "react"
import { useSelector } from "react-redux"
import defaultNotices from "../../../assets/notices/rx-default.json"
import employerNotices from "../../../assets/notices/rx-employer.json"
import { Card, CardContent, Grid, Typography } from "@mui/material"
import { useTheme } from "@mui/material/styles"

const HighDeductiblePlan = () => {
    const { selectedDrugDetails } = useSelector((state) => state)
    const { userInfo } = useSelector((state) => state)
    const employerId = userInfo?.employerId || ""
    const planId = userInfo?.planId || ""
    const muiTheme = useTheme()
    let displayNotices = defaultNotices
    let selectedEmployerNotices = employerNotices[employerId + "-" + planId]

    for (let notice_key in selectedEmployerNotices) {
        if (selectedEmployerNotices[notice_key] !== undefined) {
            displayNotices[notice_key] = selectedEmployerNotices[notice_key]
        }
    }

    return (
        <Card sx={{ marginY: muiTheme.spacing(10) }}>
            <CardContent>
                <Grid container spacing={2}>
                    <Grid item md={4}>
                        <Typography
                            variant="h4"
                            component="h2"
                            sx={{ marginBottom: muiTheme.spacing(4) }}
                        >
                            High Deductible Plan
                        </Typography>
                        {selectedDrugDetails?.hdhp?.estRetailCost && (
                            <>
                                <cite>Estimated Retail Cost</cite>
                                <Typography
                                    variant="h3"
                                    sx={{ marginTop: muiTheme.spacing(4) }}
                                >
                                    {selectedDrugDetails?.hdhp?.estRetailCost?.toFixed(
                                        2
                                    )}
                                </Typography>
                            </>
                        )}
                    </Grid>
                    <Grid item md={8}>
                        <div
                            dangerouslySetInnerHTML={{
                                __html: displayNotices[
                                    "rx_high_deductible_plan_disclaimer"
                                ]
                            }}
                        />
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    )
}
export default HighDeductiblePlan
