import { useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"

import SearchIcon from "@mui/icons-material/Search"
import {
    IconButton,
    InputAdornment,
    OutlinedInput,
    Button,
    Stack,
    Box,
    Link
} from "@mui/material"
import Popper from "@mui/material/Popper"
import ClickAwayListener from "@mui/material/ClickAwayListener"
import { useTheme } from "@mui/material/styles"
import ArrowBackIosNewOutlinedIcon from "@mui/icons-material/ArrowBackIosNewOutlined"

import * as Types from "../../../redux/actionTypes/index"
import CircularLoader from "../../common/circularLoader/CircularLoader"
import "./Style.css"

const DrugSearchBar = ({ parent = "tab", drugDetails = {} }) => {
    const dispatch = useDispatch()
    const textFieldRef = useRef(null)
    const drugSearchBarRef = useRef(null)
    const [drugValue, setDrugValue] = useState(null)
    const [anchorEl, setAnchorEl] = useState(null)
    const [targetElement, setTargetElement] = useState(null)
    const [isShow, setIsShow] = useState(false)
    const [isSearching, setIsSSearching] = useState(false)
    const [selectedDrug, setSelectedDrug] = useState(null)
    const navigate = useNavigate()
    const [isTyping, setIsTyping] = useState(false)
    const { drugList, loginType } = useSelector((state) => state)
    const muiTheme = useTheme()
    const popperStyle = {
        width: textFieldRef.current ? textFieldRef.current.clientWidth : "auto",
        zIndex: 8888
    }

    const MIN_REQ_CHAR_SEARCH = 2
    const SEARCH_DELAY_TIME = 500

    const handleKeyChange = (e) => {
        if (anchorEl === null) {
            setAnchorEl(targetElement)
        }

        const input = e.currentTarget.value
        if (e.target.value.match(/^\s+/)) {
            return
        }
        if (input !== drugValue) {
            setSelectedDrug(null)
            setIsTyping(true)
            drugList ? drugList.splice(0, drugList.length) : []
        }
        setDrugValue(input)
    }

    const smothScroll = (ref) => {
        ref.current.scrollIntoView({
            behavior: "smooth",
            block: "start"
        })
        ref.current.focus()
    }

    useEffect(() => {
        setIsSSearching(false)
    }, [drugList])

    useEffect(() => {
        if (isTyping) {
            let debouncer = setTimeout(() => {
                if (drugValue && drugValue.length) {
                    const obj = {
                        drugSearch: drugValue,
                        navigate
                    }

                    const actionType =
                        loginType === "external user login"
                            ? Types.GET_PUBLIC_DRUG_LIST_DATA
                            : Types.GET_DRUG_LIST_DATA
                    if (drugValue.length >= MIN_REQ_CHAR_SEARCH) {
                        drugList ? drugList.splice(0, drugList.length) : []
                        setIsTyping(false)
                        setIsSSearching(true)
                        setIsShow(true)
                        dispatch({
                            type: actionType,
                            payload: obj
                        })
                    }
                }
            }, SEARCH_DELAY_TIME)

            return () => clearTimeout(debouncer)
        }
    }, [drugValue])

    const handleDetailSearch = () => {
        const obj = {
            selectedDrug,
            navigate
        }

        const actionType =
            loginType === "external user login"
                ? Types.GET_PUBLIC_DRUG_DETAILS_DATA
                : Types.GET_DRUG_DETAILS_DATA
        dispatch({
            type: actionType,
            payload: obj
        })
    }

    const handleOpenDropDown = (e) => {
        setAnchorEl(e?.currentTarget)
        setTargetElement(e?.currentTarget)
        setIsTyping(true)
        smothScroll(textFieldRef)
        e.stopPropagation()
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    const selectRow = (e, data) => {
        e.preventDefault()
        // setActive(0)
        setIsShow(false)
        setIsTyping(false)
        setIsSSearching(false)
        // document.body.classList.remove("auto-complete-expand")
        setDrugValue(data.name)
        setSelectedDrug(data)
        setAnchorEl(null)
        // trigger search on suggestion select, only if component is on page not tab
        if (parent === "page") {
            const obj = {
                selectedDrug: data,
                navigate
            }
            const actionType =
                loginType === "external user login"
                    ? Types.GET_PUBLIC_DRUG_DETAILS_DATA
                    : Types.GET_DRUG_DETAILS_DATA
            dispatch({
                type: actionType,
                payload: obj
            })
        }
    }

    const renderAutocomplete = () => {
        if (isShow && !isTyping) {
            if (!drugValue || drugValue.length < MIN_REQ_CHAR_SEARCH) {
                return (
                    <div className="autocomplete dropdown-menu-ds">
                        <div className="no-autocomplete">
                            {`Type at least ${MIN_REQ_CHAR_SEARCH} characters...`}
                        </div>
                    </div>
                )
            } else if (isSearching) {
                return (
                    <div className="autocomplete dropdown-menu-ds">
                        <div className="no-autocomplete">Searching...</div>
                    </div>
                )
            } else if (drugList && !drugList.length) {
                return (
                    <div className="autocomplete dropdown-menu-ds">
                        <div className="no-autocomplete">
                            No drug results for the search specified. Please
                            update your search.
                        </div>
                    </div>
                )
            } else if (drugList && drugList.length) {
                return (
                    <div className="autocomplete dropdown-menu-ds basicDrugSrc">
                        <div>
                            {drugList?.length > 0 ? (
                                <div>
                                    {drugList?.map((item, i) => {
                                        return (
                                            <div
                                                onClick={(e) =>
                                                    selectRow(e, item)
                                                }
                                                key={i}
                                                className="typeAheadGroupingMessage-ds typeAheadResultHeaderFont"
                                            >
                                                <Link
                                                    tabIndex="-1"
                                                    name={item?.ndc}
                                                    sx={{
                                                        display: {
                                                            xs: "block",
                                                            lg: "flex"
                                                        }
                                                    }}
                                                >
                                                    <span className="drug-label">
                                                        {item?.name}{" "}
                                                    </span>
                                                    <Box className="tiering-wrapper">
                                                        {item?.maintenance && (
                                                            <button className="tier-badge maintainance">
                                                                Maintenance
                                                            </button>
                                                        )}
                                                        <button
                                                            className={`tier-badge  
                                                                ${item?.tierName}
                                                            `}
                                                        >
                                                            {item?.tierName}
                                                        </button>
                                                    </Box>
                                                </Link>
                                            </div>
                                        )
                                    })}
                                </div>
                            ) : (
                                <></>
                            )}
                        </div>
                    </div>
                )
            }
        }
        return <></>
    }

    useEffect(() => {
        if (parent === "page") {
            smothScroll(drugSearchBarRef)
        }
    }, [drugDetails])
    return (
        <>
            <Stack
                ref={drugSearchBarRef}
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                className="drug-search-bar"
                spacing={2}
                sx={{
                    paddingTop: parent === "page" ? muiTheme.spacing(14) : 0,
                    paddingBottom: parent === "page" ? muiTheme.spacing(14) : 0
                }}
            >
                {parent === "page" && (
                    <Box onClick={() => navigate("/#DrugSearch")}>
                        <IconButton
                            variant="contained"
                            sx={{
                                background: muiTheme.palette.primary.main,
                                color: muiTheme.palette.primary.text,
                                padding: muiTheme.spacing(7),
                                boxShadow: "0px 4px 10px #00000038",
                                marginRight: "8px",
                                ":hover": {
                                    background: muiTheme.palette.primary.main,
                                    color: muiTheme.palette.primary.text,
                                    opacity: 0.8
                                }
                            }}
                        >
                            <ArrowBackIosNewOutlinedIcon />
                        </IconButton>
                    </Box>
                )}
                <OutlinedInput
                    sx={{
                        background: "#ffffff",
                        boxShadow: `0px 0px ${
                            parent === "page" ? "10px" : "0px"
                        } #00000038`,
                        "& .MuiOutlinedInput-notchedOutline": {
                            borderWidth: parent === "page" ? 0 : 1
                        }
                    }}
                    id="drug-search-flds"
                    placeholder="Name of drug"
                    type="text"
                    value={drugValue}
                    onChange={handleKeyChange}
                    onClick={handleOpenDropDown}
                    onFocus={() => {
                        setIsShow(true)
                    }}
                    autoComplete="off"
                    disabled={isSearching}
                    className="w-100 location-search-input"
                    inputRef={textFieldRef}
                    endAdornment={
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                edge="end"
                            >
                                <SearchIcon />
                            </IconButton>
                        </InputAdornment>
                    }
                />
                <Popper
                    open={Boolean(anchorEl)}
                    anchorEl={anchorEl}
                    style={popperStyle}
                    placement="bottom-start"
                    className="popperClass"
                >
                    <ClickAwayListener onClickAway={handleClose}>
                        {renderAutocomplete()}
                    </ClickAwayListener>
                </Popper>
            </Stack>
            {parent === "tab" && (
                <div className="criteria-button mt-4">
                    <Button
                        variant="outlined"
                        color="primary"
                        disabled={!drugValue}
                        aria-label="search"
                        onClick={handleDetailSearch}
                        startIcon={<SearchIcon />}
                    >
                        Search
                    </Button>
                </div>
            )}
            {isSearching && drugList?.length > 0 ? (
                <div className="loanding-loader">
                    <CircularLoader />
                </div>
            ) : null}
        </>
    )
}

DrugSearchBar.propTypes = {
    parent: "",
    drugDetails: {}
}
export default DrugSearchBar
