import { all, fork } from "redux-saga/effects"
import { getTokenSaga } from "./getTokenSaga"
import { getUserInfoSaga } from "./getUserInfoSaga"
import { getProviderListSaga } from "./getProviderListSaga"
import { getProviderDetailsSaga } from "./getProviderDetailsSaga"
import { getRefreshTokenSaga } from "./getRefreshTokenSaga"
import { getZipcodesSaga } from "./getZipcodesSaga"
import { getPublicProviderDetailsSaga } from "./getPublicProviderDetailsSaga"
import { getPublicProviderListSaga } from "./getPublicProviderListSaga"
import { getCityZipcodeSaga } from "./getCityZipcodeSaga"
import { getBundledSPDSaga } from "./getBundledSPDSaga"
import { getPublicBundledSPDSaga } from "./getPublicBundledSPDSaga"
import { getBundledSPDDetailsSaga } from "./getBundledSPDDetailsSaga"
import { getPublicBundledSPDDetailsSaga } from "./getPublicBundledSPDDetailsSaga"
import { getPublicDiagnosisSaga } from "./getPublicDiagnosisSaga"
import { getDiagnosisSaga } from "./getDiagnosisSaga"
import { getPublicDrugListSaga } from "./getPublicDrugListSaga"
import { getDrugListSaga } from "./getDrugListSaga"
import { getDrugDetailsSaga } from "./getDrugDetailsSaga"
import { getPublicDrugDetailsSaga } from "./getPublicDrugDetailsSaga"
import { getNetworkProfilesSaga } from "./getNetworkProfilesSaga"

function* sagas() {
    yield all([
        fork(getTokenSaga),
        fork(getUserInfoSaga),
        fork(getProviderListSaga),
        fork(getProviderDetailsSaga),
        fork(getRefreshTokenSaga),
        fork(getZipcodesSaga),
        fork(getPublicProviderDetailsSaga),
        fork(getPublicProviderListSaga),
        fork(getCityZipcodeSaga),
        fork(getBundledSPDSaga),
        fork(getPublicBundledSPDSaga),
        fork(getBundledSPDDetailsSaga),
        fork(getPublicBundledSPDDetailsSaga),
        fork(getPublicDiagnosisSaga),
        fork(getDiagnosisSaga),
        fork(getPublicDrugListSaga),
        fork(getDrugListSaga),
        fork(getPublicDrugDetailsSaga),
        fork(getDrugDetailsSaga),
        fork(getNetworkProfilesSaga)
    ])
}
export default sagas
