import { useRef, useEffect, useState } from "react"
import { PropTypes } from "prop-types"
import { IconButton, InputAdornment, OutlinedInput } from "@mui/material"
import MyLocationIcon from "@mui/icons-material/MyLocation"

const Search = ({ updateAddress, updateVal }) => {
    let autocomplete
    const autocompleteRef = useRef()

    const [queryCoordinates, setQueryCoordinates] = useState([0, 0])
    const [queryZipCode, setQueryZipCode] = useState("")
    const [addressError, setAddressError] = useState(false)

    const updateChildQueryZipCode = (childData) => {
        setQueryZipCode(childData)
    }

    const updateChildSelectedAddress = (childData) => {
        updateAddress(childData)
    }

    const updateChildQueryCoordinates = (childData) => {
        setQueryCoordinates(childData)
    }

    const getDataBasedOnType = (list, type, name) => {
        const matched = list?.filter((ele) => ele?.types?.includes(type))
        return matched.length > 0 ? matched[0][name] : ""
    }

    const handleSelect = () => {
        try {
            const place = autocomplete.getPlace()
            if (place) {
                const lat = place.geometry.location.lat()
                const lng = place.geometry.location.lng()
                const zipCode = getDataBasedOnType(
                    place.address_components,
                    "postal_code",
                    "long_name"
                )
                const city = getDataBasedOnType(
                    place.address_components,
                    "locality",
                    "long_name"
                )
                const state = getDataBasedOnType(
                    place.address_components,
                    "administrative_area_level_1",
                    "short_name"
                )
                updateChildQueryCoordinates([lat, lng])
                setAddressError(false)
                if (autocompleteRef.current) {
                    updateChildSelectedAddress({
                        value: autocompleteRef.current.value,
                        zipCode,
                        city,
                        state,
                        lat,
                        lng
                    })
                }
            }
        } catch (err) {
            setAddressError(true)
        }
    }

    const selectFirstOption = (inputAddress) => {
        const _addEventListener = inputAddress.addEventListener
        const addEventListenerWrapper = (type, listener) => {
            if (type === "keydown") {
                const _listener = listener
                listener = (event) => {
                    const suggestionSelected =
                        document.getElementsByClassName(
                            "pac-item-selected"
                        ).length
                    if (
                        event.which >= 9 &&
                        event.which <= 13 &&
                        !suggestionSelected
                    ) {
                        const e = new KeyboardEvent("keydown", {
                            key: "ArrowDown",
                            code: "ArrowDown",
                            keyCode: 40
                        })
                        _listener.apply(inputAddress, [e])

                        const place = autocomplete.getPlace()
                        if (place) {
                            const lat = place.geometry.location.lat()
                            const lng = place.geometry.location.lng()
                            const zipCode =
                                place.address_components[0].long_name
                            const city = place.address_components[1].long_name
                            const state = place.address_components[3].short_name
                            if (autocompleteRef.current) {
                                updateChildSelectedAddress({
                                    value: autocompleteRef.current.value,
                                    zipCode,
                                    city,
                                    state,
                                    lat,
                                    lng
                                })
                            }
                            setAddressError(false)
                        }
                    } else {
                        setAddressError(true)
                    }
                    _listener.apply(inputAddress, [event])
                }
            }
            _addEventListener.apply(inputAddress, [type, listener])
        }
        inputAddress.addEventListener = addEventListenerWrapper
    }

    useEffect(() => {
        const initAutocomplete = () => {
            try {
                // disabling due to not wanting to persist any assignments to autocomplete across renders
                // eslint-disable-next-line
                autocomplete = new window.google.maps.places.Autocomplete(
                    autocompleteRef.current,
                    {
                        componentRestrictions: {
                            country: ["us"]
                        },
                        fields: [
                            "formatted_address",
                            "geometry",
                            "address_components"
                        ],
                        types: ["postal_code", "locality"]
                    }
                )
                autocomplete.addListener("place_changed", handleSelect)
                selectFirstOption(autocompleteRef.current)
            } catch (err) {
                console.log(err)
            }
        }
        try {
            initAutocomplete()
        } catch (err) {
            console.log(err)
        }
        return () => {
            setAddressError(false)
        }
    }, [])

    useEffect(() => {
        updateChildQueryZipCode(queryZipCode)
        // disabling due to causes infinite loop and not counting on this dependency to be tracked in useeffect hook
        // eslint-disable-next-line
    }, [queryCoordinates])

    return (
        <div className="text-left search-outline-container">
            <label
                className="font-weight-bold provider-lable-flds"
                htmlFor="locationFlds"
            >
                Find a provider in your network:
            </label>
            <OutlinedInput
                id="locationFlds"
                placeholder="State / City / Zip Code"
                type="text"
                data-type={addressError}
                onChange={updateVal}
                inputRef={autocompleteRef}
                className="w-100 location-search-input"
                endAdornment={
                    <InputAdornment position="end">
                        <IconButton
                            aria-label="toggle password visibility"
                            edge="end"
                        >
                            <MyLocationIcon />
                        </IconButton>
                    </InputAdornment>
                }
            />
        </div>
    )
}
Search.propTypes = {
    updateVal: PropTypes.func.isRequired,
    updateAddress: PropTypes.func.isRequired
}

export default Search
