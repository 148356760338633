import { put, select, takeLatest } from "redux-saga/effects"

import getPublicDrugListService from "../service/getPublicDrugList.service"
import * as Types from "../actionTypes/index"

const state = (store) => store
export function* getPublicDrugList(action) {
    const { payload } = action
    yield put({ type: Types.INCREASE_LOADER_COUNT })
    try {
        const storeData = yield select(state)
        const { userInfo } = storeData || {}
        const sponsorId = userInfo?.["custom:sponsorid"]
        const results = yield getPublicDrugListService({
            payload,
            detailType: "basic",
            sponsorId: sponsorId,
            employerId: userInfo?.employerId || "",
            planId: userInfo?.planId || ""
        })

        const { data, apiStatus } = results
        let drugsList = []
        if (apiStatus === "success") {
            const drugs = data?.drugs ? data.drugs : []
            drugsList = [...drugs]
            yield put({
                type: Types.GET_DRUG_DATA_SUCCESS,
                data: [...drugsList]
            })
            if (!drugsList?.length) {
                yield put({
                    type: Types.GLOBAL_ERROR_MESSAGE,
                    payload:
                        "There are no drugs found your search. Please try searching a new query."
                })
            }
        } else {
            yield put({ type: Types.GET_DRUG_DATA_FAIL })
            yield put({
                type: Types.GLOBAL_ERROR_MESSAGE,
                payload:
                    "We're sorry to say we're experiencing a service outage. Our team is working quickly to fix the issue."
            })
            payload.navigate("/error")
        }
        yield put({ type: Types.DECREASE_LOADER_COUNT })
    } catch (error) {
        yield put({ type: Types.GET_DRUG_DATA_FAIL })
        yield put({
            type: Types.GLOBAL_ERROR_MESSAGE,
            payload:
                "We're sorry to say we're experiencing a service outage. Our team is working quickly to fix the issue."
        })
        payload.navigate("/error")
        yield put({ type: Types.DECREASE_LOADER_COUNT })
    }
}

export function* getPublicDrugListSaga() {
    yield takeLatest(Types.GET_PUBLIC_DRUG_LIST_DATA, getPublicDrugList)
}
