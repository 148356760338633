import { createStore, applyMiddleware, compose } from "redux"
import createSagaMiddleware from "redux-saga"
import { appReducer } from "./reducers/appReducer"
import sagas from "./sagas/index"

const configureStore = (initialState = {}) => {
    const sagaMiddleware = createSagaMiddleware()
    const composeEnhancers =
        typeof window === "object" &&
        window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
            ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
            : compose

    const enchancer = composeEnhancers(applyMiddleware(sagaMiddleware))
    const store = createStore(appReducer, initialState, enchancer)
    sagaMiddleware.run(sagas)

    return store
}
export default configureStore
