import { createTheme } from "@mui/material/styles"

const commonConfig = {
    typography: {
        fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto"',
        h1: {
            fontSize: "2.5rem",
            fontWeight: 600,
            lineHeight: 1.2
        },
        h2: {
            fontSize: "2rem",
            fontWeight: 600,
            lineHeight: 1.3
        },
        h3: {
            fontSize: "1.8rem",
            fontWeight: 700,
            lineHeight: 1.4
        },
        h4: {
            fontSize: "1.5rem",
            fontWeight: 700,
            lineHeight: 1.5
        },
        h5: {
            fontSize: "1.25rem",
            fontWeight: 600,
            lineHeight: 1.6
        }
    },
    spacing: [
        2, 4, 6, 8, 10, 12, 14, 16, 18, 20, 24, 28, 32, 36, 40, 44, 48, 56, 64
    ],
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    borderRadius: 8
                }
            }
        },
        MuiCard: {
            styleOverrides: {
                root: {
                    borderRadius: 10,
                    boxShadow: "0px 0px 10px #00000038"
                }
            }
        },
        MuiLink: {
            styleOverrides: {
                root: {
                    "&:hover": {
                        opacity: 0.8
                    }
                }
            }
        }
    }
}

export const coupeTheme = createTheme({
    palette: {
        primary: {
            main: "#32f371",
            text: "#000000"
        },
        grey: {
            bg: "#F5F5F5",
            text: "#888888"
        },
        warning: {
            main: "#f6de30"
        }
    },
    ...commonConfig
})

export const simplePayTheme = createTheme({
    palette: {
        primary: {
            main: "#7936a1",
            text: "#FFFFFF"
        },
        grey: {
            bg: "#F5F5F5",
            text: "#888888"
        },
        warning: {
            main: "#f6de30"
        }
    },
    ...commonConfig
})

export const defaultTheme = createTheme({
    ...commonConfig
})
