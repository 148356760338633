export const GlobalConstants = {
    baseUrl: "http://localhost:3000/",
    headers: {}
}
export const USER_INFO = "/oauth2/userInfo"
export const GET_TOKEN = "/oauth2/userInfo"
export const BLACK_THEME = "#000000"
export const PURPLE_THEME = "#8331a7"
export const PS_HOME_URL = "https://providersearch.coupehealth.com/"
export const MEMBER_VIRGINPULSE_URL = "https://iam.stage2.virginpulse.com/"
export const MEMBER_VIRGINPULSE_PROD_URL = "https://app.member.virginpulse.com/"

export const BENEFIT_MANAGER = {
    MEDONE_COUPE: "Medone-Coupe",
    MEDONE_SIMPLE_PAY: "Medone-SimplePay",
    CVS_SIMPLE_PAY: "CVS-SimplePay"
}
// Preventative options
export const PREV_OPTIONS = {
    PREV_A: "Preventative A",
    PREV_B: "Preventative B",
    PREV_C: "Preventative C"
}
// Formulary types
export const FORMULARY_TYPE = {
    MEDONE_ACCESS: "Access (Medone)",
    MEDONE_PERFORMANCE: "Performance (MedOne)",
    CVS_STANDARD: "Standard (CVS)"
}
export const PUBLIC_PROVIDERS_COUNT = 50000
