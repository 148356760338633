import React from "react"
import { PropTypes } from "prop-types"

import Slider from "@mui/material/Slider"

import "./styles.css"

const marks = [
    {
        value: 0,
        label: "0 Miles"
    },
    {
        value: 50,
        label: "50 Miles"
    }
]

const RangeSlider = ({ value, handleRangeChanges }) => {
    return (
        <>
            <Slider
                value={value}
                aria-label="Default"
                valueLabelDisplay="on"
                marks={marks}
                step={5}
                max={50}
                id="rangeSlider"
                onChange={(e, val) => {
                    handleRangeChanges(val)
                }}
            />
        </>
    )
}

RangeSlider.propTypes = {
    handleRangeChanges: PropTypes.func.isRequired,
    value: PropTypes.any
}
export default RangeSlider
