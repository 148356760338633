import { put, takeLatest } from "redux-saga/effects"

import getTokenService from "../service/getToken.service"
import * as Types from "../actionTypes/index"

export function* getToken(action) {
    const { payload } = action
    try {
        const results = yield getTokenService(payload)
        const { data, apiStatus } = results
        if (apiStatus === "success") {
            yield put({ type: Types.LOGIN_TYPE, payload: "vp login" })
            yield put({ type: "IS_LOGIN_URI", payload: false })
            yield put({ type: Types.GET_TOKEN_SUCCESS, tokenInfo: data })
            yield put({ type: Types.GET_USER_INFO })
            sessionStorage.setItem("tokenData", JSON.stringify(data))
        } else {
            const tokenData = sessionStorage.getItem("tokenData")
            if (tokenData) {
                yield put({ type: Types.LOGIN_TYPE, payload: "vp login" })
                const tokenInfo = JSON.parse(tokenData)
                yield put({ type: Types.GET_REFRESH_TOKEN, tokenInfo })
            } else {
                const client_id = process.env.REACT_APP_CLIENT_ID
                const url = `${process.env.REACT_APP_API_BASE_URL}/oauth2/authorize?identity_provider=Virgin-Pulse&redirect_uri=${process.env.REACT_APP_SPA_DOMAIN_URL}&response_type=CODE&client_id=${client_id}`
                window.location.href = url
            }
        }
    } catch (error) {
        yield put({ type: Types.GET_TOKEN_FAIL })
    }
}

export function* getTokenSaga() {
    yield takeLatest(Types.GET_TOKEN, getToken)
}
