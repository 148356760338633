import axiosInstance from "../middleware/axiosInstance"

const getRefreshTokenService = async ({ refresh_token }) => {
    const client_id = process.env.REACT_APP_CLIENT_ID
    const client_secret = process.env.REACT_APP_CLIENT_SECRET

    const auth = {
        username: client_id,
        password: client_secret
    }

    const headers = {
        "Content-Type": "application/x-www-form-urlencoded"
    }
    return axiosInstance({
        method: "post",
        url: `${process.env.REACT_APP_API_BASE_URL}/oauth2/token?grant_type=refresh_token&refresh_token=${refresh_token}&client_id=${client_id}&redirect_uri=${process.env.REACT_APP_SPA_DOMAIN_URL}`,
        headers: headers,
        data: null,
        auth: auth
    })
}

export default getRefreshTokenService
