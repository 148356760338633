import axiosInstance from "../middleware/axiosInstance"
import { load } from "protobufjs"
// import protobuf from "./protos/basicPublicProvider.proto"
import protobufBcbs from "./protos/basicPublicProviderBCBS.proto"
import protobufAetna from "./protos/basicPublicProviderAetna.proto"
import { Buffer } from "buffer"
import { employerPlansData } from "../../utils/employerPlansData"

const getPublicProvidersResponse = async (encodeResponse, sponsorId) => {
    try {
        const protoBuff = sponsorId === "7140" ? protobufBcbs : protobufAetna
        return await load(protoBuff).then(function (root) {
            const BasicProviderResponse = root.lookupType(
                "providerSearch.BasicPublicProviderResponse"
            )
            const decodedResponse = BasicProviderResponse.decode(
                Buffer.from(encodeResponse.data, "base64")
            )
            // console.log(decodedResponse, "decodedResponse")
            return {
                data: decodedResponse,
                apiStatus: "success",
                cached: encodeResponse.cached
            }
        })
    } catch (e) {
        console.log(e)
    }
}

export const getPublicProviderListService = async ({
    payload,
    detailType,
    sponsorId,
    providerId,
    planId,
    employerId,
    nearByZipcodes,
    networkId
}) => {
    const {
        city,
        state,
        zipCode,
        providerType,
        offset = 0,
        getAllResults,
        radius
    } = payload
    let url = ""

    const zCode = nearByZipcodes || zipCode
    let queryUrl = ""
    if (sponsorId === "7140") {
        queryUrl = `&networkId=${networkId}`
    }

    let productId = new URLSearchParams(window.location.search).get("product")
    if (!productId) {
        const obj = sessionStorage.getItem("userInfo")
        const userInfo = JSON.parse(obj)
        if (userInfo) {
            productId = userInfo.partnersId
        }
    }
    console.log("Product ID:", productId)

    const selectedpublicProviderEndpoint =
        (productId &&
            employerPlansData[productId].endpointsList.publicproviders) ||
        "/coupe/v3/publicproviders"

    if (getAllResults) {
        url = `${process.env.REACT_APP_API_URL_v3}${selectedpublicProviderEndpoint}?sponsorId=${sponsorId}&planId=${planId}&employerId=${employerId}&detail=${detailType}&zipcode=${zCode}&city=${city}&state=${state}&limit=30&offset=${offset}&providerType=${providerType}&providersList=${providerId}${queryUrl}`
    } else if (detailType === "basic") {
        url = `${process.env.REACT_APP_API_URL_v3}${selectedpublicProviderEndpoint}?sponsorId=${sponsorId}&planId=${planId}&employerId=${employerId}&detail=${detailType}&limit=50000&offset=${offset}&zipcode=${zCode}&city=${city}&state=${state}&radius=${radius}${queryUrl}`

        let basicSearchRes = await axiosInstance({
            method: "get",
            url
        })
        // console.log("Basic Search Response:", basicSearchRes)
        return await getPublicProvidersResponse(basicSearchRes, sponsorId)
    } else {
        url = `${process.env.REACT_APP_API_URL_v3}${selectedpublicProviderEndpoint}?sponsorId=${sponsorId}&planId=${planId}&employerId=${employerId}&detail=${detailType}&zipcode=${zCode}&city=${city}&state=${state}&limit=10&offset=${offset}&providerType=${providerType}&providersList=${providerId}${queryUrl}`
    }
    return axiosInstance({
        method: "get",
        url
    })
}

export const fetchPublicProvidersInParallel = async ({
    payload,
    detailType,
    sponsorId,
    planId,
    employerId,
    nearByZipcodes,
    networkId
}) => {
    try {
        const zCode = nearByZipcodes || payload?.zipCode
        payload.zCode = zCode
        payload.networkId = networkId

        // const providerRequests = getProviderRequests(
        //     payload,
        //     sponsorId,
        //     planId,
        //     detailType,
        //     employerId
        // )
        // const batchSize = 5
        // let providerConcurrentresults = []

        // for (let i = 0; i < providerRequests.length; i += batchSize) {
        //     console.log(i, "iiii")
        //     const batch = providerRequests.slice(i, i + batchSize)
        //     const batchResults = await Promise.all(batch)
        //     providerConcurrentresults =
        //         providerConcurrentresults.concat(batchResults)
        // }
        let providerConcurrentresults = await Promise.all(
            await getProviderRequests(
                payload,
                sponsorId,
                planId,
                detailType,
                employerId
            )
        )
        let providers = []
        let specialties = []
        let searchparams = []
        let insurancePlanType = []
        let apiStatus = ""
        for (let i = 0; i < providerConcurrentresults.length; i++) {
            let res = providerConcurrentresults[i]
            if (res?.data?.status === 500) {
                apiStatus = "fail"
            }
            if (
                res?.data?.statusCode === undefined &&
                res?.data?.status === undefined
            ) {
                providers = providers.concat(res?.data?.providers)
                specialties = specialties.concat(res?.data?.specialties)
                searchparams.push(res?.data?.searchparams)
                insurancePlanType.push(res?.data?.insurance_plantype)
            }
        }
        return {
            apiStatus: apiStatus === "fail" ? "fail" : "success",
            data: {
                providers: providers,
                specialties: specialties,
                searchparams: searchparams,
                insurance_plantype: [...new Set(insurancePlanType)],
                count: payload?.count
            }
        }
    } catch (e) {
        console.log(e)
    }
}
const getProviderRequests = async (
    payload,
    sponsorId,
    planId,
    detailType,
    employerId
) => {
    try {
        let providerRequests = []
        let { count, city, state, zCode, radius, networkId } = payload
        // const totalVal = count

        // const productId = new URLSearchParams(window.location.search).get("product")
        let productId = new URLSearchParams(window.location.search).get(
            "product"
        )
        if (!productId) {
            const obj = sessionStorage.getItem("userInfo")
            const userInfo = JSON.parse(obj)
            if (userInfo) {
                productId = userInfo.partnersId
            }
        }
        console.log("Product ID:", productId)

        const selectedpublicProviderEndpoint =
            (productId &&
                employerPlansData[productId].endpointsList.publicproviders) ||
            "/coupe/v3/publicproviders"
        // let { count, city, state, zCode } = payload
        let offset = 0
        const numberOfCalls = Math.ceil(count / 50000)
        while (offset < numberOfCalls) {
            // console.log(count, "count")
            Array.from(
                { length: Math.min(5, numberOfCalls - offset) },
                (_, index) => {
                    const offsetVal = (offset + index) * 50000
                    if (offsetVal !== 0) {
                        console.log(offset, "call offset")
                        let url = `${process.env.REACT_APP_API_URL_v3}${selectedpublicProviderEndpoint}?sponsorId=${sponsorId}&planId=${planId}&employerId=${employerId}&detail=${detailType}&limit=50000&offset=${offsetVal}&zipcode=${zCode}&city=${city}&state=${state}&radius=${radius}&networkId=${networkId}`
                        const res = axiosInstance({
                            method: "get",
                            url: url
                        }).then(async (response) => {
                            console.log(response, "responseresponse")
                            if (
                                response.status === 200 ||
                                response.status === 304
                            ) {
                                return await getPublicProvidersResponse(
                                    response,
                                    sponsorId
                                )
                            } else {
                                offset = numberOfCalls + 1
                                return {
                                    data: {
                                        status: 500
                                    }
                                }
                            }
                        })
                        providerRequests.push(res)
                        // res.then((data) => {
                        //     if (data) {
                        //         providerRequests.push(res)
                        //     }
                        // })
                    }
                }
            )
            // let url = `${process.env.REACT_APP_API_URL}/v2/coupe/publicproviders?sponsorId=${sponsorId}&planId=${planId}&employerId=${employerId}&detail=${detailType}&limit=50000&offset=${offset}&zipcode=${zCode}&city=${city}&state=${state}`

            offset += 5
            await new Promise((resolve) => setTimeout(resolve, 2000))
            // count -= 50000
        }
        return providerRequests
    } catch (e) {
        console.log(e)
    }
}
