import { put, select, takeLatest } from "redux-saga/effects"

import getZipcodesService from "../service/getZipcodes.service"
import * as Types from "../actionTypes/index"

const state = (store) => store
export function* getZipcodes(action) {
    const { payload } = action
    try {
        const storeData = yield select(state)
        const response = yield getZipcodesService(payload)
        const { data, apiStatus } = response
        if (apiStatus === "success") {
            if (data?.DataList?.length) {
                const zipcodesList = []
                data?.DataList?.forEach((element) => {
                    zipcodesList.push(element.Code)
                })
                const nearByZipcodes = zipcodesList.join()
                yield put({
                    type: Types.GET_ZIPCODES_SUCCESS,
                    zipcodes: {
                        nearByZipcodes,
                        zipcodesList
                    }
                })
            } else {
                yield put({
                    type: Types.GET_ZIPCODES_SUCCESS,
                    zipcodes: {
                        zipcodesList: [],
                        nearByZipcodes: ""
                    }
                })
            }
        } else {
            yield put({ type: Types.GET_ZIPCODES_FAIL })
        }
        const { loginType } = storeData || {}
        yield put({
            type:
                loginType === "external user login"
                    ? Types.GET_PUBLIC_PROVIDERLIST
                    : Types.GET_PROVIDERLIST,
            payload: { ...payload.providerListPayload, radius: payload.radius }
        })
        yield put({
            type:
                loginType === "external user login"
                    ? Types.GET_PUBLIC_CONDITIONS_PROCEDURES_DATA
                    : Types.GET_CONDITIONS_PROCEDURES_DATA,
            payload: { ...payload.providerListPayload }
        })
        yield put({
            type:
                loginType === "external user login"
                    ? Types.GET_PUBLIC_DIAGNOSIS_DATA
                    : Types.GET_DIAGNOSIS_DATA,
            payload: { ...payload.providerListPayload }
        })
    } catch (error) {
        yield put({ type: Types.GET_ZIPCODES_FAIL })
    }
}

export function* getZipcodesSaga() {
    yield takeLatest(Types.GET_ZIPCODES, getZipcodes)
}
