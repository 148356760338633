import axiosInstance from "../middleware/axiosInstance"
import { USER_INFO } from "../../utils/constants"

const getUserInfoService = ({ token }) => {
    const headers = {
        Authorization: `Bearer ${token}`
    }
    return axiosInstance({
        method: "get",
        url: `${process.env.REACT_APP_API_BASE_URL}${USER_INFO}`,
        headers: headers
    })
}
export default getUserInfoService
