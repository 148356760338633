import React, { useEffect } from "react"
import { Container, Divider, useTheme } from "@mui/material"
import NetworkPhamacies from "./NetworkParmacies"
import HighDeductiblePlan from "./HighDeductiblePlan"
import AlternativesAndForms from "./Alternatives"
import Overview from "./Overview"
import ExternalLinks from "./ExternalLinks"
import SearchBar from "./SearchBar"
import { useNavigate } from "react-router-dom"
import { useSelector } from "react-redux"

const DrugResultPage = () => {
    const { selectedDrugDetails: drugDetails } = useSelector((state) => state)
    const navigate = useNavigate()
    const muiTheme = useTheme()
    useEffect(() => {
        if (!drugDetails) navigate("/#DrugSearch")
    }, [])

    return (
        <Container
            sx={{
                px: {
                    xs: muiTheme.spacing(4),
                    sm: muiTheme.spacing(6),
                    md: muiTheme.spacing(8)
                }
            }}
        >
            {/* Search Bar */}
            <SearchBar parent="page" />

            {/* Drug Overview */}
            <Overview />

            {drugDetails?.hdhp !== null ? (
                /* High Deductible Plan */
                <HighDeductiblePlan />
            ) : null}

            {/* Drug Network Pharmacies */}
            <NetworkPhamacies />

            {/* Separator */}
            <Divider sx={{ marginY: muiTheme.spacing(10) }} />

            {/* Pharmacies Search */}
            <ExternalLinks />

            {/* Separator */}
            <Divider sx={{ marginY: muiTheme.spacing(10) }} />

            {/* Alternatives and forms */}
            <AlternativesAndForms />
        </Container>
    )
}

export default DrugResultPage
