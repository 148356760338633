import React, { useState, useContext, useEffect } from "react"
import { PropTypes } from "prop-types"

import { Box, Button } from "@mui/material"
import Modal from "@mui/material/Modal"
import CardActions from "@mui/material/CardActions"
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined"
import { checkIsCSurlDisplay, getTierBgColor } from "../../../utils/common"
import { ThemeContext } from "../../../Theme"
import { ReactComponent as TierIcon } from "../../../assets/images/tier_starIcon.svg"

import "./searchTip.css"

const SearchTip = ({ open, handleClose, menuType, insurancePlanType }) => {
    const { theme } = useContext(ThemeContext)
    const params = new URLSearchParams(location.search)
    const [hideSupportUrl, setHideSupportUrl] = useState(false)
    const style = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: menuType === "search" ? 300 : 600,
        bgcolor: "background.paper",
        color: "#000000de",
        boxShadow: 24,
        p: 10
    }
    useEffect(() => {
        const { productLinkStatus, empLinkStatus, csLink, csText } =
            checkIsCSurlDisplay(params)
        setHideSupportUrl({
            status: productLinkStatus || empLinkStatus,
            csLink,
            csText
        })
    }, [])
    return (
        <>
            <Modal
                container={document.getElementsByClassName("App")[0]}
                open={open}
                onClose={handleClose}
                aria-labelledby="search-modal-title"
                aria-describedby="search-modal-description"
            >
                <Box
                    sx={style}
                    className={`${
                        menuType === "search"
                            ? "search-bar-modal"
                            : "icon-bar-modal"
                    }`}
                >
                    <b>
                        {menuType === "search" ? "Search Tip:" : "Icon Legend"}
                    </b>
                    <br />
                    {menuType === "search" ? (
                        <>
                            {" "}
                            {insurancePlanType === "HDHP" ? (
                                <b>
                                    I&lsquo;ve got results for your search but
                                    please note you are on a High Deductible
                                    Health Plan! The prices you see on the
                                    screen are what you would pay after you meet
                                    your deductible by paying the full cost of
                                    your care. If you would like to get an
                                    estimate of the cost of your care prior to
                                    meeting your deductible, your Health Valet
                                    is happy to help with those estimates and
                                    finding other care options.
                                </b>
                            ) : (
                                <b>
                                    I&lsquo;ve got recommendations for you! Your
                                    Health Valet is happy to help if these
                                    don&lsquo;t meet your needs or you would
                                    like some other options.
                                </b>
                            )}
                            <br />
                        </>
                    ) : (
                        <CardActions className="card-action">
                            <div className="card-list-footer-action legend-search">
                                <div className="card-footer-secondary">
                                    <div>
                                        <div className="StarIcon-icon">
                                            <TierIcon
                                                fill={getTierBgColor(1)}
                                            />
                                            <div className="final-tier">1</div>
                                        </div>
                                    </div>
                                    <div>
                                        <b>Tier 1:</b>
                                        <span className="pl-2 ">
                                            Providers have met or Exceeded the
                                            standards for a broad collection of
                                            normal quality and cost efficiency
                                            healthcare metrics. These providers
                                            provide members consistent, high
                                            quality care at each treatment.
                                        </span>
                                    </div>
                                </div>
                                <div className="card-footer-secondary">
                                    <div>
                                        <div className="StarIcon-icon">
                                            <TierIcon
                                                fill={getTierBgColor(2)}
                                            />
                                            <div className="final-tier">2</div>
                                        </div>
                                    </div>
                                    <div>
                                        <b>Tier 2:</b>
                                        <span className="pl-2 ">
                                            Providers generally meet or exceed
                                            all of the normal quality metrics
                                            but oftentimes cost more than their
                                            peers due to high rates or excess
                                            treatments. While these providers
                                            are high quality, member
                                            out-of-pockets are slightly higher
                                            to represent the higher associated
                                            cost of care.
                                        </span>
                                    </div>
                                </div>
                                <div className="card-footer-secondary">
                                    <div>
                                        <div className="StarIcon-icon">
                                            <TierIcon
                                                fill={getTierBgColor(3)}
                                            />
                                            <div className="final-tier">3</div>
                                        </div>
                                    </div>
                                    <div>
                                        <b>Tier 3:</b>
                                        <span className="pl-2 ">
                                            Providers have a performance metric
                                            associated with quality or cost
                                            efficiency that is significantly
                                            below their peers in the
                                            marketplace. While the provider may
                                            be capable of providing high quality
                                            care, the risk of a poor quality or
                                            cost outcome is much higher than
                                            many other providers in the market.
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </CardActions>
                    )}
                    <div className="result-workflow-container">
                        <Button
                            onClick={handleClose}
                            className="searchTip-dismiss-btn"
                            variant="contained"
                            aria-label="Dismiss"
                        >
                            Dismiss
                        </Button>
                    </div>
                    <div className="shared-workflow-container mt-2 text-center">
                        {!hideSupportUrl.status && (
                            <Button
                                variant="text"
                                aria-label="Need help?"
                                onClick={() => {
                                    let url = hideSupportUrl.csLink
                                    if (!url) {
                                        url =
                                            theme === "coupe-health"
                                                ? "https://employers.coupehealth.com/CoupeHealthContact.html"
                                                : "https://employers.simplepayhealth.com/SimplePayHealthContact.html"
                                    }
                                    window.open(url)
                                }}
                                endIcon={<HelpOutlineOutlinedIcon />}
                            >
                                {hideSupportUrl.csText
                                    ? hideSupportUrl.csText
                                    : "Contact Your Health Valet"}
                            </Button>
                        )}
                    </div>
                </Box>
            </Modal>
        </>
    )
}

SearchTip.propTypes = {
    open: PropTypes.any,
    handleClose: PropTypes.func,
    menuType: PropTypes.any,
    insurancePlanType: PropTypes.any
}
export default SearchTip
