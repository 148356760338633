import React, { useContext } from "react"
import { ThemeContext } from "../../../Theme"
import Box from "@mui/material/Box"
import LinearProgress from "@mui/material/LinearProgress"
import CheckCircleIcon from "@mui/icons-material/CheckCircle"

import "./ProgressBar.css"

const ProgressBar = () => {
    const { theme } = useContext(ThemeContext)
    return (
        <Box
            className={`linear-progress ${
                theme === "coupe-health"
                    ? "coupe"
                    : theme === "simplePay-health" && "simplePay-health"
            }`}
            sx={{ width: "100%" }}
        >
            <div className="progress-loading-screen-text-background">
                <div className="loading-screen-company-logo"></div>
                <div className="loading-screen-group">
                    <div className="loading-text loading-title-message">
                        <b>Your providers list is loading</b>
                    </div>
                    <br />
                    <div className="app-progress-bar">
                        <LinearProgress />
                    </div>

                    <br />
                    <div>
                        <div className="loading-text loading-text-alignment content1">
                            <CheckCircleIcon />
                            <b>&nbsp;Searching your area</b>
                        </div>
                        <br />
                        <br />
                        <div className="loading-text loading-text-alignment content2">
                            <CheckCircleIcon />
                            <b>&nbsp; Checking quality factors</b>
                        </div>
                        <br />
                        <br />
                        <div className="loading-text loading-text-alignment content3">
                            <CheckCircleIcon />
                            <b>&nbsp; Adding your preferences</b>
                        </div>
                        <br />
                    </div>
                </div>
            </div>
        </Box>
    )
}
export default ProgressBar
