import React, { useState } from "react"
import { PropTypes } from "prop-types"

import Modal from "@mui/material/Modal"
import CardActions from "@mui/material/CardActions"
import { Box, Button, Radio, RadioGroup } from "@mui/material"
import { FormControlLabel } from "@material-ui/core"

import "./searchMoreConfirmationModal.css"

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 600,
    bgcolor: "background.paper",
    color: "#000000de",
    boxShadow: 24,
    p: 10
}
const SearchMoreConfirmationModal = ({ handleClose }) => {
    const [value, setValue] = useState("")
    const handleChange = (e) => {
        setValue(e.target.value)
    }
    return (
        <>
            <Modal
                container={document.getElementsByClassName("app-content")[0]}
                open={true}
                aria-labelledby="search-more-confirmation-modal-title"
                aria-describedby="search-more-confirmation-modal-description"
            >
                <Box sx={style} className="search-more-modal">
                    <div className="text-center">
                        <h3 className="mx-2 d-block search-header">
                            Your search was SUPER successful!
                        </h3>
                        <br />
                        <span className="pl-2 ">
                            The area you searched in has a lot of providers! It
                            will take us some time to pull your data. Would you
                            still like to see the results?
                        </span>
                    </div>
                    <br />
                    <RadioGroup
                        aria-labelledby="demo-controlled-radio-buttons-group"
                        name="controlled-radio-buttons-group"
                        value={value}
                        onChange={handleChange}
                    >
                        <FormControlLabel
                            value="1"
                            control={<Radio />}
                            label="Hmm, let me focus my search a little more!"
                        />
                        <FormControlLabel
                            value="2"
                            control={<Radio />}
                            label="Yes, give me all the providers!"
                        />
                    </RadioGroup>
                    <CardActions className="card-action"></CardActions>

                    <div className="result-workflow-container">
                        <Button
                            disabled={!value}
                            onClick={() => handleClose(value)}
                            className="dismiss-btn"
                            variant="contained"
                            aria-label="Dismiss"
                        >
                            Submit
                        </Button>
                    </div>
                </Box>
            </Modal>
        </>
    )
}

SearchMoreConfirmationModal.propTypes = {
    handleClose: PropTypes.func
}
export default SearchMoreConfirmationModal
