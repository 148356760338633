import React from "react"
import { useSelector } from "react-redux"
import defaultNotices from "../../../assets/notices/rx-default.json"
import employerNotices from "../../../assets/notices/rx-employer.json"
import { Grid } from "@mui/material"

import Notices from "./Notices"
import BasicInfo from "./BasicInfo"

const Overview = () => {
    const { selectedDrugDetails: drugDetails } = useSelector((state) => state)
    const { userInfo } = useSelector((state) => state)
    const employerId = userInfo?.employerId || ""
    const planId = userInfo?.planId || ""
    let displayNotices = defaultNotices
    let selectedEmployerNotices = employerNotices[employerId + "-" + planId]

    for (let notice_key in selectedEmployerNotices) {
        if (selectedEmployerNotices[notice_key] !== undefined) {
            displayNotices[notice_key] = selectedEmployerNotices[notice_key]
        }
    }

    return (
        <Grid container spacing={10}>
            <Grid item md={8}>
                <BasicInfo
                    drugDetails={drugDetails}
                    displayNotices={displayNotices}
                />
            </Grid>
            <Grid item md={4}>
                {drugDetails && (
                    <Notices
                        drugDetails={drugDetails}
                        displayNotices={displayNotices}
                    />
                )}
            </Grid>
        </Grid>
    )
}
export default Overview
