import axiosInstance from "../middleware/axiosInstance"
import { load } from "protobufjs"
import protobuf from "./protos/basicPublicProcedureSpecialty.proto"
import protobufdx from "./protos/basicPublicMedicalCodes.proto"
import { Buffer } from "buffer"
import { employerPlansData } from "../../utils/employerPlansData"

const getPublicBundledSPDResponse = async (encodeResponse, dxcodes) => {
    if (dxcodes) {
        return await load(protobufdx).then(function (root) {
            const BasicDXResponse = root.lookupType(
                "BasicPublicMedicalCodesResponse"
            )
            const decodedResponse = BasicDXResponse.decode(
                Buffer.from(encodeResponse.data, "base64")
            )

            return {
                data: decodedResponse,
                apiStatus: "success",
                cached: encodeResponse.cached
            }
        })
    } else {
        return await load(protobuf).then(function (root) {
            const BasicSPDResponse = root.lookupType(
                "BasicPublicProcedureSpecialtyResponse"
            )
            const decodedResponse = BasicSPDResponse.decode(
                Buffer.from(encodeResponse.data, "base64")
            )
            return {
                data: decodedResponse,
                apiStatus: "success",
                cached: encodeResponse.cached
            }
        })
    }
}

const getPublicBundledSPDService = async ({
    payload,
    detailType,
    sponsorId,
    employerId,
    planId,
    nearByZipcodes,
    dxcodes,
    networkId
}) => {
    let url = ""

    const {
        city,
        state,
        zipCode,
        offset = 0,
        procedureCodes,
        diagnosisCode,
        specialtyCode,
        limit = 10
    } = payload
    const zCode = nearByZipcodes || zipCode
    payload.networkId = networkId

    // const productId = new URLSearchParams(window.location.search).get("product")
    let productId = new URLSearchParams(window.location.search).get("product")
    if (!productId) {
        const obj = sessionStorage.getItem("userInfo")
        const userInfo = JSON.parse(obj)
        if (userInfo) {
            productId = userInfo.partnersId
        }
    }
    console.log("Product ID:", productId)

    const selectedpublicMedicalEndpoint =
        (productId &&
            employerPlansData[productId].endpointsList.publicmedicalcodes) ||
        "/coupe/v3/publicmedicalcodes"

    const selectedpublicSpecialtyEndpoint =
        (productId &&
            employerPlansData[productId].endpointsList.publicproviders) ||
        "/coupe/v3/publicproviders"

    if (detailType === "basic") {
        if (dxcodes) {
            url = `${process.env.REACT_APP_API_URL_v3}${selectedpublicMedicalEndpoint}?detail=basic&sponsorId=${sponsorId}&planId=${planId}&employerId=${employerId}&limit=50000&offset=${offset}&zipcode=${zCode}&dxcodes=true`
        } else {
            url = `${process.env.REACT_APP_API_URL_v3}${selectedpublicMedicalEndpoint}?detail=basic&sponsorId=${sponsorId}&planId=${planId}&employerId=${employerId}&limit=50000&offset=${offset}&zipcode=${zCode}`
        }

        let basicSearchRes = await axiosInstance({
            method: "get",
            url
        })
        return await getPublicBundledSPDResponse(basicSearchRes, dxcodes)
    } else {
        let codesQueryParam = ""
        if (payload.codeType === "diagnosisCode") {
            codesQueryParam = `diagnosisCode=${diagnosisCode}`
            if (sponsorId === "7140") {
                url = `${process.env.REACT_APP_API_URL_v3}${selectedpublicMedicalEndpoint}?detail=detailed&state=${state}&sponsorId=${sponsorId}&zipcode=${zCode}&employerId=${employerId}&${codesQueryParam}&limit=${limit}&offset=${offset}&planId=${planId}&city=${city}&networkId=${networkId}`
            } else {
                url = `${process.env.REACT_APP_API_URL_v3}${selectedpublicMedicalEndpoint}?detail=detailed&state=${state}&sponsorId=${sponsorId}&zipcode=${zCode}&employerId=${employerId}&${codesQueryParam}&limit=${limit}&offset=${offset}&planId=${planId}&city=${city}`
            }
        } else if (payload.codeType === "specialtiesCode") {
            codesQueryParam = `specialtyCode=${specialtyCode}`
            if (sponsorId === "7140") {
                url = `${process.env.REACT_APP_API_URL_v3}${selectedpublicSpecialtyEndpoint}?detail=detailed&state=${state}&sponsorId=${sponsorId}&zipcode=${zCode}&employerId=${employerId}&${codesQueryParam}&limit=${limit}&offset=${offset}&planId=${planId}&city=${city}&networkId=${networkId}`
            } else {
                url = `${process.env.REACT_APP_API_URL_v3}${selectedpublicSpecialtyEndpoint}?detail=detailed&state=${state}&sponsorId=${sponsorId}&zipcode=${zCode}&employerId=${employerId}&${codesQueryParam}&limit=${limit}&offset=${offset}&planId=${planId}&city=${city}`
            }
        } else {
            codesQueryParam = `procedureCodes=${procedureCodes}`
            if (sponsorId === "7140") {
                url = `${process.env.REACT_APP_API_URL_v3}${selectedpublicMedicalEndpoint}?detail=detailed&state=${state}&sponsorId=${sponsorId}&zipcode=${zCode}&employerId=${employerId}&${codesQueryParam}&limit=${limit}&offset=${offset}&planId=${planId}&city=${city}&networkId=${networkId}`
            } else {
                url = `${process.env.REACT_APP_API_URL_v3}${selectedpublicMedicalEndpoint}?detail=detailed&state=${state}&sponsorId=${sponsorId}&zipcode=${zCode}&employerId=${employerId}&${codesQueryParam}&limit=${limit}&offset=${offset}&planId=${planId}&city=${city}`
            }
        }
    }

    return axiosInstance({
        method: "get",
        url
    })
}

export default getPublicBundledSPDService
