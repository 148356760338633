import { put, select, takeLatest } from "redux-saga/effects"

import getBundledSPDService from "../service/getBundledSPD.service"
import * as Types from "../actionTypes/index"

const state = (store) => store
export function* getBundledSPDDetails(action) {
    const { payload } = action
    try {
        const storeData = yield select(state)
        if (payload?.type === "navSearch" || payload?.type === "loadMore") {
            yield put({ type: Types.SHOW_LOADER })
        }
        const { tokenInfo, userInfo, nearByZipcodes } = storeData || {}
        const { access_token } = tokenInfo || {}
        const sponsorId = userInfo?.["custom:sponsorid"]
        const employeeId = userInfo?.["custom:employeeid"]
        const id = payload?.pageChange
            ? payload?.unique_id
            : payload?.provider_id
        const results = yield getBundledSPDService({
            token: access_token,
            payload,
            subType: payload.subType,
            detailType: "detailed",
            sponsorId: sponsorId,
            employeeId: employeeId,
            provider_id: id,
            nearByZipcodes
        })
        const { data, apiStatus } = results
        yield put({
            type: Types.SET_SELECTED_CATEGORY_PAGE_LEVEL_ERROR
        })
        yield put({
            type: Types.SHOW_PAGE_LEVEL_ERROR
        })
        yield put({
            type: Types.SHOW_CATEGORY_PAGE
        })
        yield put({
            type: Types.NAV_SEARCH_MODAL_STATUS,
            payload: false
        })
        if (apiStatus === "success") {
            yield put({
                type: Types.GET_PROVIDER_DETAILS_SUCCESS,
                providerDetailsData: data?.providers || [],
                count: data.count || 0
            })
            yield put({
                type: Types.GET_PREFERRED_DATA,
                preferredDetailsData: data?.preferred
            })
            if (payload?.type !== "navSearch") {
                if (data?.providers?.length > 0) {
                    yield put({
                        type: "SET_CATEGORY_VIEW_SHOW",
                        payload: false
                    })
                    payload.navigate("/ProviderResults")
                } else {
                    yield put({
                        type: Types.GLOBAL_ERROR_MESSAGE,
                        payload:
                            "There are no providers in the area you searched. Please try searching a new area for additional provider options."
                    })
                    yield put({
                        type: Types.SHOW_PAGE_LEVEL_ERROR,
                        payload: true
                    })
                    // payload.navigate("/error")
                }
            } else if (payload?.type === "navSearch") {
                yield put({
                    type: Types.IS_SEARCHTIP_MODAL_SHOW,
                    payload: true
                })
                if (data?.providers?.length === 0 || !data?.providers?.length) {
                    yield put({
                        type: Types.GLOBAL_ERROR_MESSAGE,
                        payload:
                            "There are no providers in the area you searched. Please try searching a new area for additional provider options."
                    })
                    yield put({
                        type: Types.SET_SELECTED_CATEGORY_PAGE_LEVEL_ERROR,
                        payload: true
                    })
                    payload.navigate("/error")
                }
            }
        } else if (results.response.status === 404) {
            yield put({
                type: Types.GLOBAL_ERROR_MESSAGE,
                payload:
                    "There are no providers in the area you searched. Please try searching a new area for additional provider options."
            })
            yield put({
                type: Types.SET_SELECTED_CATEGORY_PAGE_LEVEL_ERROR,
                payload: true
            })
            payload.navigate("/error")
        } else {
            yield put({ type: Types.GET_PROVIDER_DETAILS_FAIL })
            yield put({
                type: Types.GLOBAL_ERROR_MESSAGE,
                payload:
                    "We're sorry to say we're experiencing a service outage. Our team is working quickly to fix the issue."
            })
            yield put({
                type: Types.SET_SELECTED_CATEGORY_PAGE_LEVEL_ERROR,
                payload: true
            })
            payload.navigate("/error")
        }
        yield put({ type: Types.HIDE_LOADER })
    } catch (error) {
        yield put({ type: Types.HIDE_LOADER })
        yield put({ type: Types.GET_PROVIDER_DETAILS_FAIL })
    }
}

export function* getBundledSPDDetailsSaga() {
    yield takeLatest(
        Types.GET_CONDITIONS_PROCEDURES_DETAILS_DATA,
        getBundledSPDDetails
    )
}
