import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { styled, useTheme } from "@mui/material/styles"
import ArrowCircleRightOutlinedIcon from "@mui/icons-material/ArrowCircleRightOutlined"
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip"
import ClickAwayListener from "@mui/material/ClickAwayListener"
import CVS_SP_Mail_Order from "../../../assets/pdfs/CVS-SimplePay/Mail_Order_Instructions.pdf"
import Medone_Coupe_Mail_Order from "../../../assets/pdfs/Medone-Coupe/Mail_Order_Instructions.pdf"
import Medone_SP_Mail_Order from "../../../assets/pdfs/Medone-SimplePay/Mail_Order_Instructions.pdf"

import { Box, Card, Grid, Link, Stack, Typography } from "@mui/material"
import { BENEFIT_MANAGER } from "../../../utils/constants"

const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} arrow />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: "#f5f5f9",
        color: "rgba(0, 0, 0, 0.87)",
        maxWidth: 220,
        fontSize: theme.typography.pxToRem(12),
        border: "1px solid #dadde9"
    }
}))

const ExternalLinks = () => {
    const muiTheme = useTheme()
    const [open, setOpen] = React.useState(false)
    const [instructionsPDFLink, setInstructionsPDFLink] = useState("#")
    const { selectedDrugDetails } = useSelector((state) => state)

    const getMailInstructionPDFLink = () => {
        const { CVS_SIMPLE_PAY, MEDONE_COUPE, MEDONE_SIMPLE_PAY } =
            BENEFIT_MANAGER
        if (selectedDrugDetails?.benefitManager === MEDONE_COUPE) {
            setInstructionsPDFLink(Medone_Coupe_Mail_Order)
        } else if (selectedDrugDetails?.benefitManager === MEDONE_SIMPLE_PAY) {
            setInstructionsPDFLink(Medone_SP_Mail_Order)
        } else if (selectedDrugDetails?.benefitManager === CVS_SIMPLE_PAY) {
            setInstructionsPDFLink(CVS_SP_Mail_Order)
        }
    }
    useEffect(() => {
        getMailInstructionPDFLink()
    }, [])
    return (
        <div className={`external-links-container`}>
            <Grid container spacing={4} sx={{ marginY: muiTheme.spacing(8) }}>
                <Grid item xs={12} md={6} lg={6}>
                    <Card
                        sx={{
                            paddingX: muiTheme.spacing(10),
                            paddingY: muiTheme.spacing(8)
                        }}
                    >
                        <Stack
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                            spacing={2}
                        >
                            <Typography variant="h4" component="h3">
                                Search for Pharmacies
                            </Typography>
                            <ClickAwayListener
                                onClickAway={() => setOpen(false)}
                            >
                                <HtmlTooltip
                                    PopperProps={{
                                        disablePortal: true
                                    }}
                                    onClose={() => setOpen(false)}
                                    open={open}
                                    disableFocusListener
                                    disableHoverListener
                                    disableTouchListener
                                    title={
                                        <React.Fragment>
                                            <h3 className="popover-header">
                                                Finding In-Network Pharmacies
                                            </h3>
                                            <div className="popover-body">
                                                To search for in-network
                                                pharmacies near you, visit your
                                                member portal, and from your
                                                Benefits page, click on Find a
                                                Pharmacy and Start Now. Select
                                                Pharmacy Finder or Pharmacy and
                                                Drug Finder.
                                            </div>
                                        </React.Fragment>
                                    }
                                >
                                    <Box
                                        sx={{
                                            color: muiTheme.palette.primary
                                                .main,
                                            cursor: "pointer"
                                        }}
                                    >
                                        <ArrowCircleRightOutlinedIcon
                                            sx={{ width: 44, height: 44 }}
                                            onClick={() => setOpen(!open)}
                                        />
                                    </Box>
                                </HtmlTooltip>
                            </ClickAwayListener>
                        </Stack>
                    </Card>
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                    {selectedDrugDetails?.product && (
                        <Card
                            sx={{
                                paddingX: muiTheme.spacing(10),
                                paddingY: muiTheme.spacing(8)
                            }}
                        >
                            <Stack
                                direction="row"
                                justifyContent="space-between"
                                alignItems="center"
                                spacing={2}
                            >
                                <Typography variant="h4" component="h3">
                                    Mail Order Instructions
                                </Typography>
                                <Link
                                    target="_blank"
                                    rel="noreferrer"
                                    href={instructionsPDFLink}
                                    sx={{
                                        color: muiTheme.palette.primary.main,
                                        "&:hover": {
                                            color: muiTheme.palette.primary.main
                                        },
                                        "&:focus": {
                                            color: muiTheme.palette.primary.main
                                        }
                                    }}
                                >
                                    <ArrowCircleRightOutlinedIcon
                                        sx={{ width: 44, height: 44 }}
                                    />
                                </Link>
                            </Stack>
                        </Card>
                    )}
                </Grid>
            </Grid>
        </div>
    )
}
export default ExternalLinks
