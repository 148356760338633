import React, { useEffect, useState, useRef } from "react"
import { useSelector } from "react-redux"
import { PropTypes } from "prop-types"

import { Popper, OutlinedInput } from "@mui/material"
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined"
import ClickAwayListener from "@mui/material/ClickAwayListener"
import arrow from "../../../assets/images/blue_arrow.png"

import "./autoComplete.css"

const AutocompleteComponent = ({
    suggestions,
    handleSelectRow,
    handleKeyPress
}) => {
    const limit = 3
    const textFieldRef = useRef(null)
    const store = useSelector((state) => state)
    const { showNavSearchModal } = store

    const [active, setActive] = useState(0)
    const [filtered, setFiltered] = useState([])
    const [filteredData, setFilteredData] = useState([])
    const [isShow, setIsShow] = useState(false)
    const [input, setInput] = useState("")

    const [anchorEl, setAnchorEl] = useState(null)
    const [targetElement, setTargetElement] = useState(null)

    const popperStyle = {
        width: textFieldRef.current ? textFieldRef.current.clientWidth : "auto",
        zIndex: 9999
    }

    useEffect(() => {
        const ar = []
        suggestions?.forEach((item) => {
            const obj = {
                ...item,
                data: item.data.slice(0, 3)
            }
            ar.push(obj)
        })
        setFilteredData(ar)
    }, [suggestions])

    const handleOpenDropDown = (e) => {
        setAnchorEl(e?.currentTarget)
        setTargetElement(e?.currentTarget)
        e.stopPropagation()
    }
    const handleClose = () => {
        setAnchorEl(null)
    }

    const checkLen = (i) => {
        if (suggestions[i]?.data?.length === filteredData[i]?.data?.length) {
            return false
        }
        const val = suggestions[i]?.data?.length - limit
        return val > 0 ? true : false
    }
    const onChange = (e) => {
        if (anchorEl === null) {
            setAnchorEl(targetElement)
        }
        const input = e.currentTarget.value
        setActive(0)
        setIsShow(true)
        handleKeyPress(input ? input.toLocaleLowerCase() : "")
        if (e.target.value.match(/^\s+/)) {
            return
        }
        setInput(input)
        handleSelectRow(null)
    }
    const viewMore = (idx) => {
        let ar = [...filteredData]
        ar[idx].data = suggestions[idx].data
        setFilteredData(ar)
    }
    const selectRow = (e, data) => {
        e.preventDefault()
        setActive(0)
        setFiltered([])
        setIsShow(false)
        document.body.classList.remove("auto-complete-expand")
        setInput(e.currentTarget.innerText)
        handleSelectRow(data)
        setAnchorEl(null)
    }
    const onKeyDown = (e) => {
        if (e.keyCode === 13) {
            // enter key
            setActive(0)
            document.body.classList.remove("auto-complete-expand")
            setIsShow(false)
            setInput(filtered[active])
        } else if (e.keyCode === 38) {
            // up arrow
            return active === 0 ? null : setActive(active - 1)
        } else if (e.keyCode === 40) {
            // down arrow
            return active - 1 === filtered.length ? null : setActive(active + 1)
        }
    }
    const getByTitle = (title) => {
        if (title === "Practitioner,Physcian,V") {
            return "Healthcare Providers & Practices"
        } else if (title === "HOSPITAL,Facility,GROUP/CLINIC") {
            return "Hospitals, Facilities & Clinics"
        } else if (title === "specialties") {
            return "Specialists & Specialty Types"
        } else if (title === "procedureCode") {
            return "Conditions & Procedures"
        }
    }
    const getErroPage = () => {
        let status = false
        filteredData.forEach((item) => {
            if (item.data.length) {
                status = true
            }
        })
        return status
    }

    const getRowTemplate = (item) => {
        const city = item?.city || ""
        if (item?.diagnosisCodeDescription) {
            return item?.diagnosisCodeDescription
        } else if (item?.specialtyCode) {
            return item.specialtyDesc
        } else if (item?.procedureCode) {
            return item?.procedureDescription
        } else {
            return `${item?.providerName} ${city || item?.statecd ? " - " : ""} 
            ${city} ,
            ${item?.statecd}`
        }
    }
    const handleToggleClick = (event) => {
        // Check if the click event originated from the input
        if (event.target === textFieldRef.current) {
            handleOpenDropDown(event)
        }
    }

    const renderAutocomplete = () => {
        if (isShow) {
            if (!getErroPage()) {
                return (
                    <div className="autocomplete dropdown-menu">
                        <div className="no-autocomplete">
                            No results in the location you specified. Please
                            update your search.
                        </div>
                    </div>
                )
            } else if (input.length <= 0) {
                return (
                    <div className="autocomplete dropdown-menu">
                        <div className="no-autocomplete">start typing...</div>
                    </div>
                )
            } else if (getErroPage() && filteredData.length) {
                return (
                    <div className="autocomplete dropdown-menu">
                        {filteredData.map((element, i) => {
                            return (
                                <div key={i}>
                                    {element?.data?.length > 0 ? (
                                        <div>
                                            <div className="typeAheadGroupingMessage typeAheadResultHeaderFont">
                                                {getByTitle(element.title)}
                                            </div>
                                            {element?.data.map((item, i) => {
                                                return (
                                                    <div
                                                        onClick={(e) =>
                                                            selectRow(e, item)
                                                        }
                                                        key={i}
                                                        className="typeahead_grouping"
                                                    >
                                                        <a
                                                            href="#"
                                                            tabIndex="-1"
                                                            name={item.title}
                                                        >
                                                            {getRowTemplate(
                                                                item
                                                            )}
                                                        </a>
                                                    </div>
                                                )
                                            })}
                                            {checkLen(i) && (
                                                <div
                                                    className="viewMore"
                                                    onClick={() => viewMore(i)}
                                                >
                                                    <div>
                                                        {suggestions[i].data
                                                            .length -
                                                            limit}{" "}
                                                        {getByTitle(
                                                            element.title
                                                        )}
                                                    </div>
                                                    <img
                                                        className="padL5 previous"
                                                        src={arrow}
                                                        alt="arrow"
                                                    />
                                                </div>
                                            )}
                                            {i <= filteredData.length - 2 && (
                                                <div className="typeahead_separator"></div>
                                            )}
                                        </div>
                                    ) : (
                                        <></>
                                    )}
                                </div>
                            )
                        })}
                    </div>
                )
            }
        }
        return <></>
    }
    return (
        <div className="autoComplete-wrapper">
            <div className="autoComplete-input-wrapper">
                <p className="d-flex align-items-center text-left provider-lable-flds pb-0">
                    <InfoOutlinedIcon className="autocomplete-info-icon" />
                    Search by: Provider / Specialty / Condition / Procedure /
                    Clinic / Hospital
                </p>
                <div className="autoComplete-input">
                    <OutlinedInput
                        placeholder="Eg: Smith / Cardiology / Headache .."
                        value={input}
                        onClick={handleOpenDropDown}
                        onChange={onChange}
                        onKeyDown={onKeyDown}
                        id="autoComplete"
                        type="text"
                        onFocus={() => {
                            if (showNavSearchModal) {
                                document.body.classList.add(
                                    "auto-complete-expand"
                                )
                            }
                            setIsShow(true)
                        }}
                        className="autoComplete-input-fld w-100 location-search-input"
                        autoComplete="off"
                        inputRef={textFieldRef}
                        inputProps={{
                            onClick: handleToggleClick // Attach your toggle click event handler
                        }}
                    />
                </div>
            </div>
            <Popper
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                style={popperStyle}
                placement="bottom-start"
            >
                <ClickAwayListener onClickAway={handleClose}>
                    {renderAutocomplete()}
                </ClickAwayListener>
            </Popper>
        </div>
    )
}

AutocompleteComponent.propTypes = {
    suggestions: PropTypes.any,
    handleSelectRow: PropTypes.func,
    handleKeyPress: PropTypes.func
}
export default AutocompleteComponent
