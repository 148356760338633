import React, { useContext } from "react"
import { ThemeContext } from "../../Theme"

import "./Footer.css"

const Footer = () => {
    const { theme } = useContext(ThemeContext)
    const currentYear = new Date().getFullYear()

    const setThemeType = () => {
        let textVal = ""
        if (theme === "coupe-health") {
            textVal = "Coupe"
        } else if (theme === "simplePay-health") {
            textVal = "SimplePay"
        }
        return textVal
    }

    return (
        <>
            {theme ? (
                <div className="page-footer">
                    <p>
                        <span className="s1">
                            © {currentYear} {setThemeType()}. All Rights
                            Reserved
                            <a
                                href={
                                    theme === "coupe-health"
                                        ? "https://www.coupehealth.com/wp-content/uploads/2021/06/privacy-statement.pdf"
                                        : "https://simplepayhealth.stella-webhosting-p.io-prod.stellahealth.net/wp-content/uploads/2021/02/sph-privacy.pdf"
                                }
                                target="_blank"
                                rel="noreferrer"
                            >
                                . Privacy Statement
                            </a>
                            {theme === "coupe-health" && (
                                <a
                                    target="_blank"
                                    rel="noreferrer"
                                    href="https://www.coupehealth.com/wp-content/uploads/2022/05/M06730_Web.pdf"
                                >
                                    . Notice of Privacy Practices
                                </a>
                            )}
                            <a
                                href={
                                    theme === "coupe-health"
                                        ? "https://www.coupehealth.com/wp-content/uploads/2021/06/terms-of-use.pdf"
                                        : "https://simplepayhealth.stella-webhosting-p.io-prod.stellahealth.net/wp-content/uploads/2021/02/sph-tou.pdf"
                                }
                                target="_blank"
                                rel="noreferrer"
                            >
                                . Terms of Use.
                            </a>
                        </span>
                    </p>
                </div>
            ) : (
                ""
            )}
        </>
    )
}

export default Footer
