import { put, select, takeLatest } from "redux-saga/effects"

import getDrugListService from "../service/getDrugList.service"
import * as Types from "../actionTypes/index"

const state = (store) => store
export function* getDrugDetails(action) {
    const { payload } = action
    yield put({ type: Types.INCREASE_LOADER_COUNT })
    try {
        const storeData = yield select(state)
        const { tokenInfo, userInfo } = storeData || {}
        const { access_token } = tokenInfo || {}
        const sponsorId = userInfo?.["custom:sponsorid"]
        const employeeId = userInfo?.["custom:employeeid"]
        const results = yield getDrugListService({
            token: access_token,
            payload,
            detailType: "detailed",
            sponsorId: sponsorId,
            employeeId: employeeId
        })

        const { data, apiStatus } = results
        if (apiStatus === "success") {
            yield put({
                type: Types.GET_DRUG_DETAILS_DATA_SUCCESS,
                data: { ...data }
            })
            if (!data) {
                yield put({
                    type: Types.GLOBAL_ERROR_MESSAGE,
                    payload:
                        "There are no drugs found your search. Please try searching a new query."
                })
            } else {
                payload.navigate("/DrugResults")
            }
        } else {
            yield put({ type: Types.GET_DRUG_DETAILS_DATA_FAIL })
            yield put({
                type: Types.GLOBAL_ERROR_MESSAGE,
                payload:
                    "We're sorry to say we're experiencing a service outage. Our team is working quickly to fix the issue."
            })
            payload.navigate("/error")
        }
        const tokenData = sessionStorage.getItem("tokenData")
        if (tokenData) {
            yield put({ type: Types.LOGIN_TYPE, payload: "vp login" })
        } else {
            const client_id = process.env.REACT_APP_CLIENT_ID
            const url = `${process.env.REACT_APP_API_BASE_URL}/oauth2/authorize?identity_provider=Virgin-Pulse&redirect_uri=${process.env.REACT_APP_SPA_DOMAIN_URL}&response_type=CODE&client_id=${client_id}`
            window.location.href = url
        }
        yield put({ type: Types.DECREASE_LOADER_COUNT })
    } catch (error) {
        yield put({ type: Types.GET_DRUG_DETAILS_DATA_FAIL })
        yield put({
            type: Types.GLOBAL_ERROR_MESSAGE,
            payload:
                "We're sorry to say we're experiencing a service outage. Our team is working quickly to fix the issue."
        })
        payload.navigate("/error")
        yield put({ type: Types.DECREASE_LOADER_COUNT })
    }
}

export function* getDrugDetailsSaga() {
    yield takeLatest(Types.GET_DRUG_DETAILS_DATA, getDrugDetails)
}
