import { Card, CardContent, useTheme } from "@mui/material"
import React from "react"
import {
    getAgeLimitDisclaimer,
    getCustomSearchDisclaimer,
    getMaintenanceDisclaimer,
    getMaintenanceOptOutDisclaimer,
    getPreventativeDisclaimer,
    getPriorAuthDisclaimer,
    getQuantityLimitDisclaimer,
    getSpecTierExcDisclaimer,
    getStepTherapyDisclaimer
} from "./Utils"

const Notices = ({ drugDetails = {}, displayNotices = [] }) => {
    const muiTheme = useTheme()
    const {
        benefitManager,
        maintenanceChoice,
        preventativeOption,
        formularyType,
        notice: {
            preventative,
            priorAuth,
            stepTherapy,
            specialty,
            ageLimit,
            quantityLimit
        }
    } = drugDetails

    const preventativeDisc = getPreventativeDisclaimer(
        preventativeOption,
        displayNotices
    )

    const priorAuthDisc = getPriorAuthDisclaimer(benefitManager, displayNotices)

    const stepTherapyDisc = getStepTherapyDisclaimer(
        benefitManager,
        formularyType,
        displayNotices
    )

    const maintenanceDisc = getMaintenanceDisclaimer(
        benefitManager,
        displayNotices
    )

    const maintenanceChoiceDisc = getMaintenanceOptOutDisclaimer(
        benefitManager,
        displayNotices
    )

    const ageLimitDisc = getAgeLimitDisclaimer(benefitManager, displayNotices)

    const quantityLimitDisc = getQuantityLimitDisclaimer(
        benefitManager,
        displayNotices
    )

    const specTierExcDisc = getSpecTierExcDisclaimer(
        benefitManager,
        displayNotices
    )

    const customNoticeDisc = getCustomSearchDisclaimer(displayNotices)

    const Disclaimer = ({ name, title, content }) => {
        return (
            content && (
                <Card
                    sx={{ marginBottom: muiTheme.spacing(8) }}
                    data-notice={name}
                >
                    <CardContent
                        sx={{ background: muiTheme.palette.warning.main }}
                    >
                        {" "}
                        {title && <strong>{title}</strong>}
                        <div
                            dangerouslySetInnerHTML={{
                                __html: content
                            }}
                        />
                    </CardContent>
                </Card>
            )
        )
    }
    Disclaimer.propTypes = {
        name: "",
        title: "",
        content: null
    }
    return (
        <>
            <Card sx={{ marginBottom: muiTheme.spacing(8) }}>
                <CardContent sx={{ background: muiTheme.palette.warning.main }}>
                    <span>
                        <a href="/#DrugSearch">
                            <strong>{`${drugDetails?.employerName}`}</strong>
                        </a>{" "}
                        <strong>{`${drugDetails?.planName}`}</strong>
                    </span>{" "}
                    {` - ${drugDetails?.formularyType}`}
                </CardContent>
            </Card>
            {/* Preventative */}
            {preventative && preventativeDisc && (
                <Disclaimer name="Preventative" content={preventativeDisc} />
            )}
            {/* Prior Authorization */}
            {priorAuth && priorAuthDisc && (
                <Disclaimer
                    name="Prior Auth Disclaimer"
                    content={priorAuthDisc}
                />
            )}
            {/* Step Therapy */}
            {stepTherapy && stepTherapyDisc && (
                <Disclaimer
                    name="Step Therapy Disclaimer"
                    content={stepTherapyDisc}
                />
            )}
            {/* Maintenance Opt In */}
            {maintenanceChoice === "Opt In" && maintenanceChoiceDisc && (
                <Disclaimer
                    name="Maintenance Choice Opt In Disclaimer"
                    content={maintenanceDisc}
                />
            )}
            {/* Maintenance Opt Out */}
            {maintenanceChoice === "Opt Out" && maintenanceChoiceDisc && (
                <Disclaimer
                    name="Maintenance Choice Opt Out Disclaimer"
                    content={maintenanceChoiceDisc}
                />
            )}
            {/* Specialty Tier Exclusion */}
            {specialty && specTierExcDisc && (
                <Disclaimer
                    name="Specialty Tier Exclusion"
                    content={specTierExcDisc}
                />
            )}
            {/* Age Limit */}
            {ageLimit && ageLimitDisc && (
                <Disclaimer
                    name="Age Limit Disclaimer"
                    content={ageLimitDisc}
                />
            )}
            {/* Quantity Limit */}
            {quantityLimit && quantityLimitDisc && (
                <Disclaimer
                    name="Quantity Limit Disclaimer"
                    content={quantityLimitDisc}
                />
            )}
            {/* Custom Search */}
            {customNoticeDisc && <Disclaimer content={customNoticeDisc} />}
        </>
    )
}
Notices.propTypes = {
    drugDetails: {},
    displayNotices: []
}
export default Notices
