import React, { useState, useEffect, useRef } from "react"
import { useDispatch, useSelector } from "react-redux"

import Box from "@mui/material/Box"
import Modal from "@mui/material/Modal"

import Tab from "@material-ui/core/Tab"
import TabContext from "@material-ui/lab/TabContext"
import TabList from "@material-ui/lab/TabList"
import TabPanel from "@material-ui/lab/TabPanel"

import PhysicianDetails from "../../components/physician-details/PhysicianDetails"
import ProviderList from "./ProviderList"
import Map from "../../components/map/Map"
import SearchTip from "../../components/common/searchTip/SearchTip"
import * as Types from "../../redux/actionTypes/index"
// import { providerDetails } from "../../components/providers-services/searchMockData"

import "./ProviderResults.css"

const google = window.google

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    WebkitTransform: "translate(-50%, -50%)",
    width: "90%",
    bgcolor: "background.paper",
    color: "#000000de",
    boxShadow: 24,
    p: 10
}

const ProviderResults = () => {
    const store = useSelector((state) => state)
    const dispatch = useDispatch()
    const {
        currentProviderDetails,
        providerDetailsData,
        providerDetailsDataLength,
        preferredDetailsData,
        providersResponse,
        showSearchTipModal,
        loginType,
        selectedDropDownDetails
    } = store
    const [isMobile, setIsMobile] = useState(false)
    const [markerData, setMarkerData] = useState([])
    const [currentProviderData, setCurrentProviderData] = useState()
    const [providerDataList, setProviderDataList] = useState([])
    const [doctorList, setDoctorList] = useState([])
    const [open, setOpen] = useState(false)
    const [openSearchTip, setOpenSearchTip] = useState(true)
    const [value, setValue] = useState("1")
    const errorRef = useRef(null)
    const cardSectionRef = useRef(null)
    const { insurancePlanType = "" } = providersResponse || {}

    const handleLoadMore = () => {
        let offsetVal = 0
        if (
            currentProviderDetails?.offset ||
            currentProviderDetails?.offset === 0
        ) {
            offsetVal = 10 + currentProviderDetails.offset
        }
        const res = {
            ...currentProviderDetails,
            diagnosisCode: currentProviderDetails?.diagnosisCode || "",
            offset: offsetVal,
            getAllResults: false,
            type: "loadMore"
        }

        let actionType =
            loginType === "external user login"
                ? Types.GET_PUBLIC_PROVIDER_DETAILS
                : Types.GET_PROVIDER_DETAILS
        if (
            currentProviderDetails?.title === "procedureCode" ||
            currentProviderDetails?.title === "diagnosisCode" ||
            currentProviderDetails?.title === "specialties"
        ) {
            actionType =
                loginType === "external user login"
                    ? Types.GET_PUBLIC_CONDITIONS_PROCEDURES_DETAILS_DATA
                    : Types.GET_CONDITIONS_PROCEDURES_DETAILS_DATA
        }

        dispatch({
            type: actionType,
            payload: res
        })
    }

    useEffect(() => {
        if (errorRef?.current) {
            errorRef.current.scrollIntoView()
        }
    }, [providerDataList])

    useEffect(() => {
        if (cardSectionRef?.current && currentProviderDetails?.offset < 10) {
            cardSectionRef.current.scrollTo({
                top: 0
            })
        }
        if (currentProviderDetails?.type === "navSearch") {
            setMarkerData([])
        }
        if (providerDetailsData) {
            let dataRes = JSON.parse(JSON.stringify(providerDetailsData)) || []
            if (preferredDetailsData && preferredDetailsData.length) {
                const ar = []
                preferredDetailsData.forEach((ele) => {
                    ele.cardType = "preferred"
                    ar.push(ele)
                })
                if (selectedDropDownDetails?.title === "specialties") {
                    dataRes = [...ar, ...dataRes]
                }
                if (
                    selectedDropDownDetails?.title ===
                        "Practitioner,Physcian,V" &&
                    selectedDropDownDetails.providerType === "V"
                ) {
                    dataRes = [...ar, ...dataRes]
                } else if (selectedDropDownDetails?.title !== "specialties") {
                    const otherList = dataRes.slice(1)
                    dataRes = [dataRes[0], ...ar, ...otherList]
                }
                // dataRes.unshift(preferredDetailsData[0]);
            }
            setDoctorList(dataRes)

            const id =
                currentProviderDetails?.provider_id ||
                currentProviderDetails?.providerId
            if (
                preferredDetailsData &&
                preferredDetailsData.length &&
                selectedDropDownDetails?.title !== "specialties"
            ) {
                dataRes?.sort((x, y) => {
                    const pId = x.provider_id
                    return pId === id ? -1 : y.provider_id === id ? 1 : 0
                })
            }

            setProviderDataList(dataRes)
            setCurrentProviderData(null)
            setOpen(false)
            dataRes?.forEach((item) => {
                if (item.provider_id === id) {
                    setCurrentProviderData(item)
                }
            })
        }
        // getLatLangValues()
    }, [currentProviderDetails, providerDetailsData])

    useEffect(() => {
        getLatLangValues()
    }, [doctorList])

    const handleOpen = (data) => {
        setCurrentProviderData(data)
        setOpen(true)
    }
    const handleClose = () => {
        setOpen(false)
    }
    const handleMarkerClick = (data) => {
        setCurrentProviderData(data)
        setOpen(true)
    }

    const handleSearchTipClose = () => {
        dispatch({ type: "IS_SEARCHTIP_MODAL_SHOW", payload: false })
        setOpenSearchTip(false)
        if (selectedDropDownDetails?.title === "specialties") {
            setOpen(false)
        } else {
            setOpen(true)
        }
    }

    useEffect(() => {
        handleResize()
        window.addEventListener("resize", handleResize)
        return () => window.removeEventListener("resize", handleResize)
    }, [])

    const handleChange = (event, newValue) => {
        setValue(newValue)
    }

    const handleResize = () => {
        if (window.innerWidth < 768) {
            setIsMobile(true)
        } else {
            setIsMobile(false)
        }
    }

    const getLatLangValues = () => {
        // let delayFactor = 0
        let markerCopyData = markerData
        doctorList?.map((item, i) => {
            if (
                item?.cardType !== "preferred" &&
                !markerCopyData[i]?.address?.location?.lat
            ) {
                let geocoder = new google.maps.Geocoder()
                geocoder.geocode(
                    {
                        address: `${item.addr_ln_1 ? item.addr_ln_1 : ""}, ${
                            item.city ? item.city : ""
                        }, ${item.state_cd ? item.state_cd : ""}, USA`
                    },
                    (results, status) => {
                        if (status === "OK") {
                            const center = results[0].geometry.location
                            item["address"] = {
                                id: item.provider_id,
                                line1: item.addr_ln_1,
                                line2: item.addr_ln_2,
                                city: item.city,
                                state: item.state_cd,
                                zipCode: item.zipcode,
                                location: {
                                    lat: center.lat(),
                                    lon: center.lng()
                                }
                            }
                            setMarkerData((old) => [...old, item])
                        } else if (status === "OVER_QUERY_LIMIT") {
                            console.log("Waiting for Limit for item: " + item)
                            // delayFactor++
                            // setTimeout(() => {
                            //     // getLatLangValues()
                            // }, delayFactor * 1100)
                        } else {
                            console.log("errore diverso: " + status)
                        }
                    }
                )
                return item
            }
        })
        // const addressResp = providerDetailsData?.filter((item) => item.address)
    }

    return (
        <>
            <div className="ProviderResults-landing" ref={errorRef}>
                {isMobile ? (
                    <TabContext value={value}>
                        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                            <TabList
                                onChange={handleChange}
                                aria-label="lab API tabs example"
                            >
                                <Tab label="LIST" value="1" />
                                <Tab label="MAP" value="2" />
                            </TabList>
                        </Box>
                        <TabPanel
                            className="list-tabPanel"
                            value="1"
                            ref={cardSectionRef}
                        >
                            {providerDataList?.length && (
                                <ProviderList
                                    currentProviderDetails={
                                        currentProviderDetails
                                    }
                                    handleLoadMore={handleLoadMore}
                                    providerDetails={providerDataList}
                                    providerDetailsDataLength={
                                        providerDetailsDataLength
                                    }
                                    handleOpen={handleOpen}
                                />
                            )}
                        </TabPanel>
                        <TabPanel value="2">
                            {value === "2" && markerData?.length > 0 && (
                                <div className="providers-mobile-right">
                                    <Map
                                        currentProviderData={
                                            currentProviderData
                                        }
                                        markerData={markerData}
                                        handleMarkerClick={handleMarkerClick}
                                        providerDetailsIndexOne={
                                            providerDataList[0]
                                        }
                                    />
                                </div>
                            )}
                        </TabPanel>
                    </TabContext>
                ) : (
                    <>
                        <div className="providers-left" ref={cardSectionRef}>
                            {providerDataList?.length > 0 && (
                                <ProviderList
                                    currentProviderDetails={
                                        currentProviderDetails
                                    }
                                    handleLoadMore={handleLoadMore}
                                    providerDetails={providerDataList}
                                    providerDetailsDataLength={
                                        providerDetailsDataLength
                                    }
                                    handleOpen={handleOpen}
                                />
                            )}
                        </div>
                        <div className="providers-right">
                            {markerData?.length > 0 && (
                                <Map
                                    currentProviderData={currentProviderData}
                                    handleMarkerClick={handleMarkerClick}
                                    markerData={markerData}
                                    providerDetailsIndexOne={
                                        providerDataList[0]
                                    }
                                />
                            )}
                        </div>
                    </>
                )}
            </div>
            {(openSearchTip || showSearchTipModal) && (
                <SearchTip
                    open={openSearchTip || showSearchTipModal}
                    handleClose={handleSearchTipClose}
                    menuType={"search"}
                    insurancePlanType={insurancePlanType}
                />
            )}
            {open && currentProviderData && (
                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="physician-details-modal-title"
                    aria-describedby="physician-details-modal-description"
                >
                    <Box
                        sx={style}
                        className="provider-results-modal-container results-modal"
                    >
                        <PhysicianDetails
                            currentProviderData={currentProviderData}
                            onClose={handleClose}
                        />
                    </Box>
                </Modal>
            )}
        </>
    )
}

export default ProviderResults
