import { put, select, takeLatest } from "redux-saga/effects"

import getBundledSPDService from "../service/getBundledSPD.service"
import * as Types from "../actionTypes/index"

const state = (store) => store
export function* getBundledSPD(action) {
    const { payload } = action
    yield put({ type: Types.INCREASE_LOADER_COUNT })
    try {
        const storeData = yield select(state)
        const { tokenInfo, userInfo, nearByZipcodes } = storeData || {}
        const { access_token } = tokenInfo || {}
        const sponsorId = userInfo?.["custom:sponsorid"]
        const employeeId = userInfo?.["custom:employeeid"]
        const results = yield getBundledSPDService({
            token: access_token,
            payload,
            detailType: "basic",
            sponsorId: sponsorId,
            employeeId: employeeId,
            nearByZipcodes
        })
        const { data, apiStatus } = results
        let proceduresList = []
        if (apiStatus === "success") {
            const procedures = data?.procedures ? data.procedures : []
            proceduresList = [...procedures]
            yield put({
                type: Types.GET_CONDITIONS_PROCEDURES_DATA_SUCCESS,
                data: [...proceduresList]
            })
            yield put({
                type: Types.GET_SPECIALTYLIST_SUCCESS,
                specialties: data?.specialties
            })
            yield put({
                type: "SET_CATEGORY_VIEW_SHOW",
                payload: true
            })
            if (!proceduresList?.length) {
                yield put({
                    type: Types.GLOBAL_ERROR_MESSAGE,
                    payload:
                        "There are no providers in the area you searched. Please try searching a new area for additional provider options."
                })
                payload.navigate("/error")
            }
        } else {
            yield put({ type: Types.GET_CONDITIONS_PROCEDURES_DATA_FAIL })
            yield put({
                type: Types.GLOBAL_ERROR_MESSAGE,
                payload:
                    "We're sorry to say we're experiencing a service outage. Our team is working quickly to fix the issue."
            })
            payload.navigate("/error")
        }
        const tokenData = sessionStorage.getItem("tokenData")
        if (tokenData) {
            yield put({ type: Types.LOGIN_TYPE, payload: "vp login" })
            // const tokenInfo = JSON.parse(tokenData)
            // yield put({ type: Types.GET_REFRESH_TOKEN, tokenInfo })
        } else {
            const client_id = process.env.REACT_APP_CLIENT_ID
            const url = `${process.env.REACT_APP_API_BASE_URL}/oauth2/authorize?identity_provider=Virgin-Pulse&redirect_uri=${process.env.REACT_APP_SPA_DOMAIN_URL}&response_type=CODE&client_id=${client_id}`
            window.location.href = url
        }
        yield put({ type: Types.DECREASE_LOADER_COUNT })
    } catch (error) {
        yield put({ type: Types.GET_CONDITIONS_PROCEDURES_DATA_FAIL })
        yield put({
            type: Types.GLOBAL_ERROR_MESSAGE,
            payload:
                "We're sorry to say we're experiencing a service outage. Our team is working quickly to fix the issue."
        })
        payload.navigate("/error")
        yield put({ type: Types.DECREASE_LOADER_COUNT })
    }
}

export function* getBundledSPDSaga() {
    yield takeLatest(Types.GET_CONDITIONS_PROCEDURES_DATA, getBundledSPD)
}
