import axiosInstance from "../middleware/axiosInstance"

const getZipcodesService = async (payload) => {
    const { lat, lng, radius } = payload || {}
    const url = `https://api.zip-codes.com/ZipCodesAPI.svc/1.0/FindZipCodesInRadius/ByLatLon?latitude=${lat}&longitude=${lng}&minimumradius=0&maximumradius=${radius}&country=US&key=${process.env.REACT_APP_ZIPCODE_API_KEY}`
    return axiosInstance({
        method: "get",
        url,
        data: null
    })
}

export default getZipcodesService
