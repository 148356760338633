import React, { useState } from "react"
import { PropTypes } from "prop-types"

import pin_multiple_highlighted_large from "../../assets/images/pin_recommended_highlighted_large.svg"

import Box from "@mui/material/Box"
import Grid from "@mui/material/Grid"
import Modal from "@mui/material/Modal"
import PhysicianDetails from "../physician-details/PhysicianDetails"
import pin_facility_large from "../../assets/images/pin_facility_large.svg"
import pin_tier2_large from "../../assets/images/pin_tier2_large.svg"
import pin_tier3_large from "../../assets/images/pin_tier3_large.svg"
import pin_facility_dark from "../../assets/images/pin_facility_dark_large.svg"
import pin_tier2_dark from "../../assets/images/pin_tier2_dark_large.svg"
import pin_tier3_dark from "../../assets/images/pin_tier3_dark_large.svg"
import pin_recommended from "../../assets/images/pin_recommended.svg"
import { isFacilityWithCopay } from "../../utils/common"
import { Typography } from "@mui/material"

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "400px",
    maxHeight: "400px",
    overflow: "auto",
    bgcolor: "background.paper",
    color: "#000000de",
    boxShadow: 24,
    p: 10
}
const GroupModal = ({
    groupedLocation,
    callBackClose,
    providerDetailsIndexOne
}) => {
    const [currentProviderData, setCurrentProviderData] = useState("")
    const [showGroupModal, setShowGroupModal] = useState(true)
    const handleProviderDetailsModalClose = () => {
        setCurrentProviderData("")
        setShowGroupModal(true)
    }

    const customTierColor = (item) => {
        if (isFacilityWithCopay(item)) {
            return providerDetailsIndexOne?.provider_id === item?.provider_id
                ? pin_facility_dark
                : pin_facility_large
        } else if (item?.override_tier === 1 || item?.default_tier === 1) {
            return providerDetailsIndexOne?.provider_id === item?.provider_id
                ? pin_multiple_highlighted_large
                : pin_recommended
        } else if (item?.override_tier === 2 || item?.default_tier === 2) {
            return providerDetailsIndexOne?.provider_id === item?.provider_id
                ? pin_tier2_dark
                : pin_tier2_large
        } else if (item?.override_tier === 3 || item?.default_tier === 3) {
            return providerDetailsIndexOne?.provider_id === item?.provider_id
                ? pin_tier3_dark
                : pin_tier3_large
        } else {
            return null
        }
    }

    return (
        <>
            {groupedLocation?.length ? (
                <Modal
                    open={showGroupModal}
                    aria-labelledby="group-modal-title"
                    aria-describedby="group-modal-description"
                    onClose={callBackClose}
                >
                    <Box sx={style} className="group-results-modal-container">
                        <Grid container rowSpacing={2}>
                            {groupedLocation?.length &&
                                groupedLocation.map((item, i) => {
                                    return (
                                        <Grid
                                            item
                                            xs={12}
                                            md={12}
                                            onClick={() => {
                                                setShowGroupModal(false)
                                                setCurrentProviderData(item)
                                            }}
                                            key={i}
                                            className="group-modal-container"
                                        >
                                            <Grid container>
                                                <Grid item xs={11} md={11}>
                                                    <Typography>
                                                        <strong>
                                                            {
                                                                item?.provider_name
                                                            }
                                                        </strong>
                                                        <p>
                                                            {
                                                                item?.speciality_category
                                                            }
                                                        </p>
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={1} md={1}>
                                                    <img
                                                        src={customTierColor(
                                                            item
                                                        )}
                                                        alt="image"
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    )
                                })}
                        </Grid>
                    </Box>
                </Modal>
            ) : (
                <></>
            )}

            {currentProviderData && (
                <Modal
                    open={true}
                    onClose={handleProviderDetailsModalClose}
                    aria-labelledby="provider-results-modal-title"
                    aria-describedby="provider-results-modal-description"
                >
                    <Box
                        sx={style}
                        className="provider-results-modal-container group-modal"
                    >
                        <PhysicianDetails
                            currentProviderData={currentProviderData}
                            onClose={handleProviderDetailsModalClose}
                        />
                    </Box>
                </Modal>
            )}
        </>
    )
}

GroupModal.propTypes = {
    groupedLocation: PropTypes.any,
    callBackClose: PropTypes.any,
    providerDetailsIndexOne: PropTypes.any
}

export default GroupModal
