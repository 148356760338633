import { put, select, takeLatest } from "redux-saga/effects"

import getUserInfoService from "../service/getUserInfo.service"
import * as Types from "../actionTypes/index"

const state = (store) => store
export function* getUserInfo() {
    try {
        const storeData = yield select(state)
        const { tokenInfo } = storeData || {}
        const { access_token } = tokenInfo || {}
        const response = yield getUserInfoService({ token: access_token })
        const { data, apiStatus } = response
        if (apiStatus === "success") {
            yield put({ type: Types.GET_USER_INFO_SUCCESS, userInfo: data })
        } else {
            yield put({ type: Types.GET_USER_INFO_FAIL })
        }
    } catch (error) {
        yield put({ type: Types.GET_USER_INFO_FAIL })
    }
}

export function* getUserInfoSaga() {
    yield takeLatest(Types.GET_USER_INFO, getUserInfo)
}
