import axiosInstance from "../middleware/axiosInstance"
import { load } from "protobufjs"
import protobuf from "./protos/drugSearch.proto"
import { Buffer } from "buffer"

const getDrugSearchResponse = async (encodeResponse) => {
    return await load(protobuf).then(function (root) {
        const DrugSearchResponse = root.lookupType(
            "rxSearch.DrugSearchResponse"
        )
        const decodedResponse = DrugSearchResponse.decode(
            Buffer.from(encodeResponse.data, "base64")
        )
        return {
            data: decodedResponse,
            apiStatus: "success"
        }
    })
}

const getPublicDrugListService = async ({
    payload,
    detailType,
    sponsorId,
    employerId,
    planId
}) => {
    let url = ""
    if (detailType === "basic") {
        let searchText = Buffer.from(payload.drugSearch, "utf-8").toString(
            "base64"
        )
        url = `${process.env.REACT_APP_API_URL}/v1/coupe/publicdrugs?sponsorId=${sponsorId}&planId=${planId}&employerId=${employerId}&detail=basic&text=${searchText}`
        let drugSearchResponse = await axiosInstance({
            method: "get",
            url
        })
        return await getDrugSearchResponse(drugSearchResponse)
    } else {
        url = `${process.env.REACT_APP_API_URL}/v1/coupe/publicdrugs?&sponsorId=${sponsorId}&planId=${planId}&employerId=${employerId}&detail=detailed&ndc=${payload.selectedDrug.ndc}`
        return axiosInstance({
            method: "get",
            url
        })
    }
}

export default getPublicDrugListService
